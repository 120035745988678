import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'

import { Dialog, DialogActions, DialogContent, Button, Grid, InputLabel, Input, FormControl, Chip, Paper, Divider } from '@material-ui/core'
import { imgUpload, fromServer } from '../../Providers/ws'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { getLocalUserData } from '../../Providers/ls';

const SortableItem = SortableElement(({ v, idx, }) =>


    <Chip
        className="meditatii-chip-categorii"

        style={{ cursor: 'pointer', paddingRight: 20 }}
        label={(idx + 1) + '-' + v.nume_content}


    />




)

const SortableList = SortableContainer(({ items, salveazaOrdine, openList, deleteProgram, openAdd }) => {
    return (
        <Paper style={{ paddingBottom: 20, position: 'relative' }}>
            <Button className='meditatii-add-categorie-button' onClick={openList} variant="fab">
                <i className='material-icons'>add</i>
            </Button>
            <h2 className="meditatii-section-meditezi-title">Continut</h2>
            {items.map((value, index) => (<div key={`item-${index}`}>

                <SortableItem index={index} v={value} idx={index} />
                <i className="material-icons" onClick={() => { deleteProgram(value) }} style={{ color: 'white', cursor: 'pointer', marginLeft: -38, fontSize: 22 }}>delete</i>
            </div>
            ))}
            <Divider />
            <Button style={{ color: 'darkorange', display: 'block', margin: 'auto' }} color="primary" className='meditatii-autentificare-button' onClick={salveazaOrdine} >
                Salveaza ordine
                                </Button>
            <Button style={{ color: 'darkorange', display: 'block', marginTop: 10, margin: 'auto' }} color="primary" className='meditatii-autentificare-button' onClick={openAdd} >
                Meditatie Noua
                                </Button>
        </Paper>
    );
});

export default class Program extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            openCat: false,
            continut: [],
            openAdd: false,
            titlu: '',
            categorie: '',
            categories: [],
            contentList: [],
            counterTime: 4,
        }
    }

    componentDidMount() {
      this.verifyAdmin()
        this.scrollTop()
    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }


    verifyAdmin = async()=>{
        
        let server = await fromServer('users',{mode:'check_if_admin', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune}, {credentials:'include'})

        if(server.r === 'ok'){

            this.getProgram()
        this.getCategories()
        this.getPrograme()
            if(this.state.techDebug === 1){
            
          
            }
        }else{
            this.props.history.push('/')
        }
    }




    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState({
            continut: arrayMove(this.state.continut, oldIndex, newIndex),
        }, () => {
         
        });
    };

    getProgram = async () => {

        let program = await fromServer('programs', {
            mode: 'program_item_admin',
            id_user:getLocalUserData().idul,
            id_sesiune:getLocalUserData().id_sesiune,
            id_program: this.props.match.params.idprogram
        }, { credentials: 'include' })


      

        if (program.r === 'ok') {
            this.setState({
                content: program.continut[0],
                continut: program.programs_info
            })
        }
    }

    getPrograme = async () => {

        let programe = await fromServer('programs', { mode: 'list_contents_for_programs' }, { credentials: 'include' })


       
        if (programe.r === 'ok') {
            this.setState({ contentList: programe.continut })
        }
    }

    getCategories = async () => {


        let categories = await fromServer('category', { mode: 'list_categories_admin' }, { credentials: 'include' })



        if (categories.r === 'ok') {

            let myCategories = categories.continut.filter(c => c.nume_categorie === 'Programe')

         
            this.setState({ categorie: myCategories[0].idul }, () => {
              
            })
        }
       
    }


    deleteProgram = async (i) => {
        let clonePrograme = [...this.state.continut]

      


        let idx = clonePrograme.findIndex(c => c.id_content === i.id_content)

       
        clonePrograme.splice(idx, 1);
        let myCat = [];

        let counter = clonePrograme.length

        for (let i = 0; i < counter; i++) {
            myCat.push(clonePrograme[i].id_content)
        }


        if (myCat.length > 0) {
         

            let getCategorie = await fromServer('programs', {
                mode: 'update_content_program',
                id_program: this.props.match.params.idprogram,
                id_user: getLocalUserData().idul,
                add_contents: myCat.toString()
            }, { credetials: 'include' })



            if (getCategorie.r === 'ok') {

            
                this.setState({ continut: clonePrograme })
            }

        } else {
            this.setState({ continut: clonePrograme })
        }




    }

    salveazaOrdine = async () => {
        let cloneContinut = [...this.state.continut]

        let counter = cloneContinut.length

        let ordine = []

        for (let i = 0; i < counter; i++) {
            ordine.push(cloneContinut[i].id_content)
        }

    

     await fromServer('programs', {
            mode: 'update_content_program',
            id_program: this.props.match.params.idprogram,
            id_user: getLocalUserData().idul,
            add_contents: ordine.toString()
        }, { credetials: 'include' })



    }


    textHandle = camp => ev => {
        let oldState = this.state.content
        oldState[camp] = ev.target.value

        this.setState({ content: oldState })
    }

    selectFile = (id) => {
        let pi = document.getElementById(id);

        pi.click()
    }




    fileChange = async (idfile, idListare) => {
        let saveFile = document.getElementById(idfile).files[0];
        let pozaListare = document.getElementById(idListare)

        if (saveFile) {


            let reader = new FileReader();
            reader.readAsDataURL(saveFile);

            reader.onload = async () => {
                let fileContent = reader.result;
           
                pozaListare.src = fileContent
                await fromServer('programs', { mode: 'insert_list_image', id_program: this.props.match.params.idprogram }, { credentials: 'include', method: 'POST' }, false, 'POST', saveFile, 'file')
          
            }
        }

    }

    filterProgram = () => {
        let filterList = [...this.state.contentList];



       
        let counter = this.state.continut.length

        for (let i = 0; i < counter; i++) {
            filterList = filterList.filter(c => c.id_content !== this.state.continut[i].id_content)
        }
        

        return (
            filterList.map((c, idx) => {
                return (
                    <Chip
                        className="meditatii-chip-categorii"

                        key={idx}
                        label={c.nume}
                        onDelete={() => { this.addProgram(c) }}
                        deleteIcon={<i className="material-icons" style={{ color: 'white' }}>add</i>}
                    />
                )
            })
        )
    }

    closeCategorieAdd = () => {
        this.setState({ openCat: false })
    }

    addProgram = async (i) => {
        let cloneProgram = [...this.state.continut]

        let myCat = [];
        let programNou = {
            id_content: i.id_content,
            nume_content: i.nume
        }
        cloneProgram.push(programNou)

        let counter = cloneProgram.length

        for (let i = 0; i < counter; i++) {
            myCat.push(cloneProgram[i].id_content)
        }




        let nouaOrdine = await fromServer('programs', {
            mode: 'update_content_program',
            id_program: this.props.match.params.idprogram,
            id_user: getLocalUserData().idul,
            add_contents: myCat.toString()
        }, { credetials: 'include' })




        if (nouaOrdine.r === 'ok') {

            this.setState({ continut: cloneProgram })
        }




    }

    insertContent = async () => {

        let insertContent = await fromServer('content', { mode: 'insert_content', name: this.state.titlu, type: 'audio', id_category: this.state.categorie }, { credentials: 'include' })

        if (insertContent.r === 'ok') {
            this.props.history.push(`/audiocontent/${insertContent.id_introdus}`)


        }



    }

    updateContinut = async () => {

        let pret = this.state.content.pret
        let valabil_pana_la = this.state.content.valabil_pana_la

       

        let editProgram = await fromServer('programs', {
            mode: 'update_program',
            id_program: this.props.match.params.idprogram,
            program_name: this.state.content.nume_program,
            subtitle:this.state.content.subtitlu,
            description: this.state.content.descriere,
            valid_from: this.state.content.valabil_de_la,
            valid_until: valabil_pana_la,
            pret: pret
        }, { credentials: 'include' })

    

        if (editProgram.r === 'ok') {
            this.timeOutEdit()
        }
    }

    intervalTime = () => {
        let newCount = this.state.counterTime - 1

        if (this.state.counterTime > 0) {
            this.setState({ counterTime: newCount }, () => {

            })
        } else {
            clearInterval(this.state.intervalId)
        }
    }

    timeOutEdit = () => {
  
        this.setState({
            intervalId: setInterval(this.intervalTime, 1000),
            timeOut: setTimeout(() => {


                this.setState({
                    counterTime: 4,
                }, () => {
                    clearTimeout(this.state.intervalId)
                })
            }, 4000)

        })


    }

    closeAdd = () => {
        this.setState({ openAdd: false })
    }



    render() {
        return (
            <div>
                <AppBar history={this.props.history} />


                <Dialog

                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.openCat}
                    onClose={this.closeCategorieAdd}
                >

                    <DialogActions style={{ justifyContent: 'center' }}>

                        <Button className='meditatii-close-dialog-button' onClick={this.closeCategorieAdd} variant="fab">
                            <i className='material-icons'>clear</i>
                        </Button>

                    </DialogActions>


                    <h2 style={{ padding: '20px 10px 0 10px', marginTop: 20, textAlign: 'center' }} className="meditatii-section-meditezi-title">
                        Adauga Content
                             </h2>

                    <DialogContent>
                        {this.filterProgram()}

                    </DialogContent>



                </Dialog>

                <Dialog

                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.openAdd}
                    onClose={this.closeAdd}
                >

                    <DialogActions style={{ justifyContent: 'center' }}>

                        <Button className='meditatii-close-dialog-button' onClick={this.closeAdd} variant="fab">
                            <i className='material-icons'>clear</i>
                        </Button>

                    </DialogActions>


                    <h2 style={{ padding: '20px 10px 0 10px', marginTop: 20, textAlign: 'center' }} className="meditatii-section-meditezi-title">
                        Adauga Meditatie
                    </h2>

                    <DialogContent style={{ textAlign: 'center' }}>

                        <FormControl className="meditatii-text-field" style={{ width: "100%" }}  >
                            <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-titlu">Titlu</InputLabel>
                            <Input
                                disableUnderline={true}
                                id="meditatii-titlu"
                                type='text'
                                value={this.state.titlu}
                                onChange={(ev) => { this.setState({ titlu: ev.target.value }) }}


                            />
                        </FormControl>



                        <Button style={{ marginTop: 20 }} variant='contained' color="primary" className='meditatii-autentificare-button' onClick={this.insertContent} >
                            Adauga
            </Button>

                    </DialogContent>



                </Dialog>

                <div className="meditatii-back-button" onClick={() => { this.props.history.push('/adminprograme') }}><i className="material-icons meditatii-back-button-icon">reply</i></div>
                <h1 className="section-title" style={{ marginBottom: 15 }}>{this.state.content.nume_program || "Titlu"}</h1>

                <input id="input-poza-listare" type="file" accept="image/*"
                    onChange={() => { this.fileChange("input-poza-listare", 'poza-listare') }}
                    style={{ display: 'none' }}
                ></input>




                <div className="meditatii-section-container" >
                    <Grid container className="meditatii-subsection-container" style={{ marginTop: 80 }}>
                        <Grid style={{ margin: "auto", marginBottom: 100, padding: 10 }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <img alt="meditatie-program-listare" className="logo-img" id="poza-listare" style={{ display: 'block', margin: 'auto' }} src={this.state.content.cale_poza_listare !== null ? imgUpload(this.state.content.cale_poza_listare, 'images') : ''} />
                            <Button style={{ color: 'darkorange' }} color="primary" className='meditatii-autentificare-button' onClick={() => { this.selectFile('input-poza-listare') }} >
                                Adauga poza listare
                                </Button>

                        </Grid>




                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>



                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="continut-titlu">Titlu</InputLabel>
                                    <Input
                                        disableUnderline={true}
                                        id="continut-titlu"
                                        type='text'
                                        value={this.state.content.nume_program || ""}
                                        onChange={this.textHandle('nume_program')}

                                    />
                                </FormControl>

                            </Grid>
                        </Grid>

                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>



                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="continut-subtitlu">Subtitlu</InputLabel>
                                    <Input
                                        disableUnderline={true}
                                        id="continut-subtitlu"
                                        type='text'
                                        value={this.state.content.subtitlu || ""}
                                        onChange={this.textHandle('subtitlu')}

                                    />
                                </FormControl>

                            </Grid>
                        </Grid>
                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={12} lg={12} xl={12}>



                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="continut-descriere">Descriere</InputLabel>
                                    <Input
                                        rowsMax={10}
                                        style={{ maxWidth: "80%" }}
                                        disableUnderline={true}
                                        id="continut-descriere"
                                        multiline
                                        type='text'
                                        value={this.state.content.descriere || ""}
                                        onChange={this.textHandle('descriere')}

                                    />
                                </FormControl>

                            </Grid>
                        </Grid>

                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>



                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel shrink={true} style={{ color: "#bfbfbf" }} htmlFor="continut-valabil_de_la">Valabil de la</InputLabel>
                                    <Input

                                        style={{ maxWidth: "100%" }}
                                        disableUnderline={true}
                                        id="continut-valabil_de_la"

                                        type='date'
                                        value={this.state.content.valabil_de_la || ""}
                                        onChange={this.textHandle('valabil_de_la')}

                                    />
                                </FormControl>




                            </Grid>
                        </Grid>
                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>



                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel shrink={true} style={{ color: "#bfbfbf" }} htmlFor="continut-valabil_pana_la">Valabil pana la</InputLabel>
                                    <Input

                                        style={{ maxWidth: "100%" }}
                                        disableUnderline={true}
                                        id="continut-valabil_pana_la"

                                        type='date'
                                        value={this.state.content.valabil_pana_la || ""}
                                        onChange={this.textHandle('valabil_pana_la')}

                                    />
                                </FormControl>

                            </Grid>
                        </Grid>
                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>



                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="continut-pret">Pret</InputLabel>
                                    <Input
                                        rowsMax={10}
                                        style={{ maxWidth: "80%" }}
                                        disableUnderline={true}
                                        id="continut-pret"
                                        multiline
                                        type='number'
                                        value={this.state.content.pret || ""}
                                        onChange={this.textHandle('pret')}

                                    />
                                </FormControl>

                            </Grid>
                        </Grid>
                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {/* <Paper style={{ paddingBottom: 20, position: 'relative' }}>
                            <h2 className="meditatii-section-meditezi-title">Continut</h2>
                            <Button className='meditatii-add-categorie-button' onClick={() => { this.setState({ openCat: true }) }} variant="fab">
                                <i className='material-icons'>add</i>
                            </Button>
                            {this.state.categorii.map((c, idx) => {
                                return (
                                    <Chip
                                        className="meditatii-chip-categorii"

                                        key={idx}
                                        label={c.nume_categorie}
                                        onDelete={() => { this.deleteCategorie(c) }}
                                        deleteIcon={<i className="material-icons" style={{ color: 'white' }}>delete</i>}
                                    />
                                )
                            })}
                        </Paper> */}
                            <SortableList items={this.state.continut} onSortEnd={this.onSortEnd} salveazaOrdine={this.salveazaOrdine} openList={() => { this.setState({ openCat: true }) }} deleteProgram={this.deleteProgram} openAdd={() => { this.setState({ openAdd: true }) }} />
                        </Grid>



                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={12} lg={12} xl={12}>

                            <Button style={{ color: 'darkorange' }} color="primary" className='meditatii-autentificare-button' onClick={this.updateContinut} >
                                Salveaza Content
                                </Button>
                            <span style={{ display: this.state.counterTime < 4 ? 'block' : 'none', fontSize: 14, textAlign: 'center', color: '#0dd00d' }} >Content actualizat cu succes</span>
                        </Grid>
                    </Grid>
                </div>

            </div >
        );
    }
}

;