import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import { Grid, Divider, Hidden } from '@material-ui/core';
import {  imgUpload, fromServer } from '../../Providers/ws';
import { getLocalUserData, setLocalUserData } from '../../Providers/ls';
import radioLive from '../Pics/radioLive.png'
/*global FB*/



export default class Meditatii extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            categories:[],
            content:[],
            programeTitlu:[],
            
         }
    }

    componentDidMount(){
      
      
       this.getCategories()
        this.getPrograms()

 
        //this.scrollTop()
    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    fbLogOut=()=>{
        FB.getLoginStatus((response) =>{
       

            if(response.status === 'connected'){
                FB.logout(function(response){
                   
                })
            }
            
          });
    }


    logOut=async()=>{

        let user = {
            existaDate:false,
            fbLogin:false,
            esteLogat:false,
            nume:'',
            idul:'',
            email:'',
            nume_utilizator:'',
            admin:'',
            blocat:'',
            id_sesiune:'',
            data_creare_cont:'',
            data_modificare_cont:'',
            label_tip_cont:'',
            cos:''
        }

     
        
       
        setLocalUserData(user)
        
        this.fbLogOut()
    
    }


    getPrograms=async()=>{
        let programs = await fromServer('programs', {mode:'programs_list'}, {credentials:'include'} )

        if(programs.r === 'ok'){
            this.setState({programeTitlu:programs.continut},()=>{
              
            })
        
        }
       
    }

    listPrograms = ()=>{
        let grid=[] ;
        let gridItem = []

        let contentByCat = [...this.state.programeTitlu]

        let counter = this.state.programeTitlu.length

        for(let i = 0; i< counter; i++){
            gridItem.push(
                <Grid onClick={()=>{ this.props.history.push(`/program/${contentByCat[i].idul}`)}} key={i + contentByCat[i].idul} className="meditatii-subsection-subsection"  style={{cursor:"pointer", position:'relative', display:contentByCat[i].ascuns !== 1 ? 'block' : 'none'}}item xs={12} sm={12} md={3} lg={2} xl={2}>
                 <div  style={{width:'fit-content', position:'relative', margin:'auto'}} > 
                 
                 <div   className="logo-img meditatii-background-img"  style={{borderRadius:10, backgroundImage:`url(${imgUpload(contentByCat[i].cale_poza_listare, "images")})`, backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundPosition:'center'}}>
                         </div>
                         <div className='meditatii-play-items'>
                         <div className="meditatii-play-bar-item"><span style={{marginLeft:13}}>audio</span></div>
                            <i className="material-icons " style={{verticalAlign:'middle', fontSize:27, height:'100%', color:'#ea7ab7', paddingTop:3}} >play_arrow</i>
                            </div>
                    
                    
                     </div>
                     
                <h3 className="meditatii-subsection-meditezi-title meditatii-content-name" style={{marginBottom:20, color:'#7a80dc'}}>{contentByCat[i].nume_program}</h3>

            </Grid>
            )
        }
        grid.push(   <div key={1} >
            <Hidden mdUp>
            <Divider/>
            </Hidden>
            
            <h2 className="meditatii-section-meditezi-title" style={{marginTop:50, fontSize:30, fontWeight:700}}>Programe</h2>
            <Grid container >
               
                {gridItem}
                
            </Grid>
            </div>)
            return grid
    }

  

    displayLock=(c)=>{
        let tipUser = getLocalUserData().label_tip_cont
     if(getLocalUserData().esteLogat === true){

        if( getLocalUserData().admin !== '1'){

            if(tipUser !== 'free'){
          
              return 'none'
            }else{
               
              
                    let users = c.is_free_users !== '1' ? 'inline-block' : 'none'
              return users
                }
        }else{
            return 'none'
        }
        }else{
           
            return c.is_free_everybody !== '1' ? 'inline-block' : 'none'
        }
    }


    goToMeditatie = (c)=>{
       
        let tipUser = getLocalUserData().label_tip_cont
        if(getLocalUserData().esteLogat === true){

            if( getLocalUserData().admin !== '1'){
                    if(tipUser !== 'free'){
                        this.props.history.push(`/meditatie/${c.idul}`)
                    }else{
                        
                            let users = c.is_free_users !== '1' ? this.props.history.push('/abonamente') : this.props.history.push(`/meditatie/${c.idul}`)
                        return users
                        }
            }else{
                this.props.history.push(`/meditatie/${c.idul}`)
            }
           }else{
              
               return c.is_free_everybody !== '1' ? this.props.history.push('/inregistreazate/0')  : this.props.history.push(`/meditatie/${c.idul}`)
           }
       }
    
       getRatings = (rating) =>{
           let percent = (parseFloat(Number(rating)) / 5) * 100

      

           let percentRound = `${(Math.round(percent/10)*10)}%`

        
        
       
           return percentRound

        }
    
    disableRightClick=(e)=>{
        e.preventDefault();
    }

    listContent = ()=>{

        let counter1 = this.state.categories.length
        let counter2 = this.state.content.length

        let cloneCategories = [...this.state.categories]
        let cloneContent = [...this.state.content]


        let grid = [];

        for(let i =0; i< counter1; i++){
            let gridItem =[]
            let contentByCat =[];
            contentByCat = cloneContent.filter(c=>c.nume_categorie === cloneCategories[i].nume_categorie)

            
            for(let k=0; k< counter2; k++){
                if(contentByCat[k] !== undefined){
                  
                    gridItem.push(
                        <Grid  key={contentByCat[k].idul} className="meditatii-subsection-subsection"  style={{cursor:"pointer", position:'relative', marginBottom:60}} item xs={12} sm={12} md={3} lg={2} xl={2}>
                         <div  style={{width:'fit-content', position:'relative', margin:'auto'}} > 
                         <div  onClick={()=>{ this.goToMeditatie(contentByCat[k])}} className="logo-img meditatii-background-img"  style={{borderRadius:10, backgroundImage:`url(${imgUpload(contentByCat[k].cale_poza_listare, "images")})`, backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundPosition:'center'}}>
                         </div>
                            <i className="material-icons meditatii-locked-items"  style={{display: this.displayLock(contentByCat[k])}}>lock</i>
                            <div className='meditatii-play-items'>
                            <div className="meditatii-play-bar-item"><span style={{marginLeft:13}}>audio</span></div>
                            <i className="material-icons " onClick={()=>{this.goToMeditatie(contentByCat[k])}} style={{verticalAlign:'middle', fontSize:27, height:'100%', color:'#ea7ab7', paddingTop:3}} >play_arrow</i>
                            
                            </div>
                            
                             </div>
                       
                        <h3 className="meditatii-subsection-meditezi-title meditatii-content-name" onClick={()=>{this.goToMeditatie(contentByCat[k])}} style={{marginTop:10, color:'#7a80dc'}}>{contentByCat[k].nume}</h3>
                        <h3 className="meditatii-subsection-meditezi-title meditatii-content-autor " onClick={()=>{this.props.history.push(`/autor/${contentByCat[k].id_autor}`)}} >{contentByCat[k].nume_autor}</h3>
                             <div style={{width:'100%', textAlign:'center', margin:'auto'}}>

                                <div className="stars-outer-content" id='meditatie-stars-outer'   >
                                        <div className="stars-inner-content" id='meditatie-stars-inner' style={{width:this.getRatings(contentByCat[k].rating )}} ></div>
                                </div>
                       </div>
                             
                            
    
                    </Grid>
                    )
                }
            }

            if(cloneCategories[i].vizibilitate !== '2'){

                grid.push(
                    <div key={i} >
                    <Hidden mdUp>
                    <Divider/>
                    </Hidden>
                    
                    <h2 className="meditatii-section-meditezi-title" style={{marginTop:50, fontSize:30, fontWeight:700}}>{cloneCategories[i].nume_categorie}</h2>
                    <Grid container >
                       
                        {gridItem}
                        
                    </Grid>
                    </div>
                )
            }else{}
        }

       
        return grid
    }



    getContent = async()=>{

        let content = await fromServer('content', {mode:'content_list', id_user:getLocalUserData().idul || 0, id_sesiune: getLocalUserData().id_sesiune || '' }, {credentials:'include'})

       
      
        if(content.r==='ok'){


            this.setState({content:content.continut},()=>{
            if(content.session_status === false){
                this.logOut()
            }
            })
        
        }

       
    }

    getCategories =async ()=>{

        let categories = await fromServer('category',{mode:'list_categories'}, {credentials:'include'})

      
        if(categories.r==='ok'){
            this.setState({categories:categories.continut},()=>{
                this.getContent()
            })
        }
       
    }
    render() { 
        return ( 
            <div onContextMenu={this.disableRightClick}>
                <AppBar history={this.props.history} />
                
                <div className="meditatii-paralax" style={{marginBottom:-30}}>
                    <div className="meditatii-paralax-img-meditatii" style={{height:304}} ></div>
                    <a id='meditatii-redirect-link' href="https://www.youtube.com/channel/UCPMpZtCjoZ2HvxUmMe9d5PA" target='_blank'  rel='noopener noreferrer'>
                        <div  className='meditatii-radio-live' style={{backgroundImage:`url(${radioLive})`}}>
                            
                        </div>
                        </a>
                    <div className="meditatii-paralax-content">
                    
                        <h1 className="meditatii-paralax-content-title " style={{ marginTop: 40 }}>Zeci de medita&#355;ii &#351;i programe care dezvolta fiecare aspect al vie&#355;ii tale</h1>

                    </div>
                </div>
                <div className="meditatii-section-container">
                 
                 {
                   this.listPrograms()
                 }
                        {
                            
                             this.listContent() 
                        }

                  
                </div>
            </div>
         );
    }
}
 
;