import React from 'react';
import AppBar from '../../../Componente/Comune/AppBar'

import { Grid, FormControl, InputLabel, Input, Button, Paper,Select, MenuItem, Chip, Dialog, DialogContent, DialogActions, FormGroup, FormControlLabel, Switch, InputAdornment, IconButton, CircularProgress } from '@material-ui/core'
import Player from '../../../Componente/Comune/Player'
import { imgUpload, fromServer } from '../../../Providers/ws';
import noImg from '../../Pics/noImg.jpeg'
import { getLocalUserData } from '../../../Providers/ls';
const audio = document.createElement('audio')
audio.setAttribute("id", "audioFile")

export default class AudioContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: {},
            counterTime: 4,
            categorii: [],
            categories: [],
            duration: "",
            dataHref: '',
            progressWidth: '0',
            playPause: 'play_arrow',
            volume: "fas fa-volume-up",
            bar1: 'block',
            bar2: 'block',
            bar3: 'block',
            bar4: 'block',
            openA: false,
            openCat: false,
            user: false,
            everybody: false,
            ascuns: false,
            tags: [],
            tag: '',
            stringTags: '',
            progress: false,
            autor:0,
            autori:[],
            loop:false,

        }
    }

    componentDidMount() {
        this.scrollTop()
      this.verifyAdmin()
    }

    verifyAdmin = async()=>{
        
        let server = await fromServer('users',{mode:'check_if_admin', 
        id_user:getLocalUserData().idul, 
        id_sesiune:getLocalUserData().id_sesiune}, 
        {credentials:'include'})

        if(server.r === 'ok'){

            this.getContent()
            this.getCategories()
            //this.removeElement()
            audio.removeAttribute('src')
       
            if(this.state.techDebug === 1){
            
          
            }
        }else{
            this.props.history.push('/')
        }
    }


    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    goBack = () => {
        this.props.history.goBack()
    }


    componentWillUnmount() {

    }

    //removeElement = () => {
        //let el = document.getElementById('audioFile')
    
        // el.remove();
    //}

    getCategories = async () => {

        let categories = await fromServer('category', { mode: 'list_categories_admin' }, { credentials: 'include' })


        if (categories.r === 'ok') {
            this.setState({ categories: categories.continut }, () => {

            })
        }
    }

    getContent = async () => {

        let content = await fromServer('content', { mode: 'content_item_admin', id_content: this.props.match.params.idmeditatie, id_user:getLocalUserData().idul, id_sesiune: getLocalUserData().id_sesiune }, { credentials: 'include' })

        

        if (content.r === 'ok') {
            this.getAutori()
            let tags = []
            if (content.continut[0].tags !== null) {
                tags = content.continut[0].tags.split(',')
            }
            this.setState({
                content: content.continut[0],
                categorii: content.categories_info,
                user: content.continut[0].is_free_users === '1' ? true : false,
                everybody: content.continut[0].is_free_everybody === '1' ? true : false,
                loop:content.continut[0].content_loop === '1' ? true : false,
                 ascuns: content.continut[0].ascuns === '1' ? false : true,
                tags: tags,
                autor:content.continut[0].id_autor || 0
            }, () => {
                if (this.state.content.cale_audio !== null) {


                    audio.src = imgUpload(this.state.content.cale_audio, "audio")
                    audio.onloadedmetadata = () => {

                        let hour = Math.floor(audio.duration / 3600)
                        let minutes = Math.floor(audio.duration / 60);
                        let seconds = Math.ceil(audio.duration - minutes * 60) - 1

                        if (minutes >= 60) {
                            minutes = minutes - 60
                        }

                        hour = hour < 10 ? '0' + hour : hour

                     
                        this.setState({
                            duration: `${hour < '01' ? '' : hour + ':'}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`,
                        })
                    }
                } else {

                }
            })
        }
    }

    playPause = () => {
        audio.currentTime = Math.floor((audio.duration * this.state.progressWidth) / 100)
        audio.ontimeupdate = () => {

            let duration = audio.duration - audio.currentTime

            let hour = Math.floor(duration / 3600)
            let minutes = Math.floor(duration / 60);
            let seconds = Math.ceil(duration - minutes * 60) - 1

            if (minutes >= 60) {
                minutes = minutes - 60
            }

            hour = hour < 10 ? '0' + hour : hour

           
            this.setState({
                progressWidth: audio.currentTime / audio.duration * 100,
                duration: `${hour < '01' ? '' : hour + ':'}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`,

            })
        }
        if (this.state.playPause === 'play_arrow') {
            this.setState({ playPause: 'pause' }, () => {
                audio.play()
            })
        } else {
            this.setState({ playPause: 'play_arrow' }, () => {
                audio.pause()
            })
        }

        audio.onended = () => {
            audio.currentTime = 0
            this.setState({ playPause: 'play_arrow' })
        }
    }

    showCoords = (ev) => {
        let div = ev.clientX;
      
        let offset = div - document.getElementById('bar').offsetLeft



        let bar_width = document.getElementById('bar').clientWidth


        //let coords = "X coords: " + offset + "si sunt la: " + Math.floor((offset / bar_width * 100))



        this.setState({ progressWidth: Math.floor((offset / bar_width * 100)) }, () => {
            this.getAudioTime()
        })

    }

    setVolume = (ev) => {
        let div = ev.clientX
        
        let offset = div - document.getElementById('meditatii-player-volume').offsetLeft
        let bar_width = document.getElementById('meditatii-player-volume').clientWidth




        let vWidth = Math.floor((offset / bar_width * 100))

        if (vWidth < 25) {
            this.setState({
                bar1: 'block',
                bar2: 'none',
                bar3: 'none',
                bar4: 'none'
            }, () => {
                audio.volume = 0
            })
        } else if (vWidth > 25 && vWidth < 50) {
            this.setState({
                bar1: 'block',
                bar2: 'block',
                bar3: 'none',
                bar4: 'none'
            }, () => {
                audio.volume = 0.25
            })
        } else if (vWidth > 50 && vWidth < 75) {
            this.setState({
                bar1: 'block',
                bar2: 'block',
                bar3: 'block',
                bar4: 'none'
            }, () => {
                audio.volume = 0.5
            })
        } else {
            this.setState({
                bar1: 'block',
                bar2: 'block',
                bar3: 'block',
                bar4: 'block'
            }, () => {
                audio.volume = 1
            })
        }

    }
    getAudioTime = () => {

        //let duration = audio.duration







        audio.currentTime = Math.floor((audio.duration * this.state.progressWidth) / 100)
        audio.ontimeupdate = () => {
            let duration = audio.duration - audio.currentTime

            let hour = Math.floor(duration / 3600)
            let minutes = Math.floor(duration / 60);
            let seconds = Math.ceil(duration - minutes * 60) - 1

            if (minutes >= 60) {
                minutes = minutes - 60
            }

            hour = hour < 10 ? '0' + hour : hour

           
            this.setState({
                progressWidth: audio.currentTime / audio.duration * 100,
                duration: `${hour < '01' ? '' : hour + ':'}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`,

            })


        }

    }

    addTag = async () => {
        let tags = [...this.state.tags]



        tags.push(this.state.tag)

        let stringTags = tags.toString()

        let updateTags = await fromServer('content', { mode: 'content_tags', id_content: this.props.match.params.idmeditatie, tags: stringTags }, { credentials: 'include' })



        if (updateTags.r === 'ok') {


           
            this.setState({
                tags: tags,
                stringTags: stringTags,
                tag: ''
            })

        }

    }

    deleteTag = async (c) => {
        let tags = [...this.state.tags]

        let idx = tags.indexOf(c)

        tags.splice(idx, 1)

        let stringTags = tags.toString()

        let updateTags = await fromServer('content', { mode: 'content_tags', id_content: this.props.match.params.idmeditatie, tags: stringTags }, { credentials: 'include' })


        if (updateTags.r === 'ok') {


            
            this.setState({
                tags: tags,
                stringTags: stringTags,
                tag: ''
            })

        }
    }

    deleteCategorie = async (i) => {
        let cloneCategorii = [...this.state.categorii]



        let idx = cloneCategorii.findIndex(c => c.id_categorie === i.id_categorie)

     
        cloneCategorii.splice(idx, 1);
        let myCat = [];

        let counter = cloneCategorii.length

        for (let i = 0; i < counter; i++) {
            myCat.push(cloneCategorii[i].id_categorie)
        }

        let getCategorie;
        if (myCat.length > 0) {

            getCategorie = await fromServer('content', {
                mode: 'update_categories',
                id_content: this.props.match.params.idmeditatie,
                add_categories: myCat.toString()
            }, { credentials: 'include' })


            if (getCategorie.r === 'ok') {
                this.setState({ categorii: cloneCategorii })
            }

        } else {
            this.setState({ categorii: cloneCategorii })
        }


    }

    addCategorie = async (i) => {
        let cloneCategorii = [...this.state.categorii]

        let myCat = [];
        let categorieNoua = {
            id_categorie: i.idul,
            nume_categorie: i.nume_categorie
        }
        cloneCategorii.push(categorieNoua)

        let counter = cloneCategorii.length

        for (let i = 0; i < counter; i++) {
            myCat.push(cloneCategorii[i].id_categorie)
        }


        let getCategorie = await fromServer('content', {
            mode: 'update_categories',
            id_content: this.props.match.params.idmeditatie,
            add_categories: myCat.toString()
        }, { credentials: 'include' })

        if (getCategorie.r === 'ok') {

         
            this.setState({ categorii: cloneCategorii })
        }




    }




    textHandle = camp => ev => {
        let oldState = this.state.content
        oldState[camp] = ev.target.value

        this.setState({ content: oldState })
    }

    selectFile = (id) => {
   
        let pi = document.getElementById(id);

        pi.click()
    }




    fileChange = async (idfile, idListare) => {
        let saveFile = document.getElementById(idfile).files[0];
        let pozaListare = document.getElementById(idListare)

        if (saveFile) {
            if (idListare !== audio) {
                if (idListare === "poza-listare") {
                    let reader = new FileReader();
                    reader.readAsDataURL(saveFile);
                 
                
                    reader.onload = async () => {
                        let fileContent = reader.result;
                
                        pozaListare.src = fileContent
                

                        await fromServer('content', {
                            mode: 'insert_list_image',
                            id_content: this.props.match.params.idmeditatie
                        },
                            { credentials: 'include', method: 'POST' }, false, 'POST', saveFile, 'file')


                    }
                    this.setState({ openA: true })
                } else {

                    let reader = new FileReader();
                    reader.readAsDataURL(saveFile);
                    
                    reader.onload = async () => {
                        let fileContent = reader.result;
                  
                        pozaListare.src = fileContent

                       await fromServer('content', {
                            mode: 'insert_background_image',
                            id_content: this.props.match.params.idmeditatie
                        },
                            { credentials: 'include', method: 'POST' }, false, 'POST', saveFile, 'file')


                    }
                }

            }
            else {
                let reader = new FileReader();
                await reader.readAsDataURL(saveFile);
              

                reader.onload = async () => {
                    let fileContent = reader.result;
                    this.setState({ progress: true })


                    let insertAudio = await fromServer('content', {
                        mode: 'insert_content_sound',
                        id_content: this.props.match.params.idmeditatie
                    },
                        { credentials: 'include', method: 'POST' }, false, 'POST', saveFile, 'file')



                    if (insertAudio.r === 'ok') {
                        await audio.setAttribute('src', fileContent)



                        audio.onloadedmetadata = async () => {

                            let hour = Math.floor(audio.duration / 3600)
                            let minutes = Math.floor(audio.duration / 60);
                            let seconds = Math.ceil(audio.duration - minutes * 60) - 1

                            if (minutes >= 60) {
                                minutes = minutes - 60
                            }

                            await fromServer('content', {
                                mode: 'update_time',
                                time: `${hour < '01' ? '' : hour + ':'}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`,
                                id_content: this.props.match.params.idmeditatie
                            }, { credentials: 'include' })



                            hour = hour < 10 ? '0' + hour : hour

                           
                            this.setState({
                                progress: false,
                                duration: `${hour < '01' ? '' : hour + ':'}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`,

                            })
                        }
                    }


                }

            }
        }
    }

    dialogClose = () => {
        this.setState({ openA: false })
    }

    closeCategorieAdd = () => {
        this.setState({ openCat: false })
    }


    updateBackground = async () => {
        let pozaselect = document.getElementById("poza-listare")
        let pozaListare = document.getElementById("poza-preview")

        pozaListare.src = pozaselect.src
       


      await fromServer('content', {
            mode: 'use_same_image',
            id_content: this.props.match.params.idmeditatie
        }, { credentials: 'include' })



        this.dialogClose()

    }

    filterCategorii = () => {
        let filterList = [...this.state.categories];



        let counter = this.state.categorii.length

        for (let i = 0; i < counter; i++) {
            filterList = filterList.filter(c => c.idul !== this.state.categorii[i].id_categorie)
        }

        return (
            filterList.map((c, idx) => {
                return (
                    <Chip
                        className="meditatii-chip-categorii"

                        key={idx}
                        label={c.nume_categorie}
                        onDelete={() => { this.addCategorie(c) }}
                        deleteIcon={<i className="material-icons" style={{ color: 'white' }}>add</i>}
                    />
                )
            })
        )
    }
    handleChange = name => event => {
        this.setState({ [name]: event.target.checked }, () => {
            if (this.state.everybody === true) {
                this.setState({ user: true })
            }
        });
    };


    updateContinut = async () => {

        let freeUser = this.state.user === true ? 1 : 0;
        let freeEverybody = this.state.everybody === true ? 1 : 0;
        let pret = this.state.content.pret !== null ? this.state.content.pret : 0

        let descriere = this.state.content.descriere === null ? '' : this.state.content.descriere
     

        let updateContinut = await fromServer('content', {
            mode: 'content_details',
            id_content: this.props.match.params.idmeditatie,
            name: this.state.content.nume,
            description: descriere,
            price: pret,
            content_loop:this.state.loop === true ? '1' : '0',
            id_author:this.state.autor,
            is_free_users: freeUser,
            is_free_everybody: freeEverybody
        }, { credentials: 'include' })

        if (updateContinut.r === 'ok') {
            this.timeOutEdit()
        }

    }

    intervalTime = () => {
        let newCount = this.state.counterTime - 1

        if (this.state.counterTime > 0) {
            this.setState({ counterTime: newCount }, () => {

            })
        } else {
            clearInterval(this.state.intervalId)
        }
    }
    timeOutEdit = () => {
        this.setState({
            intervalId: setInterval(this.intervalTime, 1000),
            timeOut: setTimeout(() => {


                this.setState({
                    counterTime: 4,
                }, () => {
                    clearTimeout(this.state.intervalId)
                })
            }, 4000)

        })


    }

    ascundePublica = (ev) => {
        this.setState({ ascuns: ev.target.checked }, async () => {
            if (this.state.ascuns === true) {
                await fromServer('content', { mode: 'publish', id_content: this.props.match.params.idmeditatie }, { credentials: 'include' })


            } else {
                await fromServer('content', { mode: 'unpublish_content', id_content: this.props.match.params.idmeditatie }, { credentials: 'include' })


            }


        })
    }

   
    getAutori=async()=>{

        console.log('getLocalUserData() :', getLocalUserData());
        let autori = await fromServer('author',{mode:'get_authors_list',id_user:getLocalUserData().idul, id_sesiune: getLocalUserData().id_sesiune},{credentials:'include'})

        console.log('autori :', autori);
        if(autori.r === 'ok'){

            let autoriActivi = autori.continut.filter(a=>a.dezactivat !== '1')
            this.setState({autori:autoriActivi,
                            })
        }
    }





    render() {
        return (
            <div>
                <AppBar history={this.props.history} />
                <Dialog

                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.openA}
                    onClose={this.dialogClose}
                >

                    <DialogContent>
                        <h2 className="meditatii-section-meditezi-title">
                            Doriti sa adaugati aceeasi poza si ca poza de background ?
                            </h2>
                    </DialogContent>

                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button variant="contained" onClick={this.dialogClose} className="dialogAnuleaza">
                            NU
                            </Button>
                        <Button variant="contained" onClick={this.updateBackground} className="dialogAdauga">
                            DA
                            </Button>

                    </DialogActions>

                </Dialog>

                <Dialog

                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.openCat}
                    onClose={this.closeCategorieAdd}
                >

                    <DialogActions style={{ justifyContent: 'center' }}>

                        <Button className='meditatii-close-dialog-button' onClick={this.closeCategorieAdd} variant="fab">
                            <i className='material-icons'>clear</i>
                        </Button>

                    </DialogActions>


                    <h2 style={{ padding: '20px 10px 0 10px', marginTop: 20, textAlign: 'center' }} className="meditatii-section-meditezi-title">
                        Adauga Categorie
                             </h2>

                    <DialogContent>
                        {this.filterCategorii()}

                    </DialogContent>



                </Dialog>

                <div className="meditatii-back-button" onClick={() => { this.goBack() }}><i className="material-icons meditatii-back-button-icon">reply</i></div>
                <h1 className="section-title" style={{ marginBottom: 15 }}>{this.state.content.nume || 'Meditatie'}</h1>
                <h2 className="meditatii-section-meditezi-title">{this.state.content.tip_content}</h2>
                <input id="input-poza-listare" type="file" accept="image/*"
                    onChange={() => { this.fileChange("input-poza-listare", 'poza-listare') }}
                    style={{ display: 'none' }}
                ></input>
                <input id="input-poza-preview" type="file" accept="image/*"
                    onChange={() => { this.fileChange('input-poza-preview', 'poza-preview') }}
                    style={{ display: 'none' }}
                ></input>
                <input id="input-audio" type="file" accept="audio/*"
                    onChange={() => { this.fileChange("input-audio", audio) }}
                    style={{ display: 'none' }}
                ></input>



                <div className="meditatii-section-container">
                    <Grid container className="meditatii-subsection-container" style={{ marginTop: 80 }}>
                        <Grid style={{ margin: "auto", marginBottom: 100, padding: 10 }} item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <img alt="meditatie-adauga-listare" className="logo-img" id="poza-listare" src={this.state.content.cale_poza_listare !== null ? (this.state.content.cale_poza_listare !== undefined ? imgUpload(this.state.content.cale_poza_listare, 'images') : noImg) : noImg} />
                            <Button style={{ color: 'darkorange' }} color="primary" className='meditatii-autentificare-button' onClick={() => { this.selectFile('input-poza-listare') }} >
                                Adauga poza listare
                                </Button>

                        </Grid>
                        <Grid style={{ margin: "auto", marginBottom: 100, padding: 10 }} item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <img alt="meditatie-adauga-background" className="logo-img" id="poza-preview" src={this.state.content.cale_poza_background !== null ? (this.state.content.cale_poza_background !== undefined ? imgUpload(this.state.content.cale_poza_background, 'images') : noImg) : noImg} />
                            <Button style={{ color: 'darkorange' }} color="primary" className='meditatii-autentificare-button' onClick={() => { this.selectFile('input-poza-preview') }} >
                                Adauga poza Background
                                </Button>

                        </Grid>
                        <Grid style={{ margin: "auto", marginBottom: 100, padding: 10 }} item xs={12} sm={12} md={4} lg={4} xl={4}>

                            {audio.src && this.state.progress !== true &&

                                <Player
                                    playPauseFunc={this.playPause}
                                    playPause={this.state.playPause}
                                    showCoords={this.showCoords}
                                    progressWidth={this.state.progressWidth}
                                    setVolume={this.setVolume}
                                    bar1={this.state.bar1}
                                    bar2={this.state.bar2}
                                    bar3={this.state.bar3}
                                    bar4={this.state.bar4}
                                    duration={this.state.duration} />
                            }

                            {this.state.progress === true &&
                                <CircularProgress style={{ width: 50, display: 'block', margin: 'auto', color: '#2991d6' }} />
                            }
                            <Button style={{ color: 'darkorange' }} color="primary" className='meditatii-autentificare-button' onClick={() => { this.selectFile('input-audio') }} >
                                Adauga Audio
                                </Button>

                        </Grid>

                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>



                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="continut-titlu">Titlu</InputLabel>
                                    <Input
                                        disableUnderline={true}
                                        id="continut-titlu"
                                        type='text'
                                        value={this.state.content.nume || ""}
                                        onChange={this.textHandle('nume')}

                                    />
                                </FormControl>

                            </Grid>
                        </Grid>
                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>



                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="continut-descriere">Descriere</InputLabel>
                                    <Input
                                        rowsMax={10}
                                        style={{ maxWidth: "80%" }}
                                        disableUnderline={true}
                                        id="continut-descriere"
                                        multiline
                                        type='text'
                                        value={this.state.content.descriere || ""}
                                        onChange={this.textHandle('descriere')}

                                    />
                                </FormControl>

                            </Grid>
                        </Grid>

                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>



                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="continut-pret">Pret</InputLabel>
                                    <Input
                                        rowsMax={10}
                                        style={{ maxWidth: "100%" }}
                                        disableUnderline={true}
                                        id="continut-pret"

                                        type='number'
                                        value={this.state.content.pret || ""}
                                        onChange={this.textHandle('pret')}

                                    />
                                </FormControl>

                            </Grid>
                        </Grid>
                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>



                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl style={{ width: "100%", marginTop: 16 }}>
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="med-autor">Autor</InputLabel>
                                    <Select value={this.state.autor || ""}
                                        onChange={(ev) => { this.setState({ autor: ev.target.value }) }}

                                        input={<Input id="med-autor" />}>


                                        {this.state.autori.map((a, idx) => {
                                            return <MenuItem value={a.idul} key={idx}  > {a.nume}</MenuItem>

                                        })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper style={{ paddingBottom: 20, position: 'relative' }}>
                                <h2 className="meditatii-section-meditezi-title">Categorii</h2>
                                <Button className='meditatii-add-categorie-button' onClick={() => { this.setState({ openCat: true }) }} variant="fab">
                                    <i className='material-icons'>add</i>
                                </Button>
                                {this.state.categorii.map((c, idx) => {
                                    return (
                                        <Chip
                                            className="meditatii-chip-categorii"

                                            key={idx}
                                            label={c.nume_categorie}
                                            onDelete={() => { this.deleteCategorie(c) }}
                                            deleteIcon={<i className="material-icons" style={{ color: 'white' }}>delete</i>}
                                        />
                                    )
                                })}
                            </Paper>
                        </Grid>

                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper style={{ paddingBottom: 20, position: 'relative' }}>
                                <h2 className="meditatii-section-meditezi-title">Tags</h2>
                                <Grid style={{ margin: '5px auto' }} className="meditatii-subsection-subsection" item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <FormControl className="meditatii-text-field"  >
                                        <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-tags">Tag</InputLabel>
                                        <Input
                                            disableUnderline={true}
                                            id="meditatii-tags"

                                            value={this.state.tag}
                                            onChange={(ev) => { this.setState({ tag: ev.target.value }) }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        style={{ color: "#bfbfbf", border: 'none' }}

                                                        onClick={this.addTag}
                                                    >
                                                        <i className="material-icons">add</i>
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>

                                {this.state.tags.map((c, idx) => {
                                    return (
                                        <Chip
                                            className="meditatii-chip-categorii"
                                            key={idx}
                                            label={c}
                                            onDelete={() => { this.deleteTag(c) }}
                                            deleteIcon={<i className="material-icons" style={{ color: 'white' }}>delete</i>}
                                        />
                                    )
                                })}
                            </Paper>
                        </Grid>

                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        
                            <FormGroup style={{ margin: 'auto' }}>
                                <FormControlLabel style={{ margin: 'auto' }}
                                    control={
                                        <Switch
                                            className='meditatii-my-switch'
                                            checked={this.state.loop}
                                            onChange={(ev)=>{this.setState({loop:ev.target.checked})}}
                                            value="loop"
                                            color='default'
                                            checkedIcon={<i className='material-icons' style={{ color: '#06cc06', fontSize: 31 }}>fiber_manual_record</i>}
                                        />
                                    }
                                    label="Loop"
                                />

                                 <FormControlLabel style={{ margin: 'auto' }}
                                    control={
                                        <Switch
                                            className='meditatii-my-switch'
                                            checked={this.state.user}
                                            onChange={this.handleChange('user')}
                                            value="users"
                                            color='default'
                                            checkedIcon={<i className='material-icons' style={{ color: '#06cc06', fontSize: 31 }}>fiber_manual_record</i>}
                                        />
                                    }
                                    label="Gratis pentru utilizatori"
                                />
                                <FormControlLabel style={{ margin: 'auto' }}
                                    control={
                                        <Switch
                                            className='meditatii-my-switch'
                                            checked={this.state.everybody}
                                            onChange={this.handleChange('everybody')}
                                            value="everybody"
                                            color='default'

                                            checkedIcon={<i className='material-icons' style={{ color: '#06cc06', fontSize: 31 }}>fiber_manual_record</i>}
                                        />
                                    }
                                    label="Gratis pentru toti"
                                />
                                <FormControlLabel style={{ margin: 'auto' }}
                                    control={
                                        <Switch
                                            className='meditatii-my-switch'
                                            checked={this.state.ascuns}
                                            onChange={this.ascundePublica}
                                            value="ascuns"
                                            color='default'

                                            checkedIcon={<i className='material-icons' style={{ color: '#06cc06', fontSize: 31 }}>fiber_manual_record</i>}
                                        />
                                    }
                                    label="Publicat"
                                />
                            </FormGroup>
                            <Button style={{ color: 'darkorange' }} color="primary" className='meditatii-autentificare-button' onClick={this.updateContinut} >
                                Salveaza Content
                                </Button>
                            <span style={{ display: this.state.counterTime < 4 ? 'block' : 'none', fontSize: 14, textAlign: 'center', color: '#0dd00d' }} >Content actualizat cu succes</span>
                        </Grid>
                    </Grid>
                </div>

            </div>
        );
    }
}

;