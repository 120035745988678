import React from 'react';




import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {  SwipeableDrawer } from '@material-ui/core';
import { getLocalUserData, setLocalUserData, setBlogAddr } from '../../Providers/ls';
import { fromServer } from '../../Providers/ws';
import patreon from '../../Module/Pics/patreon.png'

/*global FB*/

export default class CAppBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            top: false,
            tabValue: 0,
            loadData:false,
            cos:getLocalUserData().cos
    
        }
    }

    componentDidMount(){

      //this.getContinutCos()
    }

    


    toggleDrawer = (side, open) => () => {
        this.setState({ [side]: open })
    }


  getContinutCos=async()=>{
    let cos = await fromServer('cart', {mode:'get_cart', id_user:getLocalUserData().idul},{credentials:'include'})

    
    if(cos.r==='ok'){

       
    
     
      let user =  getLocalUserData()
       
      user.cos = cos.continut.length
      setLocalUserData(user)
       this.setState({cos:getLocalUserData().cos})

       
    }else{

       let user =  getLocalUserData()
       
        user.cos = 0
        setLocalUserData(user)
     
        
    }
 }



    goNav = (page) => {
     
        document.location.href = `#${page}`

       
           
      
        

    }

    getBlogAddr = async () =>{

        let addr = await fromServer('blog',{mode:'get_blog_address'}, {credentials:'include'})
    
       
         
            setBlogAddr(addr)
        
    }

    goBlog= async ()=>{

        let addr = await fromServer('blog',{mode:'get_blog_address'}, {credentials:'include'})

        console.log('addr :', addr);
    
      
            window.open(addr, '_blank');
        
        
   
     
   

        
    }



    fbLogOut=()=>{
        FB.getLoginStatus((response) =>{
           

            if(response.status === 'connected'){
                FB.logout(function(response){
                   
                })
            }
            
          });
    }


    logOut=async()=>{

        let user = {
            existaDate:false,
            fbLogin:false,
            esteLogat:false,
            nume:'',
            idul:'',
            email:'',
            nume_utilizator:'',
            admin:'',
            blocat:'',
            id_sesiune:'',
            data_creare_cont:'',
            data_modificare_cont:'',
            label_tip_cont:'',
            cos:''
        }

       await fromServer('login',{mode:'delete_session', id_user:getLocalUserData().idul},{credentials:'include'})


       
        setLocalUserData(user)
        window.location.href = '/'
        this.fbLogOut()
    
    }


    goToNewPage = (page) =>{

        window.open(page)
    }

    render() {

        return (
            <div id="Header_wrapper" style={{ height: 80 }}>
                <div style={{ clar: "both" }}></div>
                <SwipeableDrawer
                    className="meditatii-modal"
               
                    open={this.state.top}
                    anchor="top"
                    onClose={this.toggleDrawer('top', false)}
                    onOpen={this.toggleDrawer('top', true)}>

                    <div
                        tabIndex={0}
                        role="button"
                        onClick={this.toggleDrawer('top', false)}
                        onKeyDown={this.toggleDrawer('top', false)}
                    >
                        <div>
                            <List style={{textAlign:"center"}}>
                                <ListItem className="meditatii-nav-list-item" button  onClick={() => { this.goNav("/decesameditam") }}>
                                    <ListItemText primary={<span className="meditatii-nav-list-text">De ce s&#227; meditezi</span>}/>
                                </ListItem>
                                <ListItem className="meditatii-nav-list-item" button  onClick={() => { this.goBlog() }}>
                                    <ListItemText primary={<span className="meditatii-nav-list-text">Blog</span>}/>
                                </ListItem>
                                <ListItem className="meditatii-nav-list-item" button onClick={() => { this.goNav("/meditatii") }}>
                                    <ListItemText primary={<span className="meditatii-nav-list-text">Medita&#355;ii</span>}/>
                                </ListItem>
                                <ListItem style={{display:getLocalUserData().esteLogat === true ? 'none' : 'list-item'}} className="meditatii-nav-list-item" button onClick={() => { this.goNav("/inregistreazate/0") }}>
                                    <ListItemText primary={<span className="meditatii-nav-list-text">&#206;nregistreaz&#227;-te Gratuit</span>}/>
                                </ListItem>
                                <ListItem style={{display:getLocalUserData().esteLogat === true ? 'none' : 'list-item'}} className="meditatii-nav-list-item" button onClick={() => { this.goNav("/autentificare/0") }}>
                                    <ListItemText primary={<span className="meditatii-nav-list-text">Autentificare</span>}/>
                                </ListItem>
                                <ListItem style={{display:getLocalUserData().esteLogat === true ? 'none' : 'list-item'}} className="meditatii-nav-list-item" button onClick={() => { this.goToNewPage("https://www.patreon.com/casameditatiilor") }}>
                                    <ListItemText primary={<span className="meditatii-nav-list-text"> <img src={patreon} style={{width:30, height:30}} />  Patreon</span>}/>
                                </ListItem>
                                <ListItem style={{display:  getLocalUserData().esteLogat === false ? 'none' : (getLocalUserData().admin !== '1' ? 'none' : 'list-item')}} className="meditatii-nav-list-item" button onClick={() => { this.goNav("/administrator") }}>
                                    <ListItemText primary={<span className="meditatii-nav-list-text">Administrator</span>}/>
                                </ListItem>
                                <ListItem style={{display:getLocalUserData().esteLogat === true ? 'list-item' : 'none'}} className="meditatii-nav-list-item" button onClick={() => { this.goNav("/contulmeu")}}>
                                    <ListItemText primary={<span className="meditatii-nav-list-text">Contul Meu</span>}/>
                                </ListItem>
                                <ListItem style={{display:getLocalUserData().esteLogat === true ? 'list-item' : 'none'}} className="meditatii-nav-list-item" button onClick={() => { this.logOut()}}>
                                    <ListItemText primary={<span className="meditatii-nav-list-text">Iesire Cont</span>}/>
                                </ListItem>
                                {/* <ListItem style={{display:getLocalUserData().esteLogat === true ? 'list-item' : 'none'}} className="meditatii-nav-list-item" button onClick={() => { this.goNav("/abonament")}}>
                                    <ListItemText primary={<span className="meditatii-nav-list-text" style={{position:'relative'}}>Cosul meu<span style={{display:getLocalUserData().cos > 0 ? 'inline-block' : 'none', top:-3, right:-20, left:'auto'}} className="meditatii-cos-count">{this.props.cos !== undefined ? this.props.cos : this.state.cos}</span></span>}/>
                                    
                                </ListItem> */}
                            </List>
                        </div>
                        </div>
                        </SwipeableDrawer>


                    <header id="Header">




                        <nav className="navbar navbar-expand-sm  navbar-dark fixed-top meditatii-navbar">



                            <div className="clearfix navbar-container">


                                <div className="logo " >
                                    <a className="navbar-brand navbar-link-logo" href="#/"><img className="logo-img" src={require('../../Module/Pics/logo-web-acasa-casa-meditatiilor.png')} alt='casa-meditatii-logo' /></a>
                                </div>

                           
                                <div className="navbar-list">

                                    <ul className="navbar-nav ">
                                        <li className="nav-item med-nav-item" onClick={() => { this.goNav(`/decesameditam`) }}>
                                            <span style={{ borderBottom: this.props.history.location.pathname === "/decesameditam" ? "2px solid #ea7ab7" : "" }} className="nav-link meditatii-nav-text" ><span style={{ color: this.props.history.location.pathname === "/decesameditam" ? "#ea7ab7" : "black" }} >De ce s&#227; meditezi</span></span>
                                        </li>
                                        <li className="nav-item med-nav-item" onClick={() => { this.goBlog()  }}>
                                            <span style={{ borderBottom: this.props.history.location.pathname === "/blog" ? "2px solid #ea7ab7" : "" }} className="nav-link meditatii-nav-text" ><span style={{ color: this.props.history.location.pathname === "/blog" ? "#ea7ab7" : "black" }} >Blog</span></span>
                                        </li>
                                        <li className="nav-item med-nav-item" onClick={() => { this.goNav("/meditatii") }}>
                                            <span style={{ borderBottom: this.props.history.location.pathname === "/meditatii" ? "2px solid #ea7ab7" : "" }} className="nav-link meditatii-nav-text" ><span style={{ color: this.props.history.location.pathname === "/meditatii" ? "#ea7ab7" : "black" }} >Medita&#355;ii</span></span>
                                        </li>
                                        <li style={{display:getLocalUserData().esteLogat === true ? 'none' : 'list-item'}} className="nav-item med-nav-item" onClick={() => { this.goNav("/inregistreazate/0") }}>
                                            <span className="nav-link meditatii-nav-text" style={{color:'white'}} >&#206;nregistreaz&#227;-te Gratuit</span>
                                        </li>
                                        <li style={{display:getLocalUserData().esteLogat === true ? 'none' : 'list-item'}} className="nav-item med-nav-item" onClick={() => { this.goNav("/autentificare/0") }}>
                                            <span style={{ borderBottom: this.props.history.location.pathname.indexOf(`/autentificare`) !== -1  ? "2px solid #ea7ab7" : "" }} className="nav-link meditatii-nav-text" ><span style={{ color: this.props.history.location.pathname.indexOf(`/autentificare`) !== -1 ? "#ea7ab7" : "black" }} >Autentificare</span></span>
                                        </li>
                                        <li style={{display:getLocalUserData().esteLogat === false ? 'none' : (getLocalUserData().admin !== '1' ? 'none' : 'list-item')}} className="nav-item med-nav-item" onClick={() => { this.goNav("/administrator") }}>
                                            <span style={{ borderBottom: this.props.history.location.pathname === "/administrator" ? "2px solid #ea7ab7" : "" }} className="nav-link meditatii-nav-text" ><span style={{ color: this.props.history.location.pathname === "/administrator" ? "#ea7ab7" : "black" }} >Administrator</span></span>
                                        </li>
                                        <li style={{display:getLocalUserData().esteLogat === true ? 'list-item' : 'none'}} className="nav-item med-nav-item" onClick={() => { this.goNav("/contulmeu") }}>
                                            <span style={{ borderBottom: this.props.history.location.pathname === "/contulmeu" ? "2px solid #ea7ab7" : "" }} className="nav-link meditatii-nav-text" ><span style={{ color: this.props.history.location.pathname === "/contulmeu" ? "#ea7ab7" : "black" }} >Contul Meu</span></span>
                                        </li>
                                        <li style={{display:getLocalUserData().esteLogat === true ? 'list-item' : 'none'}} className="nav-item med-nav-item" onClick={() => { this.logOut() }}>
                                            <span style={{ borderBottom: this.props.history.location.pathname.indexOf(`/autentificare`) !== -1 ? "2px solid #ea7ab7" : "" }} className="nav-link meditatii-nav-text" ><span style={{ color: this.props.history.location.pathname.indexOf(`/autentificare`) !== -1 ? "#ea7ab7" : "black" }} >Iesire Cont</span></span>
                                        </li>
                                        <li className="nav-item med-nav-item" onClick={() => { this.goToNewPage("https://www.patreon.com/casameditatiilor") }}>
                                           <span  style={{verticalAlign:'sub', cursor:'pointer'}}><img src={patreon} style={{width:30, height:30}} /></span>
                                        </li>
                                        {/* <li style={{display:getLocalUserData().esteLogat === true ? 'list-item' : 'none', position:'relative'}} className="nav-item med-nav-item" onClick={() => { this.goNav("/abonament") }}>
                                            <span  className="nav-link meditatii-nav-text" ><span style={{display:getLocalUserData().cos > 0 ? 'inline-block' : 'none'}} className="meditatii-cos-count">{this.props.cos !== undefined ? this.props.cos : this.state.cos}</span><i className='material-icons' style={{ color: this.props.history.location.pathname === "/abonament" ? "#ea7ab7" : "black", verticalAlign:'middle' }}>local_grocery_store</i> <span style={{ color: this.props.history.location.pathname === "/abonament" ? "#ea7ab7" : "black" }} >Cosul meu</span> </span>
                                        </li> */}
                                    </ul>

                                    <i className="material-icons navbar-button" onClick={this.toggleDrawer('top', true)}>dehaze</i>
                                </div>
                            
                            </div>


                        </nav>

                    </header>
               

                    
            </div>


                )
            }
        
}// end class