import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import { getBlogAddr, setBlogAddr } from '../../Providers/ls';
import { fromServer } from '../../Providers/ws';

export default  class Blog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            blogAddr:''
         }
    }

    componentDidMount(){

        this.scrollTop()
       this.setState({blogAddr:getBlogAddr()})
    }

    

    getBlogAddrFromServer = async () =>{

        let addr = await fromServer('blog',{mode:'get_blog_address'}, {credentials:'include'})
    
       if(this.state.blogAddr === null){

         
           setBlogAddr(addr)
          this.setState({blogAddr:addr})
       
       }
    }
    
    componentWillMount(){
        this.getBlogAddrFromServer()
    }
componentWillUnmount(){
    setBlogAddr(null)
}
  

    scrollTop = () => {
    
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }


  
    render() { 
        return (
            <div>
                <AppBar history={this.props.history}  />
              
                <iframe 
                    id="myFrame"
                   title='blog'
                  src= {this.state.blogAddr}// location of external resource 
                           // height of iframe should match the height of containing div
                    marginWidth="0"          // width of iframe margin
                    marginHeight="0"         // height of iframe margin   
                    frameBorder="no"         // frame border preference
                    scrolling="yes"          // instructs iframe to scroll overflow content
                    style={{  width:'100%', 
                        background: '#FFF' ,
                        height:'90vh' }}
       
                        >
                        </iframe>
            </div>
          );
    }
}
 
;