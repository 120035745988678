import React from 'react';

import AppBar from '../../Componente/Comune/AppBar'
import { fromServer } from '../../Providers/ws';
import { Divider, Grid, Hidden,  } from '@material-ui/core';
import {imgUpload} from '../../Providers/ws'
import {getLocalUserData} from '../../Providers/ls'

import defaultImg from '../Pics/defaultImg.png'

export default class Autor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            autor: {nume:'', website:'', descriere:''},
            content: []
        }
    }

    componentDidMount() {
        this.scrollTop()
        this.getAutorInfo()

    }

    getRatings = (rating) =>{
        let percent = (parseFloat(Number(rating)) / 5) * 100

     

        let percentRound = `${(Math.round(percent/10)*10)}%`

     
     
    
        return percentRound

     }

     displayLock=(c)=>{
        let tipUser = getLocalUserData().label_tip_cont
     if(getLocalUserData().esteLogat === true){

        if( getLocalUserData().admin !== '1'){

            if(tipUser !== 'free'){
          
              return 'none'
            }else{
               
              
                    let users = c.is_free_users !== '1' ? 'inline-block' : 'none'
              return users
                }
        }else{
            return 'none'
        }
        }else{
           
            return c.is_free_everybody !== '1' ? 'inline-block' : 'none'
        }
    }


    goToMeditatie = (c)=>{
       
        let tipUser = getLocalUserData().label_tip_cont
        if(getLocalUserData().esteLogat === true){

            if( getLocalUserData().admin !== '1'){
                    if(tipUser !== 'free'){
                        this.props.history.push(`/meditatie/${c.idul}`)
                    }else{
                        
                            let users = c.is_free_users !== '1' ? this.props.history.push('/abonamente') : this.props.history.push(`/meditatie/${c.idul}`)
                        return users
                        }
            }else{
                this.props.history.push(`/meditatie/${c.idul}`)
            }
           }else{
              
               return c.is_free_everybody !== '1' ? this.props.history.push('/inregistreazate/0')  : this.props.history.push(`/meditatie/${c.idul}`)
           }
       }


    listFav = () => {
        let grid = [];
        let gridItem = []

        let fav = [...this.state.content]

        let counter = this.state.content.length

        for (let i = 0; i < counter; i++) {
            gridItem.push(
                <Grid  key={i + fav[i].id_content} className="meditatii-subsection-subsection" style={{ cursor: "pointer", position: 'relative', marginBottom: 60 }} item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <div style={{ width: 'fit-content', position: 'relative', margin: 'auto' }} >
                    <div  onClick={()=>{ this.goToMeditatie(fav[i])}} className="logo-img meditatii-background-img"  style={{borderRadius:10, backgroundImage:`url(${imgUpload(fav[i].cale_poza_listare, "images")})`, backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundPosition:'center'}}>
                         </div>
                      
                       <i className="material-icons meditatii-locked-items"  style={{display: this.displayLock(fav[i])}}>lock</i>
                        <div className='meditatii-play-items'>
                            <div className="meditatii-play-bar-item"><span style={{marginLeft:13}}>audio</span></div>
                            <i className="material-icons " onClick={() => { this.props.history.push(`/meditatie/${fav[i]}`) }}style={{verticalAlign:'middle', fontSize:27, height:'100%', color:'#ea7ab7', paddingTop:3}} >play_arrow</i>
                            
                            </div>

                    </div>

                    <h3 onClick={() => { this.props.history.push(`/meditatie/${fav[i].id_content}`) }} className="meditatii-subsection-meditezi-title meditatii-content-name" style={{marginTop:10, color:'#7a80dc'}}>{fav[i].nume_content}</h3>
                    <h3 className="meditatii-subsection-meditezi-title meditatii-content-autor ">{fav[i].nume}</h3>
                            
                    <div style={{ width: '100%', textAlign: 'center', margin: 'auto' }}>

                        <div className="stars-outer-content" id='meditatie-stars-outer'   >
                            <div className="stars-inner-content" id='meditatie-stars-inner' style={{ width: this.getRatings(fav[i].rating) }} ></div>
                        </div>
                    </div>

                </Grid>
            )
        }
        grid.push(<div key={1} >
            <Hidden mdUp>
                <Divider />
            </Hidden>

            <h2 className="meditatii-section-meditezi-title" style={{ marginTop: 50, fontSize: 30, fontWeight: 700 }}>Meditatii</h2>
            <Grid container style={{ margin: 'auto', justifyContent: 'center', maxWidth: 960 }} >

                {gridItem}

            </Grid>
        </div>)
        return grid
    }

    getAutorInfo = async () => {
        let autor = await fromServer('author', { mode: 'get_author_content', id_author: this.props.match.params.idautor }, { credentials: 'include' })

        if (autor.r === 'ok') {
            this.setState({
                autor: autor.continut[0],
                content: autor.continut
            })
        }
       
    }

    disableRightClick = (e) => {
        e.preventDefault();
    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    render() {
        return (
            <div onContextMenu={this.disableRightClick}>
                <div className="meditatii-back-button" onClick={() => { this.props.history.goBack() }}><i className="material-icons meditatii-back-button-icon">reply</i></div>

                <AppBar history={this.props.history} />
                <div className="meditatii-section-container">
                    <h1 className="meditatii-meditatie-title" style={{ marginBottom: 10, textAlign: 'right' }}  >{this.state.autor.nume}</h1>
                    <p className="meditatii-section-paragraph meditatii-content-name" style={{ textAlign: 'right', marginBottom: 20, color: '#7a80dc', cursor: 'pointer', fontSize: 18, marginRight: 30 }} onClick={() => { document.location.href = `http://${this.state.autor.website}` }} >{this.state.autor.website}</p>
                    <p className="meditatii-section-paragraph meditatii-content-name" style={{ textAlign: 'center', marginBottom: 20, color: 'rgb(127, 135, 140)', cursor: 'pointer', fontSize: 18, }} >{this.state.autor.descriere}</p>
                    <Divider />
                  {  this.listFav() }
                </div>

            </div>
        );
    }
}

;