import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'

import { Grid } from '@material-ui/core'


export default class DeCeSaMeditezi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount(){
        this.scrollTop()
       
    }

  

    scrollTop=()=>{
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    render() {
        return (
            <div>
                <AppBar history={this.props.history} />
                <div className="meditatii-section-container" style={{position:'relative'}} >
              
                
                    <h1 className="section-title">De ce s&#259; meditezi</h1>
                    <Grid container className="meditatii-subsection-container" style={{ maxWidth: 1200, justifyContent: "center" }}>
                   
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <img alt="meditatie-de-ce-stres" className="logo-img" src={require('../../Module/Pics/de-ce-stres.png')} />
                            <h3 className="meditatii-subsection-meditezi-title">Elimini stresul</h3>

                        </Grid>
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <img alt="meditatie-de-ce-creativitate" className="logo-img" src={require('../../Module/Pics/de-ce-creativitate.png')} />
                            <h3 className="meditatii-subsection-meditezi-title">Stimulezi creativitatea</h3>
                        </Grid>

                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <img alt="meditatie-de-ce-concentrare" className="logo-img" src={require('../../Module/Pics/de-ce-concentrare.png')} />
                            <h3 className="meditatii-subsection-meditezi-title">&#206;mbun&#259;t&#259;&#355;e&#351;ti concentrarea</h3>
                        </Grid>

                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <img alt="meditatie-de-ce-anxietate" className="logo-img" src={require('../../Module/Pics/de-ce-anxietate.png')} />
                            <h3 className="meditatii-subsection-meditezi-title">Reduci anxietatea &#351;i depresia </h3>
                        </Grid>
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <img alt="meditatie-de-ce-relatii" className="logo-img" src={require('../../Module/Pics/de-ce-relatii.png')} />
                            <h3 className="meditatii-subsection-meditezi-title">Pentru rela&#355;ii bune</h3>
                        </Grid>
                    </Grid>

                </div>
                <div className="meditatii-paralax">
                    <div className="meditatii-paralax-img-meditezi" >
                    
                        <h3 className="meditatii-paralax-content-title meditatii-paralax-center-title">Medita&#355;ia elimin&#259; stresul</h3>
                    </div>
                    
                </div>
                <div className="meditatii-section-container">
                    <h2 className="meditatii-section-meditezi-title">E&#351;ti stresat? Ai probleme &#238;n rela&#355;ia cu partenerul, serviciu sau cu banii?</h2>
                    <p className="meditatii-section-paragraph" style={{ marginBottom: 30 }}>Speciali&#351;tii numesc stres aceast&#259; stare de presiune emo&#355;ional&#259; &#351;i mental&#259; care se acumuleaz&#259;. </p>
                    <p className="meditatii-section-paragraph" style={{ marginBottom: 30 }}>Corpul r&#259;spunde stresului prin instalarea unor simptome precum dureri de cap, tensiuni la nivelul mu&#351;chilor, probleme cu stomacul, pierderea poftei de m&#226;ncare, dificult&#259;&#355;i de concentrare, insomnii, iritabilitate, imb&#259;tranire prematur&#259; și… lista continu&#259;. </p>
                    <p className="meditatii-section-paragraph" >Afl&#259; c&#259; nu e&#351;ti singur: un studiu arat&#259; c&#259; 75% dintre rom&#226;ni spun c&#259; se simt stresa&#355;i &#351;i suprasolicita&#355;i la locul de munc&#259;, iar 80% se pl&#226;ng de probleme cu somnul, migrene și oboseal&#259;.</p>
                    <h2 className="meditatii-section-meditezi-title">Cum poate medita&#355;ia s&#259; elimine stresul?</h2>
                    <p className="meditatii-section-paragraph" style={{ marginBottom: 30 }}>Medita&#355;ia ac&#355;ioneaz&#259; direct asupra sistemului nervos, u&#351;ur&#226;ndu-i activitatea. C&#226;nd meditezi, ritmul inimii scade, respira&#355;ia &#238;ncetine&#351;te &#351;i scade tensiunea arterial&#259;. Toate acestea au un efect direct de combatere a stresului &#351;i de revigorare &#351;i relaxare a min&#355;ii &#351;i corpului, contribuind astfel la starea general&#259; de bine. </p>
                    <p className="meditatii-section-paragraph" style={{ marginBottom: 30 }}>Neurocercet&#259;torii au descoperit c&#259; pe m&#259;sur&#259; ce continui s&#259; meditezi, creierul se modific&#259; din punct de vedere fizic, chiar dac&#259; nu e&#351;ti con&#351;tient de acest lucru. Milioane de oameni folosesc medita&#355;ia pentru a face fa&#355;&#259; stresului zilnic &#351;i pentru a echilibra mintea. Hai s&#259; meditezi &#351;i tu cu noi.</p>
                </div>

                <div className="meditatii-paralax">
                    <div className="meditatii-paralax-img-meditezi2" >
                    
                        <h2 className="meditatii-paralax-content-title meditatii-paralax-center-title">Medita&#355;ia stimuleaz&#259; creativitatea</h2>
                    </div>
                
                </div>

                <p className="meditatii-section-paragraph" style={{ marginBottom: 30 }}>Indiferent de locul de munc&#259;, fie c&#259; lucrezi &#238;n v&#226;nz&#259;ri, &#238;n sectorul medical sau c&#226;n&#355;i la pian, medita&#355;ia te poate ajuta. O minte creativ&#259; rezolv&#259; situa&#355;ii, &#238;mbun&#259;t&#259;&#355;este procese &#351;i comunic&#259; mai bine. Chiar dac&#259; ai probleme, creativitatea te va ajut&#259; s&#259; le faci fa&#355;&#259; mai bine.</p>
                <p className="meditatii-section-paragraph" style={{ marginBottom: 30 }}>Tr&#259;im timpuri interesante &#238;n care oamenii de &#351;tiin&#355;&#259; g&#259;sesc dovezi clare care sus&#355;in faptul c&#259; medita&#355;ia ajut&#259; oamenii s&#259; fie mai creativi. Studii &#351;tiin&#355;ifice realizate de universit&#259;&#355;i din Europa și Statele Unite au testat aceast&#259; teorie &#351;i au descoperit c&#259; medita&#355;ia ajut&#259; la g&#259;sirea rapid&#259; a solu&#355;iilor, reduc&#226;nd astfel rigiditatea în g&#226;ndire care apare atunci c&#226;nd activit&#259;&#355;ile noastre devin o rutin&#259;.</p>
                
                <div className="meditatii-paralax">
                    <div className="meditatii-paralax-img-meditezi3" >
                    
                        <h2 className="meditatii-paralax-content-title meditatii-paralax-center-title" >Medita&#355;ia te ajut&#259; s&#259; te concentrezi</h2>
                    </div>
         
                </div>

                <p className="meditatii-section-paragraph" style={{ marginBottom: 30 }}>Concentrarea este esen&#355;ial&#259; pentru a duce lucrurile la bun sf&#226;r&#351;it &#351;i pentru a performa în domeniul t&#259;u. Fie c&#259; &#238;&#355;i place s&#259; c&#226;n&#355;i la diverse instrumente, fie c&#259; e&#351;ti elev, sportiv, pensionar, muncitor, doctor sau ai orice alt&#259; meserie, modul &#238;n care ac&#355;ionezi sau &#238;nve&#355;i lucrurile noi depinde de capacitatea fiec&#259;ruia de concentrare. Este adev&#259;rat c&#259; oamenii de succes se concentrez&#259; mai u&#351;or. Rezultatele cercet&#259;rilor arat&#259; c&#259; cei care mediteaz&#259;, comparativ cu cei care nu o fac, au capacitatea s&#259; “controleze” mintea mai ușor, nel&#259;sand-o s&#259; zboare peste tot. </p>
                <p className="meditatii-section-paragraph" style={{ marginBottom: 30 }}>Medita&#355;ia cre&#351;te capacitatea de a fi atent. Iar asta este o veste bun&#259;, &#355;in&#226;nd cont de cantitatea de informa&#355;ii cu care suntem bombarda&#355;i frecvent. </p>
                <h2 className="meditatii-section-meditezi-title">Ai vrea s&#259; fii în stare s&#259; te concentrezi mai bine la ce faci acum?</h2>
                <p className="meditatii-section-paragraph" style={{ marginBottom: 30 }}>Medita&#355;ia este deseori catalogat&#259; ca o practic&#259; religioas&#259; ciudat&#259; sau o activitate stranie, de ne&#238;n&#355;eles, dar aceste lucruri sunt departe de adev&#259;r. Dac&#259; vrei s&#259; imbun&#259;t&#259;&#355;e&#351;ti capacitatea de concentrare, s&#259; &#238;&#355;i gestionezi eficient aten&#355;ia &#351;i s&#259; te concentrezi &#238;n situa&#355;ii critice… mediteaz&#259;. Este a&#351;a de simplu.</p>
           
                <div className="meditatii-paralax">
                    <div className="meditatii-paralax-img-meditezi4" >
                    
                        <h2 className="meditatii-paralax-content-title meditatii-paralax-center-title" >Medita&#355;ia lupt&#259; &#238;mpotriva anxiet&#259;&#355;ii &#351;i depresiei</h2>
                    </div>
                   
                </div>

                <h2 className="meditatii-section-meditezi-title">&#206;&#355;i faci griji des?</h2>
                <p className="meditatii-section-paragraph" style={{ marginBottom: 30 }}>Toat&#259; lumea &#238;&#351;i face griji, deci nu e&#351;ti singur. Depresia este denumit&#259; boala secolului &#351;i în Rom&#226;nia afecteaz&#259; unul din zece rom&#226;ni. S-a descoperit c&#259; medita&#355;ia te &#238;nva&#355;&#259; cum s&#259; faci fa&#355;&#259; dificult&#259;&#355;ilor &#351;i stresului. Studii &#351;tiin&#355;ifice arat&#259; c&#259; cei care practic&#259; medita&#355;ia au observat reduceri cu pan&#259; la 90% ale grijilor &#351;i depresiei. </p>
                <p className="meditatii-section-paragraph" style={{ marginBottom: 30 }}>Neurocercet&#259;torii de la Universitatea Stanford (SUA) au descoperit c&#259; cei care practic&#259; medita&#355;ia timp de 8 s&#259;pt&#259;m&#226;ni pot s&#259; &#238;&#351;i contoleze frica mai bine. Iar cei de la Universitatea Harvard (SUA) au descoperit c&#259; medita&#355;ia poate reduce num&#259;rul de neuroni &#238;n por&#355;iunea din creier care declan&#351;eaz&#259; frica. F&#259;r&#259; suport neuronal, frica nu se mai manifest&#259; a&#351;a intens. </p>
                <p className="meditatii-section-paragraph" style={{ marginBottom: 30 }}>Dac&#259; suferi de st&#259;ri de anxietate &#351;i &#238;&#355;i faci griji constant, e&#351;ti &#238;n locul potrivit. Mediteaz&#259; cu noi &#351;i d&#259; grijile… la minim.</p>
            
                <div className="meditatii-paralax">
                    <div className="meditatii-paralax-img-meditezi5" >
                        <h2 className="meditatii-paralax-content-title meditatii-paralax-center-title" >Medita&#355;ia &#238;mbun&#259;t&#259;&#355;e&#351;te rela&#355;iile</h2>
                    
                    </div>
                   
                </div>

                <p className="meditatii-section-paragraph" style={{ marginBottom: 30 }}>Atunci c&#226;nd e&#351;ti &#238;ntr-o rela&#355;ie, vrei s&#259; te sim&#355;i bine, s&#259; dureze, s&#259; fi&#355;i ferici&#355;i &#351;i s&#259; &#238;mb&#259;trani&#355;i frumos impreun&#259;. Dar chiar &#351;i Dalai Lama spune c&#259; toat&#259; lumea se ceart&#259;. Problemele &#238;n cuplu sunt inevitabile &#351;i foarte des dai vina fie pe partner, fie pe tine. Po&#355;i s&#259; fii complet nefericit &#351;i asta te face o companie de evitat. </p>
                <p className="meditatii-section-paragraph" style={{ marginBottom: 30 }}>Medita&#355;ia te poate ajuta s&#259; fii mai tolerant at&#226;t cu tine, dar &#351;i cu jum&#259;tatea ta. Unul din beneficiile demonstrate ale medita&#355;iei este c&#259; &#238;mbun&#259;t&#259;&#355;e&#351;te rela&#355;iile, fie c&#259; e&#351;ti sau nu c&#259;s&#259;torit. Medita&#355;ia &#238;&#355;i transform&#259; lumea &#351;i te ajut&#259; s&#259; o vezi cu al&#355;i ochi. Te sim&#355;i mai bine cu tine &#351;i acest lucru va fi observat &#351;i de cei din jurul t&#259;u. Cercet&#259;torii spun c&#259; cei care mediteaz&#259; se simt mai bine &#238;n rela&#355;ii &#351;i au un grad mai mare de satisfac&#355;ie. Calit&#259;&#355;i ca empatia, toleran&#355;a fa&#355;&#259; de al&#355;ii &#351;i con&#351;tientizarea sinelui sunt dezvoltate de medita&#355;ie. </p>
                <p className="meditatii-section-paragraph" style={{ marginBottom: 30 }}>Acum c&#259; ai v&#259;zut cum medita&#355;ia &#238;&#355;i face via&#355;a mai bun&#259;, e timpul s&#259; &#238;ncerci &#351;i tu. Creeaz&#259;-&#355;i cont gratuit &#351;i po&#355;i &#238;ncepe imediat.</p>


                 <div className="meditatii-paralax">
                    <div className="meditatii-paralax-img1"></div>
                    <div className="meditatii-paralax-content">
                        <h2 className="meditatii-paralax-content-title">&#206;NREGISTREAZ&#258;-TE GRATUIT</h2>
                        <p className="meditatii-paralax-subtitle">&#351;i vei primi &#238;nregistr&#259;ri transforma&#355;ionale pentru &#238;mbun&#259;t&#259;&#355;irea fiec&#259;rui aspect al vie&#355;ii tale. </p>
                        <div className="mediteaza-home-register" onClick={()=>{this.props.history.push("/inregistreazate/0")}}>
                            <h2 className="mediteaza-register-text" >&#206;nregistreaz&#259;-te gratuit &#351;i prime&#351;ti cadou 20 de medita&#355;ii </h2>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

