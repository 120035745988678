import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'

import {Grid, Button, FormControl, InputLabel, Input, Select, MenuItem} from '@material-ui/core'
import { imgUpload, fromServer } from '../../Providers/ws';
import { valabilitate } from '../../Providers/utile';
import { getLocalUserData } from '../../Providers/ls';

export default class Categorie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: {},
            valabil:"",
            valabilitate:valabilitate

        }
    }
    componentDidMount() {
       this.verifyAdmin()
    }

    goBack = () => {
        this.props.history.goBack()
    }

    verifyAdmin = async()=>{
        
        let server = await fromServer('users',{mode:'check_if_admin', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune}, {credentials:'include'})

        if(server.r === 'ok'){

            this.getCategory()
            if(this.state.techDebug === 1){
   
            }
        }else{
            this.props.history.push('/')
        }
    }


    textHandle = camp => ev => {
        let oldState = this.state.content
        oldState[camp] = ev.target.value

        this.setState({ content: oldState })
    }


    selectFile = (id) => {
        let pi = document.getElementById(id);

        pi.click()
    }

    fileChange = async (idfile, idListare) => {
        let saveFile = document.getElementById(idfile).files[0];
        let pozaListare = document.getElementById(idListare)
        let reader = new FileReader();
        reader.readAsDataURL(saveFile);

        reader.onload = async () => {
            let fileContent = reader.result;
          
            pozaListare.src = fileContent
      

            await fromServer('category', {
                mode: 'insert_list_image',
                id_category: this.props.match.params.idcategorie
            },
                { credentials: 'include', method: 'POST' }, false, 'POST', saveFile, 'file')


        }
        
    }

    getCategory = async () => {

        let categories = await fromServer('category', {mode:'get_category', id_category:this.props.match.params.idcategorie}, {credentials:'include'})

        
        if (categories.r === 'ok') {
            this.setState({ content: categories.continut[0],
                            valabil:categories.continut[0].vizibilitate }, () => {

            })
        }
    }

    updateCategorie= async()=>{

        await fromServer('category', {mode:'update_category', 
                                                   id_category:this.props.match.params.idcategorie,
                                                    tagline:this.state.content.subtitlu,
                                                    category_name:this.state.content.nume_categorie,
                                                    visibility:this.state.valabil
                                                        }, {credentials:'include'})                                           
    }


    
    render() {
        return (
            <div>
                <AppBar history={this.props.history} />
                <div className="meditatii-back-button" onClick={() => { this.goBack() }}><i className="material-icons meditatii-back-button-icon">reply</i></div>
                <h1 className="section-title" style={{ marginBottom: 15 }}>{this.state.content.nume_categorie || 'Categorie'}</h1>
                <input id="input-poza-listare" type="file" accept="image/*"
                    onChange={() => { this.fileChange("input-poza-listare", 'poza-listare') }}
                    style={{ display: 'none' }}
                ></input>

                <div className="meditatii-section-container">
                    <Grid container className="meditatii-subsection-container" style={{ marginTop: 80 }}>
                        <Grid style={{ margin: "auto", marginBottom: 100, padding: 10 }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <img  alt="meditatie-adauga-listare" className="logo-img" id="poza-listare" src={this.state.content.cale_poza_listate !== null ? imgUpload(this.state.content.cale_poza_listare, 'images') : ''} />
                            <Button style={{ color: 'darkorange', display:'block', margin:'auto' }} color="primary" className='meditatii-autentificare-button' onClick={() => { this.selectFile('input-poza-listare') }} >
                                Adauga poza categorie
                                </Button>

                        </Grid>
                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>



                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="continut-titlu">Titlu</InputLabel>
                                    <Input
                                        disableUnderline={true}
                                        id="continut-titlu"
                                        type='text'
                                        value={this.state.content.nume_categorie || ""}
                                        onChange={this.textHandle('nume_categorie')}

                                    />
                                </FormControl>

                            </Grid>
                        </Grid>
                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>



                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="continut-titlu">Subtitlu</InputLabel>
                                    <Input
                                        disableUnderline={true}
                                        id="continut-titlu"
                                        type='text'
                                        value={this.state.content.subtitlu || ""}
                                        onChange={this.textHandle('subtitlu')}

                                    />
                                </FormControl>

                            </Grid>
                        </Grid>
                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>

                        <FormControl style={{width:"100%", marginTop:16}}> 
                                    <InputLabel htmlFor="val">Vizibilitate</InputLabel>
                                    <Select value={this.state.valabil || ""}
                                            onChange={(ev)=>{this.setState({valabil:ev.target.value})}}
                                           
                                            input={<Input id="val"/>}>
                                                
                                            
                                                { this.state.valabilitate.map((m,idx)=>{
                                                    return <MenuItem value={m.idul}  key={idx}  > {m.nume}</MenuItem>
                                                    
                                                })
                                            }
                                    </Select>
                                </FormControl>
                        </Grid>
                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={{ color: 'darkorange' }} color="primary" className='meditatii-autentificare-button' onClick={this.updateCategorie} >
                                Salveaza Categorie
                         </Button>
                        </Grid>
                    </Grid>

                </div>
            </div>

        );
    }
}