import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import { Grid, Button, Card, FormControl, InputLabel, Input, IconButton, InputAdornment, Paper, Tab, Tabs, Hidden, Divider} from '@material-ui/core'
import { imgUpload, fromServer } from '../../Providers/ws';
import { arataData } from '../../Providers/utile';
import { setLocalUserData, getLocalUserData } from '../../Providers/ls';
import defaultImg from '../Pics/defaultImg.png'
/* global FB */

export default class ContulMeu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nume: getLocalUserData().nume || '',
            email: getLocalUserData().email || '',
            user: getLocalUserData().nume_utilizator || '',
            schimbaComponenta: false,
            parolaveche: '',
            parolanoua: '',
            confirmaparola: '',
            showPassword1: false,
            showPassword2: false,
            showPassword3: false,
            tabsValue:0,
            favorite:[],
            valabilitateAb:'',
            abonament:false,
            tranzactii:[],
            mesaj:''
        }
    }

    componentDidMount(){
        this.getFavorites()
        this.getDateAbonament()
       this.getTranzactii()
       this.scrollTop()
    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }


    fbLogOut=()=>{
        FB.getLoginStatus((response) =>{

            if(response.status === 'connected'){
                FB.logout(function(response){
                   
                })
            }
            
          });
    }


    logOut=async()=>{

        let user = {
            existaDate:false,
            fbLogin:false,
            esteLogat:false,
            nume:'',
            idul:'',
            email:'',
            nume_utilizator:'',
            admin:'',
            blocat:'',
            id_sesiune:'',
            data_creare_cont:'',
            data_modificare_cont:'',
            label_tip_cont:'',
            cos:''
        }

     
        
       
        setLocalUserData(user)
        
        this.fbLogOut()
        this.props.history.push('/autentificare/0')
    }


    getTranzactii = async()=>{
        let tranzactie = await fromServer('transactions',{mode:'get_user_transactions', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune}, {credentials:'include'})

        if(tranzactie.r==='ok'){
            this.setState({tranzactii:tranzactie.continut},()=>{
               
            })
        }

       
    }

    getDateAbonament= async()=>{
        let abonament = await fromServer('cart', {mode:'get_subscription_expire_date', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune}, {credentials:'include'})
      
 
        
        if(abonament.r === 'ok'){
           if(abonament.continut.length > 0){

               let d1 = new Date()
               let d2 = new Date(abonament.continut[0].data_expirare)
  
                  let fullYeard1 = d1.getFullYear()
                  let fullYeard2 = d2.getFullYear()
  
                  let total = fullYeard2 - fullYeard1
  
                  
  
                  if(total > 100){
                     this.setState({valabilitateAb:`Valabil pe viata`,
                                    abonament:true}) 
                  }else{
                      this.setState({valabilitateAb : `Valabil pana la : ${arataData(abonament.continut[0].data_expirare)}`,
                                        abonament:true})
                  }
              
           }
        }else{
            this.setState({valabilitateAb:abonament.mesaj,
                          abonament:false})
        }
    }

    schimbaComponenta = () => {
        if (this.state.schimbaComponenta === false) {

            this.setState({
                schimbaComponenta: true,
                parolanoua: '',
                parolaveche: '',
                confirmaparola: ''
            })
        }
        else {
            this.setState({ schimbaComponenta: false })
        }
    }

    getFavorites = async()=>{

        let fav = await fromServer('favorites', {mode:'get_user_favorites', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune}, {credentials:'include'})
        

        if(fav.r==='ok'){
        this.setState({favorite:fav.continut})
        
        }else{
            this.logOut()
        }

    }

    listFav = ()=>{
        let grid=[] ;
        let gridItem = []

        let fav = [...this.state.favorite]

        let counter = this.state.favorite.length

        if(counter > 0){

        

        for(let i = 0; i< counter; i++){
            gridItem.push(
                <Grid onClick={()=>{ this.props.history.push(`/meditatie/${fav[i].id_content}`)}} key={fav[i].id_content} className="meditatii-subsection-subsection"  style={{cursor:"pointer", position:'relative', marginBottom:60}}item xs={12} sm={12} md={3} lg={2} xl={2}>
                 <div  style={{width:'fit-content', position:'relative', margin:'auto'}} > 
                 <div   className="logo-img meditatii-background-img"  style={{borderRadius:10, backgroundImage:`url(${imgUpload(fav[i].cale_poza_listare, "images")})`, backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundPosition:'center'}}>
                         </div>
                  
                 <div className='meditatii-play-items'>
                            <div className="meditatii-play-bar-item"><span style={{marginLeft:13}}>audio</span></div>
                            <i className="material-icons " style={{verticalAlign:'middle', fontSize:27, height:'100%', color:'#ea7ab7', paddingTop:3}} >play_arrow</i>
                            
                            </div>
                    
                    
                     </div>
                     
                <h3 className="meditatii-subsection-meditezi-title meditatii-content-name" style={{marginTop:10, color:'#7a80dc'}}>{fav[i].nume}</h3>
                <h3 className="meditatii-subsection-meditezi-title meditatii-content-autor " onClick={()=>{this.props.history.push(`/autor/${fav[i].id_autor}`)}} >{fav[i].nume_autor}</h3>
                    
            </Grid>
            )
        }
        grid.push(   <div key={1} >
            <Hidden mdUp>
            <Divider/>
            </Hidden>
            
            <h2 className="meditatii-section-meditezi-title" style={{marginTop:50, fontSize:30, fontWeight:700}}>Favorite</h2>
            <Grid container >
               
                {gridItem}
                
            </Grid>
            </div>)
            return grid
        }else{
            grid.push(<div key={1} >
                <Hidden mdUp>
                <Divider/>
                </Hidden>
                
                <h2 className="meditatii-section-meditezi-title" style={{marginTop:50, fontSize:30, fontWeight:700}}>Favorite</h2>
                            <span>Nu aveti nimic adaugat la favorite</span>
                </div>)
                return grid
        }
    }


    updateUser = async () => {

        let update = await fromServer('insert_account', {id:getLocalUserData().idul,
                                                         email:this.state.email,
                                                         name: this.state.nume,
                                                         id_sesiune:getLocalUserData().id_sesiune,
                                                        mode:'edit_user'}, {credentials:'include'})


        if (update.r === 'ok') {
            let user = {
                esteLogat: true,
                fbLogin:getLocalUserData().fbLogin,
                nume: this.state.nume,
                idul: getLocalUserData().idul,
                email: this.state.email,
                nume_utilizator: getLocalUserData().nume_utilizator,
                admin: getLocalUserData().admin,
                blocat: getLocalUserData().blocat,
                data_creare_cont: getLocalUserData().data_creare_cont,
                data_modificare_cont: getLocalUserData().data_modificare_cont,
                label_tip_cont: getLocalUserData().label_tip_cont,
                id_sesiune:getLocalUserData().id_sesiune
            }
       
            setLocalUserData(user)


            this.setState({mesaj:update.mesaj})


        }
    }

    Blank = () => {
        this.setState({ parolanoua: '', parolaveche: '', confirmaparola: '' })
    }




    render() {
        return (
            <div>
                <AppBar history={this.props.history} />

                <Paper >
                    <Tabs
                        value={this.state.tabsValue}
                        onChange={(ev, tabsValue)=>{this.setState({tabsValue})}}
                       className="meditatii-tabs"
                        textColor="primary"
                        centered
                    >
                        <Tab label={<span style={{color:this.state.tabsValue !== 0 ? "rgba(0, 0, 0, 0.54)" : "#7a80dc"}}>Contul Meu</span>}  />
                        <Tab label={<span style={{color:this.state.tabsValue !== 1 ? "rgba(0, 0, 0, 0.54)" : "#7a80dc"}}>Favorite</span>} className="meditatii-tabs" />
                        <Tab label={<span style={{color:this.state.tabsValue !== 2 ? "rgba(0, 0, 0, 0.54)" : "#7a80dc"}}>Tip Abonament</span>} className="meditatii-tabs" />
                    </Tabs>
                </Paper>
                <div className="meditatii-section-container">
                {this.state.schimbaComponenta === false && this.state.tabsValue === 0 &&
                    <UserSiEmail
                        user={this.state.user}
                        nume={this.state.nume}
                        mesaj={this.state.mesaj}
                        numeChange={(ev) => { this.setState({ nume: ev.target.value }) }}
                        email={this.state.email}
                        emailChange={(ev) => { this.setState({ email: ev.target.value }) }}
                        schimbaComponenta={this.schimbaComponenta}
                        updateUser={this.updateUser} />
                }
                {this.state.schimbaComponenta === true && this.state.tabsValue === 0 &&
                    <SchimbaParola
                        parolaveche={this.state.parolaveche}
                        changeParolaVeche={(ev) => { this.setState({ parolaveche: ev.target.value }) }}
                        parolanoua={this.state.parolanoua}
                        changeParolaNoua={(ev) => { this.setState({ parolanoua: ev.target.value }) }}
                        confirmaparola={this.state.confirmaparola}
                        changeConfirmaParola={(ev) => { this.setState({ confirmaparola: ev.target.value }) }}
                        schimbaComponenta={this.schimbaComponenta}
                        Blank={this.Blank}
                    />
                }
                {this.state.tabsValue === 1 && 
            this.listFav()
            }
            {this.state.tabsValue === 2 &&
                <div>
                <Card className='meditatii-abonament-container' style={{maxWidth:600, margin:'auto'}}>
                <h1 className='meditatii-abonament-titlu'>Abonament </h1>
               <h3 style={{color:this.state.abonament === true ? '#59c327' : '#da2b2b', fontSize:20}} >{this.state.valabilitateAb}</h3>
            
               
              
                </Card>

                <h3 className='meditatii-comenzile-mele-title' style={{display:this.state.tranzactii.length > 0 ? 'block' : 'none' }}>Comenzile Mele</h3>
                <div>

                    {this.state.tranzactii.map((t, idx)=>{
                        return(
                            <LiTranzactii key={idx} tranzactie={t} history={this.props.history}/>
                        )
                    })}
                </div>

 
             </div>
            }

            </div>
            </div>
        );
    }
}


export class LiTranzactii extends React.Component{




    render(){
        return(
            <Card style={{maxWidth:'90%', margin:'10px auto'}}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}> 
                        <h2 className='meditatii-tranzactii-title'>Comanda nr. {this.props.tranzactie.idul}</h2>
                        <p className='meditatii-tranzactii-subtitle' style={{marginBottom:0}}>Plasata pe: {arataData(this.props.tranzactie.data_tranzactie)}</p>
                        <p className='meditatii-tranzactii-subtitle'>Total : {this.props.tranzactie.total} lei</p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}> 
                       <Button variant='contained' className="meditatii-tranzactie-detalii-button" onClick={()=>{this.props.history.push(`/tranzactie/${this.props.tranzactie.idul}`)}}>
                           detalii tranzactie
                       </Button>
                    </Grid>

                </Grid>
               

            </Card>
        )
    }
}



export class UserSiEmail extends React.Component {


    render() {
        return (
         

                <Grid container className="meditatii-subsection-container" style={{ marginTop: 80 }}>
                
                    <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>
                   <h3 className="meditatii-subsection-meditezi-title " style={{marginTop:10, color:'#7a80dc', display:'block'}}>{`Ai inceput sa meditezi din ${arataData(getLocalUserData().data_creare_cont)}`}</h3>
                        <Card style={{ paddingBottom: 30,     borderRadius: 20 }}>

                            <Grid style={{ marginTop: 20, display:getLocalUserData().fbLogin === false ? 'block' : 'none' }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-user-cont">Nume Utilizator</InputLabel>
                                    <Input
                                        disableUnderline={true}
                                        disabled={true}
                                        id="meditatii-user-cont"
                                        type='text'
                                        value={this.props.user}


                                    />
                                </FormControl>

                            </Grid>

                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-nume-cont">Nume</InputLabel>
                                    <Input
                                    disabled={getLocalUserData().fbLogin}
                                        disableUnderline={true}
                                        id="meditatii-nume-cont"
                                        type='text'
                                        value={this.props.nume}
                                        onChange={this.props.numeChange}

                                    />
                                </FormControl>

                            </Grid>
                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-email-cont">E-mail</InputLabel>
                                    <Input
                                    disabled={getLocalUserData().fbLogin}
                                        disableUnderline={true}
                                        id="meditatii-email-cont"
                                        type='text'
                                        value={this.props.email}
                                        onChange={this.props.emailChange}

                                    />
                                </FormControl>

                                <span className="meditatii-schimba-parola" onClick={this.props.schimbaComponenta} style={{display:getLocalUserData().fbLogin === false ? 'block' : 'none'}}>Schimba Parola </span>
                                <span className="meditatii-success-message" >{this.props.mesaj}</span>
                            </Grid>



                            <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <Button style={{display:getLocalUserData().fbLogin === false ? 'inline-flex' : 'none'}} className="meditatii-autentificare-button" variant="contained" onClick={this.props.updateUser}>
                                    ACTUALIZARE DATE
                        </Button>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            
        )
    }
}


export class SchimbaParola extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            vParola: ''
        }
    }

    updatePassword = async () => {
        let pass = {
            old_password: this.props.parolaveche,
            new_password: this.props.parolanoua
        }

        let schimbaPass = await fromServer('insert_account',{id:getLocalUserData().idul,
                                                            mode:'edit_password'}, {credentials:'include', method:'POST'}, false, 'POST', JSON.stringify(pass), 'postData' )

       

        if (schimbaPass.r === 'ok') {
            this.setState({ vParola: 'Parola a fost schimbata cu success' }, () => {
                this.props.Blank()
            })
        } else {
            this.setState({ vParola: schimbaPass.mesaj })
        }
    }

    validareParola = () => {
        if (this.props.parolanoua !== this.props.confirmaparola) {
            this.setState({ vParola: 'Noua parola trebuie sa fie la fel cu confirmarea' })
        }
        else if (this.props.parolanoua.length < 3) {
            this.setState({ vParola: 'Parola trebuie sa aiba minim 3 caractere' })
        }
        else {
            this.setState({ vParola: '' }, () => {
                this.updatePassword()
            })

        }
    }

    render() {
        return (
           
                <Grid container className="meditatii-subsection-container" style={{ marginTop: 80 }}>
                    <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>

                        <Card style={{ paddingBottom: 30, borderRadius: 20  }}>

                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>


                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-parola1">Parola Veche</InputLabel>
                                    <Input
                                        disableUnderline={true}
                                        id="meditatii-parola1"
                                        type={this.state.showPassword1 ? 'text' : 'password'}
                                        value={this.props.parolaveche}
                                        onChange={this.props.changeParolaVeche}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    style={{ color: "#bfbfbf", border: 'none' }}

                                                    onClick={() => { this.setState({ showPassword1: this.state.showPassword1 === true ? false : true }) }}
                                                >
                                                    <i className="material-icons">{this.state.showPassword1 ? "visibility" : "visibility_off"}</i>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>


                            </Grid>
                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>


                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-parola2">Parola Noua</InputLabel>
                                    <Input
                                        disableUnderline={true}
                                        id="meditatii-parola2"
                                        type={this.state.showPassword2 ? 'text' : 'password'}
                                        value={this.props.parolanoua}
                                        onChange={this.props.changeParolaNoua}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    style={{ color: "#bfbfbf", border: 'none' }}

                                                    onClick={() => { this.setState({ showPassword2: this.state.showPassword2 === true ? false : true }) }}
                                                >
                                                    <i className="material-icons">{this.state.showPassword2 ? "visibility" : "visibility_off"}</i>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>


                            </Grid>
                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>


                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-parola3">Confirma Parola</InputLabel>
                                    <Input
                                        disableUnderline={true}
                                        id="meditatii-parola3"
                                        type={this.state.showPassword3 ? 'text' : 'password'}
                                        value={this.props.confirmaparola}
                                        onChange={this.props.changeConfirmaParola}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    style={{ color: "#bfbfbf", border: 'none' }}

                                                    onClick={() => { this.setState({ showPassword3: this.state.showPassword3 === true ? false : true }) }}
                                                >
                                                    <i className="material-icons">{this.state.showPassword3 ? "visibility" : "visibility_off"}</i>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <span className="meditatii-success-message" style={{coor:'red'}}>{this.state.vParola}</span>
                                <span className="meditatii-schimba-parola" onClick={this.props.schimbaComponenta}>Editeaza Date Personale</span>


                            </Grid>


                            <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <Button className="meditatii-autentificare-button" variant="contained" onClick={this.validareParola}>
                                    SCHIMBA PAROLA
                    </Button>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>

        )
    }
}

