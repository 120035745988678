import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import {Grid, Button} from '@material-ui/core'
import { fromServer } from '../../Providers/ws';
import { getLocalUserData } from '../../Providers/ls';


export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            nume:'',
            email:'',
            mesaj:'',
            disabled:true,
            vNume:'',
            vMail:'',
            vTrimiteContact:''
         }
    }

    
componentDidMount(){
    this.enableButton()
    this.scrollTop()
}

scrollTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

    verifyNume=()=>{
        if(this.state.nume.length < 3 ){
            this.setState({vNume:"Numele este obligatoriu!"});
            
        }else{
            this.setState({vNume:""});
            
        }
    this.enableButton()

    }

    verifyEmail=()=>{
        if(this.state.email.indexOf("@") !== -1 ){
            this.setState({vMail:""});
            
        }else{
            this.setState({vMail:"Emailul nu este corect! "});
            
        }

        this.enableButton()
    }


    enableButton=()=>{
        if(this.state.nume.length > 3 && this.state.email.indexOf("@") !== -1){
            this.setState({disabled:false})
        }else{
            this.setState({disabled:true})
        }
    }

    sendContact =async() =>{
        this.setState({vTrimiteContact:'Va rugam asteptati.....'})
        let sendContact = await fromServer('contact',{name:this.state.nume, email:this.state.email, message:this.state.mesaj, id_user:getLocalUserData().idul}, {credentials:'include'})
          
            
        if(sendContact.r === 'ok'){
            this.setState({vTrimiteContact:sendContact.mesaj})
        }
    }
    

    goToFacebookPage=()=>{
        window.open('https://www.facebook.com/CasaMeditatiilor/', '_blank');
    }
    render() { 
        return ( 
            <div>
                 <AppBar history={this.props.history} />
                <div className="meditatii-paralax-contact">
                    <div className="meditatii-paralax-img-contact"></div>
                    <div className="meditatii-paralax-content">
                   

                    </div>
                </div>
                <div className="meditatii-section-container">
                <Grid container className="meditatii-subsection-container" style={{maxWidth:1200}}>
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={4} lg={4} xl={4} >
                        <p className="meditatii-section-paragraph" style={{marginBottom:15}}>Informa&#355;ii de contact</p>
                        <p className="meditatii-despre-noi-paragraph" style={{ marginBottom: 20 }}>Ai o &#238;ntrebare? Contacteaz&#259;-ne, vrem s&#259; te cunoa&#351;tem.</p>
                        <p className="meditatii-despre-noi-paragraph" style={{ marginBottom: 20 }}>Email: contact@casameditatiilor.ro</p>
                        <p className="meditatii-despre-noi-paragraph" style={{ marginBottom: 20 }}>Pentru &#238;ntreb&#259;ri generale ne pute&#355;i contacta &#351;i pe Facebook.</p>
                        <h1 className="meditatii-fb-link" onClick={this.goToFacebookPage}><div className="meditatii-fb-logo"></div>/CasaMeditatiilor</h1>
                        </Grid>
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <p className="meditatii-section-paragraph" style={{marginBottom:15}}>Colabor&#259;ri</p>
                        <p className="meditatii-despre-noi-paragraph" style={{marginBottom:15}}>Vrei s&#259; colaborezi cu noi pe proiecte legate de dezvoltare spiritual&#259;, vrei s&#259; contribui la blogul nostru cu post&#259;ri, articole, medita&#355;ii sau pur &#351;i simplu vrei s&#259; &#238;mp&#259;rt&#259;&#351;e&#351;ti din experien&#355;a ta? Te rug&#259;m s&#259; completezi formularul de contact.</p>
                        </Grid>
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <label className="meditatii-login-label" htmlFor="utilizator">Nume</label>
                                <input  type="text" id="utilizator" className="meditatii-utilizator" value={this.state.nume} onChange={(ev)=>{this.setState({nume:ev.target.value},()=>{this.verifyNume()})}} />
                                <span className="meditatii-verify-span">{this.state.vNume}</span>

                            </Grid>
                            <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <label className="meditatii-login-label" htmlFor="email">Email</label>
                                <input type="email" id="email" className="meditatii-utilizator" value={this.state.email} onChange={(ev)=>{this.setState({email:ev.target.value},()=>{this.verifyEmail()})}} />
                                <span className="meditatii-verify-span">{this.state.vMail}</span>
                            </Grid>
                            <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <label className="meditatii-login-label" htmlFor="mesaj">Mesaj</label>
                                <textarea type="" id="mesaj" value={this.state.mesaj}  onChange={(ev)=>{this.setState({mesaj:ev.target.value})}}className="meditatii-utilizator" style={{height:130}}></textarea>
                            </Grid>
                            <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <span className="meditatii-success-message" >{this.state.vTrimiteContact}</span>
                                <Button className={this.state.disabled === true ? "" : "meditatii-autentificare-button"} variant="contained" onClick={this.sendContact} disabled={this.state.disabled} >
                                   Trimite 
                                 </Button>
                            </Grid>
                        
                        </Grid>
                </Grid>
                </div>
            </div>
         );
    }
}
 
 ;