import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import {   List, FormControl, InputLabel, InputAdornment, Input, IconButton } from '@material-ui/core';
import {  fromServer } from '../../Providers/ws';
import LiClienti from './LiClienti'
import { getLocalUserData } from '../../Providers/ls';

export default class Clienti extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            orderby:'numeasc',
            nrEl:100,
            nrPagina:1,
            clienti:[],
            cauta:''
         }
    }

    componentDidMount(){

       this.verifyAdmin()
        window.addEventListener('scroll', this.onScroll, false)
        this.scrollTop()
    }

    verifyAdmin = async()=>{
        
        let server = await fromServer('users',{mode:'check_if_admin', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune}, {credentials:'include'})

        if(server.r === 'ok'){

            this.getClienti(this.state.orderby, this.state.nrEl, this.state.nrPagina)
            if(this.state.techDebug === 1){
            
          
            }
        }else{
            this.props.history.push('/')
        }
    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    getClienti=async(orderBy, nrEl, nrPagina)=>{

        let clienti = await fromServer('users',{mode:'list_users',
                                                current_page:nrPagina,
                                                    nr_elem_page:nrEl,
                                                order_by:orderBy}, {credentials:'include'})

    

        if(clienti.r === 'ok'){
            let cloneClienti = [...this.state.clienti]
            
            let counter = clienti.continut.length

            for(let i=0; i<counter; i++){
                cloneClienti.push(clienti.continut[i])
          
            }
           

            this.setState({clienti:cloneClienti})
        
        }
     
    }

    onScroll =()=>{
        if((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 800) && this.state.clienti.length ){
           this.getClienti(this.state.orderby, this.state.nrEl, this.state.nrPagina + 1)
            this.setState({nrPagina:this.state.nrPagina + 1})
        } 
    }

    changeSearch=(ev)=>{
        this.setState({cauta:ev.target.value})
    }

    search = async()=>{
        this.setState({orderby:'numeasc',
        nrEl:100,
        nrPagina:1},async()=>{
            if(this.state.cauta === ''){
                this.getClienti(this.state.orderby, this.state.nrEl, this.state.nrPagina)
            }else{
                let clienti = await fromServer('users',{mode:'search',
                searched_word:this.state.cauta}, {credentials:'include'})

                if(clienti.r === 'ok'){
                    this.setState({clienti:clienti.continut})
                }
            }
        })
    }


    render() { 
        return ( 
            <div>
                  <AppBar history={this.props.history}/>
                  <div className="meditatii-back-button" onClick={()=>{this.props.history.push('/administrator')}}><i className="material-icons meditatii-back-button-icon">reply</i></div>
                  <h1 className="section-title">Clienti</h1>
                  <div>
                  <FormControl className="meditatii-text-field"  >
                                <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-cauta">Cauta</InputLabel>
                                <Input
                                    disableUnderline={true}
                                    id="meditatii-cauta"
                                    type='text'
                                   
                                    onBlur={this.changeSearch}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                style={{ color: "#bfbfbf", border: 'none' }}

                                                onClick={this.search}
                                            >
                                                <i className="material-icons" >search</i>
                                            </IconButton>
                                        </InputAdornment>
                                    }

                                />
                            </FormControl>

                  </div>
                  
                 < List style={{marginBottom:100, marginTop:100, width:'80w'}}>
                      {
                          this.state.clienti.map((c,idx)=>{
                              return(
                               <LiClienti key={idx} index={idx} client={c} history={this.props.history}/>
                             
                              )
                          })
                      }
                   
                  </List>
            </div>
         );
    }
}