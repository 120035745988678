import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import { fromServer } from '../../Providers/ws';
import { Grid, Switch, FormControlLabel } from '@material-ui/core';
import { arataData } from '../../Providers/utile';
import { getLocalUserData } from '../../Providers/ls';


export default class InfoClient extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            client:{
                tip_content:'',
               
                
            },
            checkClient: false
         }
    }

    componentDidMount(){
        this.verifyAdmin()
        this.scrollTop()
    }
    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    verifyAdmin = async()=>{
        
        let server = await fromServer('users',{mode:'check_if_admin', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune}, {credentials:'include'})

        if(server.r === 'ok'){

            this.getClient()
            if(this.state.techDebug === 1){
    
            }
        }else{
            this.props.history.push('/')
        }
    }

    getClient = async()=>{
    

        let client = await fromServer('users',{mode:'get_user',
                                                id_user:this.props.match.params.idclient}, {credentials:'include'})



        if(client.r === 'ok'){
            this.setState({client:client.continut[0],
                            checkClient:client.continut[0].blocat !== '1' ? true : false})
        }
    }

    blocheaza=async()=>{

        if(this.state.checkClient === true  ){

            let blocheaza = await fromServer('users',{mode:"block_user",
                                                        id_user:this.state.client.idul},{credentials:'include'})

         

    
            if(blocheaza.r === 'ok'){
                this.setState({checkClient:false})
            }
        }else{

            let deblocheaza = await fromServer('users',{mode:"unblock_user",
                                                        id_user:this.state.client.idul},{credentials:'include'})
     

    
            if(deblocheaza.r === 'ok'){
                this.setState({checkClient:true})
            }
        }
       
    }

    render() { 
        return (
            <div>
                <AppBar history={this.props.history}/>
                <div className="meditatii-back-button" onClick={()=>{this.props.history.push('/adminclienti')}}><i className="material-icons meditatii-back-button-icon">reply</i></div>
                  <h1 className="section-title" style={{marginBottom:10}}>{this.state.client.nume}</h1>
                  <h2 className="meditatii-admin-subtitle">({this.state.client.email})</h2>
                 
                                <FormControlLabel style={{ margin: 'auto' }}
                                    control={
                                        <Switch
                                            className='meditatii-my-switch'
                                            checked={this.state.checkClient}
                                            onChange={this.blocheaza}
                                            value="checkClient"
                                            color='default'
                                            checkedIcon={<i className='material-icons' style={{ color: '#06cc06', fontSize: 31 }}>fiber_manual_record</i>}
                                        />
                                    }
                                    label="Activ"
                                />
               

                  <Grid className="meditatii-subsection-container" style={{marginTop:50, marginBottom:50}} container>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <p className="meditatii-admin-info-text" style={{color:'black'}}>Data Creare Cont: <span className="meditatii-admin-info-text">{arataData(this.state.client.data_creare_cont)}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <p className="meditatii-admin-info-text" style={{color:'black'}}>Data Expirare Cont: <span className="meditatii-admin-info-text">{arataData(this.state.client.data_expirare)}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <p className="meditatii-admin-info-text" style={{color:'black'}}>Este Admin: <span className="meditatii-admin-info-text">{this.state.client.admin === '1' ? "Da" : "Nu"}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <p className="meditatii-admin-info-text" style={{color:'black'}}>Tip Abonament: <span className="meditatii-admin-info-text">{this.state.client.tip_content !== null ? this.state.client.tip_content.toUpperCase() : ''}</span></p>
                    </Grid>
                  </Grid>
                 
            </div>
          );
    }
}
 
 ;


