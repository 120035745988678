import React from 'react';

import { Grid, Button } from '@material-ui/core';
import { fromServer } from '../../Providers/ws';
import { arataData } from '../../Providers/utile';
import { setBlogAddr, getLocalUserData } from '../../Providers/ls';
import InstagramEmbed from 'react-instagram-embed';

// import history from '../../History'





export default class FooterContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            intervalId: 0,
            mail: '',
            posts: [],
            mesaj:'',
            mesajErr:''
        }
    }

    componentDidMount() {
        this.getBlogPosts()

        this.scrollTop()

    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }


    getYear=()=>{
        let date = new Date()

        let year = date.getFullYear()

        return year
    }

   

    goTo = (go) => {
        document.location.href = `#/${go}`;

    }

    changeIframeAddress=(c)=>{
        // setBlogAddr(c.link)
        // this.goTo('blog')
        // document.location.reload()

        window.open(c.link, '_blank')
    }


    sendToServer = async () => {
        let sendMail = await fromServer('newsletter', { mode: 'insert_email', email: this.state.mail, id_user:getLocalUserData().idul }, { credentials: 'include' })
        
        if(sendMail.r === 'ok'){
            this.setState({mesaj:sendMail.mesaj,
                            mesajErr:''})
        }else{
            this.setState({mesajErr:sendMail.mesaj,
                            mesaj:''})
        }
    }





    getBlogPosts = async () => {
        let blogPosts = await fromServer('blog', { mode: 'get_last_posts' }, { credentials: 'include' })

       
        if (blogPosts.r === 'ok') {
            this.setState({ posts: blogPosts.continut })
        }
    }


    goToNewPage = (page) =>{

        window.open(page)
    }
          // 
    render() {
        return (<div className="meditatii-footer-container">
            <Grid container  >
                <Grid style={{ overflow: "hidden" }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <h3 className="meditatii-footer-title">Hai &#351;i pe FACEBOOK</h3>
                    <div className="fb-page meditatii-facebook-section" data-href="https://www.facebook.com/CasaMeditatiilor"
                       
                        data-small-header={false}
                        data-adapt-container-width={true}
                        data-hide-cover={false}
                        data-show-facepile={true}><blockquote cite="https://www.facebook.com/CasaMeditatiilor"
                            className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/CasaMeditatiilor">Casa Meditatiilor</a></blockquote>
                </div>
                    <div style={{height:318}}>
                    <InstagramEmbed
                                            url='https://www.instagram.com/p/BuJeFVKHV_m/'
                                            maxWidth={270}
                                            style={{minWidth:250}}
                                            hideCaption={false}
                                            className='meditatii-instagram-embed'
                                            containerTagName='div'
                                            protocol=''
                                            injectScript
                                            onLoading={() => {}}
                                            onSuccess={() => {}}
                                            onAfterRender={() => {}}
                                            onFailure={() => {}}
                                            />  

                    </div>
                            

                </Grid>
              
               
                <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <h3 className="meditatii-footer-title">Alatur&#259;-te Casei Medita&#355;iilor</h3>
                    <input className="meditatii-footer-input" type="email" placeholder='adresa de email' value={this.state.mail} onChange={(ev) => { this.setState({ mail: ev.target.value }) }} />
                    <span className="meditatii-success-message" >{this.state.mesaj}</span>
                    <span className="meditatii-success-message" style={{color:'red'}}>{this.state.mesajErr}</span>
                    <Button className="meditatii-footer-button" variant="contained" onClick={this.sendToServer}>
                        Trimite
                 </Button>
                </Grid>

                <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <h3 className="meditatii-footer-title">Informa&#355;ie util&#259;</h3>
                    <ul className="meditatii-footer-list">
                        <li onClick={() => { this.goTo("desprenoi") }}>Despre noi</li>
                        <li onClick={() => { this.goTo('contact') }}>Contact</li>
                        <li onClick={() => { this.goTo('confidentialitate') }}>Politica de Confiden&#355;ialitate</li>
                        <li onClick={() => { this.goTo('termenisiconditii') }}>Termeni &#351;i condi&#355;ii</li>
                        <li onClick={() => { this.goTo('politicacookie') }}>Politica Cookie</li>
                        <li onClick={() => { this.goTo('abonamente') }}>Abonamente</li>
                        <li onClick={() => { this.goToNewPage('https://www.patreon.com/casameditatiilor') }}> Sustine-ne prin Patreon</li>
                    </ul>
                </Grid>

                <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <h3 className="meditatii-footer-title">Articole</h3>

                    <div className="meditatii-footer-articole">

                        {this.state.posts.map((p, idx) => {
                            return (
                                <div key={idx} className="meditatii-footer-articol-container" onClick={() => { this.changeIframeAddress(p) }}>

                                    <div className="meditatii-footer-articol">

                                        <p className="meditatii-footer-articol-paragraph">{p.post_title}</p>
                                        <h6><i className="material-icons meditatii-footer-icon-size" >schedule</i><span className="meditatii-articol-date"> {arataData(p.post_date)}</span></h6>
                                        <div className="meditatii-footer-line-effect">

                                        </div>

                                    </div>
                                    <div className="meditatii-footer-articol-img" style={{ backgroundImage: `url(${p.image_link})` }}></div>
                                </div>
                            )
                        })}

                       
                    </div>

                </Grid>
             
            </Grid>

            <div className="meditatii-copyright">
                <i className="material-icons" onClick={() => { this.scrollTop() }} style={{ fontSize: 50, cursor: "pointer", color:'#7a80dc' }}>keyboard_arrow_up</i>
                <h6 className="meditatii-copyright-text">&#169; {this.getYear()} Casa Meditatiilor | <a className="meditatii-footer-copyright-links" href="http://www.anpc.gov.ro/" target="_blank" rel='noopener noreferrer'>ANPC</a> | <a className="meditatii-footer-copyright-links" href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" rel='noopener noreferrer' target="_blank">SOL</a></h6>
            </div>

        </div>);
    }
}
