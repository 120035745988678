import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import { ListItem, ListItemText, List } from '@material-ui/core';
import { getLocalUserData } from '../../Providers/ls';
import {fromServer} from '../../Providers/ws'


export default class Administrator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    componentDidMount(){
        this.scrollTop()
        this.verifyAdmin()
    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }


    verifyAdmin = async()=>{
        
        let server = await fromServer('users',{mode:'check_if_admin', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune}, {credentials:'include'})


       

        if(server.r === 'ok'){
            if(this.state.techDebug === 1){
            
           
            }
        }else{
            //this.props.history.push('/')
        }
    }
    render() { 
        return ( 
            <div>
                  <AppBar history={this.props.history}/>
                  <h1 className="section-title">ADMINISTRATOR</h1>
                  <List style={{marginBottom:100, marginTop:100}}>
                      <ListItem style={{maxWidth:960, margin:"auto"}} onClick={()=>{this.props.history.push('/adminclienti')}} button>
                          <ListItemText primary={<h2 className="meditatii-admin-list-text">Clienti</h2>}/>
                      </ListItem>
                      <ListItem style={{maxWidth:960, margin:"auto"}} onClick={()=>{this.props.history.push('/citatezilnice')}} button>
                          <ListItemText primary={<h2 className="meditatii-admin-list-text">Citate Zilnice</h2>}/>
                      </ListItem>

                      <ListItem style={{maxWidth:960, margin:"auto"}} onClick={()=>{this.props.history.push('/admincontent')}} button>
                          <ListItemText primary={<h2 className="meditatii-admin-list-text">Continut</h2>}/>
                      </ListItem>

                      <ListItem style={{maxWidth:960, margin:"auto"}} onClick={()=>{this.props.history.push('/autori')}} button>
                          <ListItemText primary={<h2 className="meditatii-admin-list-text">Autori</h2>}/>
                      </ListItem>

                      <ListItem style={{maxWidth:960, margin:"auto"}} onClick={()=>{this.props.history.push('/admincategorii')}}  button>
                          <ListItemText primary={<h2 className="meditatii-admin-list-text">Categorii</h2>}/>
                      </ListItem>

                      <ListItem style={{maxWidth:960, margin:"auto"}} onClick={()=>{this.props.history.push('/adminprograme')}} button>
                          <ListItemText primary={<h2 className="meditatii-admin-list-text">Programe</h2>}/>
                      </ListItem>

                      <ListItem style={{maxWidth:960, margin:"auto"}} onClick={()=>{this.props.history.push('/ratings')}} button>
                          <ListItemText primary={<h2 className="meditatii-admin-list-text">Note / Ratings</h2>}/>
                      </ListItem>

                  </List>
            </div>
         );
    }
}
 
 ;