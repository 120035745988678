import UtilsDecrypt from "./UtilsDecrypt";

export const setLocalUserData = (u)=>{
    if(u){
        let userData={
            existaDate:true,
            fbLogin:u.fbLogin,
            esteLogat:u.esteLogat,
            nume:u.nume,
            idul:u.idul,
            email:u.email,
            nume_utilizator:u.nume_utilizator,
            admin:u.admin,
            blocat:u.blocat,
            data_creare_cont:u.data_creare_cont,
            data_modificare_cont:u.data_modificare_cont,
            label_tip_cont:u.label_tip_cont,
            cos:u.cos,
            id_sesiune:u.id_sesiune
            
        }


  
        localStorage.setItem('userData', JSON.stringify(userData))
    }
    else{
        localStorage.setItem('userData', JSON.stringify({ existaDate: false }) )
    }
    
    return true
    
}



export const getLocalUserData = () => {


    
    if( localStorage.getItem('userData') ){

        console.log('localStorage.getItem :', localStorage.getItem('userData'));
        
        let ud =localStorage.getItem('userData')

      
        return JSON.parse(ud)
    }
    else{
        return {existaDate:false}
    }
}






export const setBlogAddr = (ab) => {
    if(ab){
        let abonament= ab
        localStorage.setItem("blog", JSON.stringify(abonament))

    }else{
        localStorage.setItem('blog',null)
    }
    return true
}

export const getBlogAddr = () =>{
    if(localStorage.getItem("blog")){
        let ab = localStorage.getItem('blog')
        return JSON.parse(ab)
    }
}


