import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import LiCategorii from './LiCategorii'

import { List, Grid, Button, FormControl, Input, InputLabel, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { fromServer } from '../../Providers/ws'
import { getLocalUserData } from '../../Providers/ls';


export default class Categorii extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            cauta: '',
            openAdd:false,
            disableInsert:true,
            nume_categorie:'',
            update:false,
            infoCat:{}
        }
    }

    componentDidMount() {
        this.verifyAdmin()
        this.scrollTop()
    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    verifyAdmin = async()=>{
        
        let server = await fromServer('users',{mode:'check_if_admin', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune}, {credentials:'include'})

        if(server.r === 'ok'){

            this.getCategories()
            if(this.state.techDebug === 1){
            
            }
        }else{
            this.props.history.push('/')
        }
    }

    closeAdd=()=>{
        this.setState({openAdd:false})
    }

    changeStatus=(cat, nr)=>{
        let cloneCat = [...this.state.categories]
        let idx = cloneCat.findIndex(c=>c.idul === cat.idul)
       

        cloneCat[idx].sters = nr

        this.setState({categories:cloneCat})
    }

    getInfo=(c)=>{
     this.props.history.push(`/categorie/${c.idul}`)
    }

    search = () => {
        let content = []

        //content = this.state.categories.filter(c => (c.nume_categorie.toLowerCase().includes(this.state.cauta.toLowerCase())))
        content = this.state.categories.filter((c) => {return c.nume_categorie.toLowerCase().indexOf(this.state.cauta.toLowerCase()) !== -1 })
        
        return (

            content.map((c, idx) => {
                return (
                    <LiCategorii key={idx} index={idx} content={c} history={this.props.history} changeStatus={this.changeStatus} getInfo={this.getInfo}/>

                )
            })

        )

    }

    getCategories = async () => {

        let categories = await fromServer('category', {mode:'list_categories_admin'}, {credentials:'include'})

        
        if (categories.r === 'ok') {
            this.setState({ categories: categories.continut }, () => {

            })
        }
    
    }

    insertCategory =async ()=>{

        
        let insertCategory = await fromServer('category', {category_name:this.state.nume_categorie, mode:'insert_category'}, {credentials:'include'})
    

        if(insertCategory.r==='ok'){
           
            this.props.history.push(`/categorie/${insertCategory.id_introdus}`)
       
           
        }
    }

    render() {
        return (
            <div>
                <AppBar history={this.props.history} />

                <Dialog

                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.openAdd}
                    onClose={this.closeAdd}
                >

                    <DialogActions style={{ justifyContent: 'center' }}>

                        <Button className='meditatii-close-dialog-button' onClick={this.closeAdd} variant="fab">
                            <i className='material-icons'>clear</i>
                        </Button>

                    </DialogActions>


                    <h2 style={{ padding: '20px 10px 0 10px', marginTop: 20, textAlign: 'center' }} className="meditatii-section-meditezi-title">
                       {this.state.update === true ? 'Editeaza Categorie' : "Adauga Categorie"}
                    </h2>

                    <DialogContent style={{ textAlign: 'center' }}>

                        <FormControl className="meditatii-text-field" style={{ width: "100%" }}  >
                            <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-nume-categorie">Nume Categorie</InputLabel>
                            <Input
                                disableUnderline={true}
                                id="meditatii-nume-categorie"
                                type='text'
                                value={this.state.nume_categorie}
                                onChange={(ev) => { this.setState({ nume_categorie: ev.target.value },()=>{
                                                    if(this.state.nume_categorie.length > 2){
                                                        this.setState({disableInsert:false})
                                                    }else{
                                                        this.setState({disableInsert:true})
                                                    }
                                }) }}


                            />
                        </FormControl>



                        <Button disabled={this.state.disableInsert} style={{ marginTop: 20 }} variant='contained' color="primary" className={this.state.disableInsert === true ? "" :'meditatii-autentificare-button' } onClick={this.state.update === true ? this.editCategory : this.insertCategory} >
                            {this.state.update === false ? "Adauga" : 'Editeaza'}
                         </Button>

                    </DialogContent>
                </Dialog>
                <div className="meditatii-back-button" onClick={() => { this.props.history.push('/administrator') }}><i className="material-icons meditatii-back-button-icon">reply</i></div>
                <h1 className="section-title" style={{ marginBottom: 10 }} >Categorii</h1>
                <Grid container className="meditatii-subsection-container" style={{ marginTop: 10 }}>
                    <Grid style={{ margin: "auto" }} item xs={12} sm={12} md={6} lg={6} xl={6}>

                        <Button style={{ color: 'darkorange' }} color="primary" className='meditatii-autentificare-button' onClick={() => { this.setState({ openAdd: true, update:false }) }} >
                            Adauga Categorie
                                </Button>

                        <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl className="meditatii-text-field"  >
                                <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-cauta">Cauta</InputLabel>
                                <Input
                                    disableUnderline={true}
                                    id="meditatii-cauta"
                                    type='text'
                                    value={this.state.cauta}
                                    onChange={(ev) => { this.setState({ cauta: ev.target.value }) }}


                                />
                            </FormControl>

                        </Grid>

                    </Grid>
                </Grid>
                <List style={{ marginBottom: 100, }}>
                    {
                        this.search()
                    }

                </List>

            </div>
        );
    }
}

;