import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import meditatiiprogramemin from '../Pics/meditatiiprogramemin.png'
import {  fromServer } from '../../Providers/ws';
import { getLocalUserData } from '../../Providers/ls';


export default class ProgramListare extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            program:{},
            content:[],
            back:false
            
         }
    }

    componentDidMount(){
       


        this.program()
        this.scrollTop()
    }

    componentWillUnmount(){
        if(this.state.back === false){
            window.location.href = '#/meditatii'
        }else{

        }
       
    }



    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    displayLock=(c)=>{
        let tipUser = getLocalUserData().label_tip_cont
     if(getLocalUserData().esteLogat === true){
        if(tipUser !== 'free'){
 
          return 'none'
        }else{
           
               
                let users = c.is_free_users !== '1' ? 'inline-block' : 'none'
          return users
            }
        }else{
           
            return c.is_free_everybody !== '1' ? 'inline-block' : 'none'
        }
    }


    goToMeditatie = (c)=>{
       
        let tipUser = getLocalUserData().label_tip_cont
        if(getLocalUserData().esteLogat === true){
           if(tipUser !== 'free'){
               this.setState({back:true},()=>{

                   this.props.history.push(`/meditatie/${c.id_content}`)
               })
           }else{
              
                  
                   this.setState({back:true},()=>{
                  
                    let users = c.is_free_users !== '1' ? this.props.history.push('/abonamente') : this.props.history.push(`/meditatie/${c.id_content}`)
             
                    return users
                   })
               }
           }else{
               

               this.setState({back:true},()=>{
               return c.is_free_everybody !== '1' ?  this.props.history.push('/inregistreazate/0')  : this.props.history.push(`/meditatie/${c.id_content}`)
               })
           
            }
       }

       

   

    program = async()=>{

        let myProgram = await fromServer('programs',{mode:'program_item',
                                                    id_user:getLocalUserData().idul,
                                                        id_sesiune:getLocalUserData().id_sesiune,
                                                    id_program:this.props.match.params.idprogram},{credentials:'include'})

     


        if(myProgram.r==='ok'){
            this.setState({program:myProgram.continut[0],
                            content:myProgram.programs_info})
        }
       
    }
    render() { 
        return ( 
            <div>
                <div className="meditatii-back-button" onClick={() => { this.props.history.push('/meditatii') }}><i className="material-icons meditatii-back-button-icon">reply</i></div>
                  <AppBar history={this.props.history} />
                  <div style={{backgroundImage:`url(${meditatiiprogramemin})`, backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundSize:'cover', padding:'20px 0 20px 0'}}>
                       <div className="meditatii-section-container" style={{backgroundColor:'transparent'}} >
                       <h1 className="meditatii-paralax-content-title " style={{ marginTop: 40, textAlign:'left', fontWeight:700 }}>{this.state.program.nume_program}</h1>
                    <p className='meditatii-section-paragraph' style={{textAlign:'left', color:'white', marginTop:20, marginLeft:0, maxWidth:700, fontWeight:500, fontSize:23, marginBottom:100}}>{this.state.program.descriere}</p>
                    <div  style={{margin:'auto', maxWidth:1100, display:'flex', width:'100%', flexWrap:'wrap', justifyContent:'center', marginBottom:80 }}>
                    {this.state.content.map((c, idx)=>{
                        return(
                            <div className='meditatii-program-list-item' style={{order:'1'}} key={idx} onClick={()=>{this.goToMeditatie(c)}}>
                              <div style={{backgroundImage:`url(${meditatiiprogramemin})`, backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundSize:'cover', borderRadius:'50%', width:'60px', margin:'auto', position:'relative'}}>
                                    <i className='material-icons' style={{fontSize:56, color:'white'}}>play_arrow</i> 
                                    <i className="material-icons meditatii-locked-items" style={{display: this.displayLock(c), top: -5, right:-3, fontSize:25}}>lock</i>
                              </div>
                               <span className='meditatii-program-list-zile'>{this.state.program.nume_program.toLowerCase().indexOf('zile') !== -1 ? ('Ziua ' + [idx + 1]) : ''}</span>
                               <span className='meditatii-program-list-titlu'>{c.nume_content}</span>
                               <span className='meditatii-program-list-time'>{c.durata}</span>  
                            </div>
                        )
                    })}

                    </div>
                  
                </div>
                </div>
            </div>
         );
    }
}
 
 ;