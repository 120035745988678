import React from 'react';
import { getLocalUserData, setLocalUserData } from '../../Providers/ls';
import AppBar from '../../Componente/Comune/AppBar'
import {Button, Checkbox, Card} from '@material-ui/core'
import { fromServer } from '../../Providers/ws';

export default class Abonament extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            checkedTermeni:false,
            abonamente:[],
            total:0,
            cos:0
         }
        }
             componentDidMount(){
                 this.scrollTop()
           
                 this.getContinutCos()
             }
    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    achitaComanda=async()=>{
        let achita = await fromServer('cart', {mode:'send_cart', id_user:getLocalUserData().idul}, {credentials:'include'})

        if(achita.r === 'ok'){
            let myData = getLocalUserData()
            myData.label_tip_cont = 'paid'
            setLocalUserData(myData)

            this.props.history.push('/meditatii')
        }
    }

   

     getContinutCos=async()=>{
        let cos = await fromServer('cart', {mode:'get_cart', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune},{credentials:'include'})
      

        if(cos.r==='ok'){

            let counter= cos.continut.length
            let total = 0;

            for(let i = 0; i< counter; i++){
                total += (Number(cos.continut[i].item_price) * Number(cos.continut[i].item_quantity))
            }

          

            this.setState({abonamente:cos.continut,
                          total:total,
                          cos:cos.continut.length
                           },()=>{
                            let user =  getLocalUserData()
       
                            user.cos = cos.continut.length
                            setLocalUserData(user)
                          })
        }else{
            let user =  getLocalUserData()
       
        user.cos = 0
        setLocalUserData(user)
        }
     }

    getTotal=async(c,a)=>{
       
        let updateCantitate = await fromServer('cart', {mode:'update_item_quantity', 
                                                id_cart_item:a.id_cart_item,
                                            item_quantity:c},{credentials:'include'})
         if(updateCantitate.r === 'ok'){

                let cloneAb = [...this.state.abonamente]
        
                let idx = cloneAb.findIndex(ab=> ab.id_cart_item === a.id_cart_item)
        
                cloneAb[idx].item_quantity = c
        
                let counter= cloneAb.length
                let total = 0;
        
                for(let i = 0; i< counter; i++){
                    total += (Number(cloneAb[i].item_price) * Number(cloneAb[i].item_quantity))
                }
        
        
        
                this.setState({abonamente:cloneAb,
                                total:total},()=>{
                                })
            }

    }

    deleteAbonament= (a)=>{
        let cloneAb = [...this.state.abonamente]
        let idx = cloneAb.findIndex(ab=> ab.id_cart_item === a.id_cart_item)
        cloneAb.splice(idx, 1)

        let counter= cloneAb.length
        let total = 0;

        for(let i = 0; i< counter; i++){
            total += (Number(cloneAb[i].item_price) * Number(cloneAb[i].item_quantity))
        }


        this.setState({abonamente:cloneAb,
                        total:total,
                        cos:cloneAb.length},()=>{
                            let user =  getLocalUserData()
       
                          
                            user.cos = cloneAb.length
                            setLocalUserData(user)
                            
                        })
        
    }

    goToPlata =async()=>{


        let goToPlata = await fromServer('transactions', {mode:'insert_transaction',
                                                            id_user:getLocalUserData().idul,
                                                            id_sesiune:getLocalUserData().id_sesiune}, {credentials:'include'})

        if(goToPlata.r === 'ok'){
            this.props.history.push(`/achitacomanda/${goToPlata.id_introdus}`)
        }

    }
    


    
    render() { 
        return (
            <div>
                 <AppBar history={this.props.history} cos={this.state.cos}/>
                 <div className="meditatii-section-container" style={{overflow:'scroll'}} >
                 <h1 className="meditatii-section-meditezi-title" style={{ marginTop: 50 }}>Comanda Ta</h1>
                <table className="meditatii-tabela-pret">
                    <thead>
                        <tr>
                          
                            <td></td>
                            <td className="meditatii-abonament-pret">Pre&#355;</td>
                        </tr>
                    </thead>
                    <tbody className="meditatii-tabela-body">
                        {this.state.abonamente.length < 1 &&
                            <tr>
                                <td colSpan={3}>Nu aveti nici un produs in cosul de cumparaturi</td>
                            </tr>
                        }
                      {this.state.abonamente.map((a, idx)=>{
                          return(
                              <LiAbonament key={idx} abonament={a} total={this.getTotal} deleteAbonament={this.deleteAbonament}/>
                          )
                      })}  
                    </tbody>
                    <tfoot>
                        <tr>
                            <td style={{textAlign:'left'}}><Checkbox value='checkedTermeni' checked={this.state.checkedTermeni} onChange={(ev)=>{this.setState({checkedTermeni:ev.target.checked})}} /> <span>Am citit &#351;i accept <span className="meditatii-despre-noi-abonament-link" onClick={()=>{this.props.history.push("/termenisiconditii")}}>termenii si condi&#355;iile</span></span></td>
                         
                         <td>
                            <span>TOTAL: {this.state.total} lei</span> 
                         </td>
                        </tr>
                    </tfoot>
                </table>
                <Button  className={this.state.checkedTermeni === false ? '' : "meditatii-autentificare-button"} variant="contained" disabled={this.state.checkedTermeni === false ? true : false} onClick={this.goToPlata}>
                                Mai Departe</Button>
                </div>
            </div>
          );
    }
}
 



export class LiAbonament extends React.Component{
    constructor(props) {
        super(props);
        this.state = { 
            checkedTermeni:false,
            abonamente:[],
            cantitate:this.props.abonament.item_quantity
         }
        }

        abonamenteColor=(abonament)=>{
            switch(abonament){
                case 'Lunar':
                   return '#84c3f8'
                  
                case 'Pe an':
                  return  ' #5dd982 '  
                 
              
                    default:
                   return '#5b3ddd'      
            }
        }

        abonamentPret = (abonament)=>{
            switch(abonament){
                case 'Lunar':
                   return 'luna'
                  
                case 'Pe an':
                  return  'an'  
                 
              
                    default:
                   return 'viata'      
            }
        }

        onChangeTotal = ()=>{

            if(this.state.cantitate >= 1){
                this.props.total(this.state.cantitate, this.props.abonament)
            }else{

                this.setState({cantitate:1},()=>{
                    
                    this.props.total(this.state.cantitate, this.props.abonament)
                })
            }
                
            
        }

        deleteAbonament = async()=>{
            let deleteAb = await fromServer('cart', {mode:'delete_item_cart',

                                                    id_cart_item:this.props.abonament.id_cart_item,
                                                    id_user:getLocalUserData().idul}, {credentials:'include'})

            if(deleteAb.r === 'ok'){
                this.props.deleteAbonament(this.props.abonament)
            }                                        
        }

        changeCantitate = (ev)=>{
            if(ev.target.value <= 0){
                this.setState({cantitate:''})
            }else{
                this.setState({cantitate:ev.target.value})
            }
        }
        

        render(){
            return(<tr style={{border: '1px solid #e6e6e6'}}>
               
              <td style={{padding:20}}>   
    
      

          <Card className='meditatii-abonament-container'>
              <h2 className='meditatii-abonament-titlu'>{this.props.abonament.item_name}</h2>
              <h3 style={{color:this.abonamenteColor(this.props.abonament.item_name), fontSize:33}} >{this.props.abonament.item_price} lei/{this.abonamentPret(this.props.abonament.item_name)}</h3>
              <Button className="meditatii-abonament-button" style={{backgroundColor:this.abonamenteColor(this.props.abonament.item_name)}} variant='contained' >
                  Alege {this.props.abonament.item_name}
              </Button>
              

          </Card>
       
       
   </td>
              <td className="meditatii-abonament-pret" style={{position:'relative'}}>
              {this.props.abonament.item_price} lei 
              {/* <Button className="meditatii-cos-delete-button" variant='fab' onClick={this.deleteAbonament}>
                  <i className='material-icons'>clear</i>
                  
                  </Button> */}
                  </td>
          </tr>)
        }
}