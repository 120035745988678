import React from 'react';
import {  Grid } from '@material-ui/core';
import radioLive from '../Pics/radioLive.png'
import googlePlay from '../Pics/googlePlay.png'
import appStore from '../Pics/appStore.png'

import AppBar from '../../Componente/Comune/AppBar'





export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 3,

        }
    }

    componentDidMount() {
        this.scrollTop()
    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }


   


    render() {
        return (
            <div style={{}}>
             <AppBar history={this.props.history}/>
                <div className="sectionVideo">
                    <a id='meditatii-redirect-link' href="https://www.youtube.com/channel/UCPMpZtCjoZ2HvxUmMe9d5PA" target='_blank'  rel='noopener noreferrer'>
                        <div  className='meditatii-radio-live' style={{backgroundImage:`url(${radioLive})`}}>
                            
                        </div>
                        </a>
                    <video height={'auto'} className="meditatii-home-video" style={{ width: "100%" }} autoPlay={true} loop={true} muted="muted">

                        <source type="video/mp4" src={require('../../Module/Pics/BalanceTest.mov')} />
                       
                    
                    </video>
                    <div className="meditatii-home-title">
                        <h1 className="meditatii-home-title-text" style={{marginTop:120, marginBottom:5, fontSize:40}}>Bine ai venit acas&#259;  </h1>
                        <p className="meditatii-home-subtitle" style={{fontSize:20.5}}>Reduci stresul te  sim&#355;i bine &#351;i dormi bine</p>
                        {/* <p className="meditatii-home-subtitle" style={{fontSize:16.5}}>Descarc&#259; aplica&#355;ia gratuit sau inregistreaz&#259;-te online &#351;i &#238;ncepe s&#259; meditezi de azi. </p> */}
                        {/* <div className="mediteaza-home-register" onClick={()=>{this.props.history.push("/inregistreazate/0")}}>
                            <h2 className="mediteaza-register-text">&#206;NCEPE S&#258; MEDITEZI </h2>
                        </div> */}

                          <div className="mediteaza-home-register-promo" onClick={()=>{this.props.history.push("/inregistreazate/0")}}>
                            {/* <h2 className="mediteaza-register-text">&#206;NREGISTREAZ&#258;-TE GRATUIT &#350;I PRIME&#350;TI 20 DE MEDITA&#354;II CADOU </h2> */}
                            <h2 className="mediteaza-register-text-promo"> CAMPANIA "LINI&#350;TEA DE ACAS&#258;" ESTE ACTIV&#258;. AI ACCES GRATUIT TIMP DE 3 LUNI. CREEAZ&#258;-&#354;I CONT ACUM.</h2>

                        </div>
                        <div className='meditatii-store-container'>
                            <a id='meditatii-redirect-link' href="https://play.google.com/store/apps/details?id=com.aplicatiamea.casameditatiilor" target='_blank'  rel='noopener noreferrer'>
                            <div className='meditatii-store-icon' style={{ backgroundImage:`url(${googlePlay})`}}>
                            </div>
                            </a>
                            <a id='meditatii-redirect-link' href="https://apps.apple.com/ro/app/casa-meditatiilor/id1465892325?utm_source=feedburner&utm_medium=feed&utm_campaign=Feed%3A%20apple%2FEeAl%20%28iTunes%20Store%3A%20All%20New%20Applications%29&ign-mpt=uo%3D2" target='_blank'  rel='noopener noreferrer'>
                           
                            <div className='meditatii-store-icon' style={{ backgroundImage:`url(${appStore})`}}></div>
                            </a>

                        </div>
                        
                    </div>



                </div>
                <div className="meditatii-section-container">
                    <h2 className="section-title">Casa Medita&#355;iilor te ajut&#259; s&#259; ...</h2>
                    <Grid container className="meditatii-subsection-container">
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <img alt="acasa-meditatie" className="logo-img" src={require('../../Module/Pics/acasa-index-meditatie.png')} />
                            <h3 className="meditatii-subsection-title">Meditezi</h3>
                            <p className="meditatii-subsection-paragraph">O minte u&#351;oar&#259; face lucrurile f&#259;r&#259; efort</p>

                        </Grid>
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <img alt="meditatie-concentrare" className="logo-img" src={require('../../Module/Pics/acasa-index-concentrare.png')} />
                            <h3 className="meditatii-subsection-title">Te concentrezi</h3>
                            <p className="meditatii-subsection-paragraph">Vei deveni mai stabil, mai eficient si mai creativ</p>
                        </Grid>

                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <img alt="meditatie-relaxare" className="logo-img" src={require('../../Module/Pics/acasa-index-relaxare.png')} />
                            <h3 className="meditatii-subsection-title">Te relaxezi</h3>
                            <p className="meditatii-subsection-paragraph">&#206;&#355;i m&#226;ng&#226;ie inima si las&#259; via&#355;a s&#259; curg&#259; liber &#238;n corpul t&#259;u</p>
                        </Grid>

                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <img alt="meditatie-dormi" className="logo-img" src={require('../../Module/Pics/acasa-index-dormi.png')} />
                            <h3 className="meditatii-subsection-title">Dormi bine</h3>
                            <p className="meditatii-subsection-paragraph">Somnul lin imbl&#226;nze&#351;te corpul &#351;i te regenereaz&#259; </p>
                        </Grid>
                    </Grid>
                    <h1 className="section-title">Cu noi este simplu s&#259; meditezi</h1>
                    <p className="meditatii-section-paragraph">La noi &#238;n cas&#259; este natural s&#259; meditezi. Oamenii mediteaz&#259; de mii de ani. Studii &#351;tiin&#355;ifice recente au demonstrat impactul pozitiv al medita&#355;iei asupra oamenilor: mai pu&#355;in stres, mai mult&#259; creativitate, reduce riscurile bolilor de inim&#259; &#351;i atacurilor cerebrale, cre&#351;te imunitatea &#351;i te ajut&#259; s&#259; tr&#259;ie&#351;ti mai mult.</p>
                </div>

                <div className="meditatii-paralax">
                    <div className="meditatii-paralax-img1"></div>
                    <div className="meditatii-paralax-content">
                        <h2 className="meditatii-paralax-content-title">&#206;NREGISTREAZ&#258;-TE GRATUIT</h2>
                        <p className="meditatii-paralax-subtitle">&#206;nva&#355;&#259; s&#259; meditezi &#351;i s&#259; tr&#259;ie&#351;ti &#238;n prezent </p>
                        <div className="mediteaza-home-register" onClick={()=>{this.props.history.push("/inregistreazate/0")}}>
                            <h2 className="mediteaza-register-text">&#206;nregistreaz&#259;-te gratuit &#351;i prime&#351;ti cadou 20 de medita&#355;ii </h2>
                        </div>
                    </div>

                </div>
                <div className="meditatii-section-container">
                    <h1 className="meditatii-section-smaller-title">Categorii populare:</h1>
                    <p className="meditatii-section-subtitle">Minte, Corp, Relații, Stil de Via&#355;&#259;, Tonuri Binaurale și multe altele.</p>
                </div>
                <div className="meditatii-section-container">
                    <Grid container className="meditatii-subsection-container">
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <img alt="meditatie-muzica" className="logo-img" src={require('../../Module/Pics/muzica.png')} />
                            <h3 className="meditatii-subsection-subtitle">Muzic&#259;</h3>


                        </Grid>
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <img alt="meditatie-minte" className="logo-img" src={require('../../Module/Pics/minte.png')} />
                            <h3 className="meditatii-subsection-subtitle">Minte</h3>
                        </Grid>

                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <img alt="meditatie-legaturi" className="logo-img" src={require('../../Module/Pics/calm.png')} />
                            <h3 className="meditatii-subsection-subtitle">Stil de via&#355;&#259;</h3>
                        </Grid>

                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <img alt="meditatie-calm" className="logo-img" src={require('../../Module/Pics/legaturi.png')} />
                            <h3 className="meditatii-subsection-subtitle">Rela&#355;ii</h3>
                        </Grid>
                    </Grid>
                    <div className="mediteaza-message-text">
                        <h2 className="mediteaza-register-text" onClick={()=>{this.props.history.push("/inregistreazate/0")}}>&#206;ncepe s&#259; meditezi </h2>
                    </div>
                </div>

                <div className="meditatii-paralax2">
                    <div className="meditatii-paralax-img2"></div>
                    <div className="meditatii-paralax-content">
                        <h2 className="meditatii-paralax-content-title2">Transform&#259;-&#355;i Via&#355;a</h2>
                        <p className="meditatii-paralax-content-subtitle2">Medita&#355;iile din Cas&#259; sunt scrise de autori entuzia&#351;ti &#351;i mae&#351;tri spirituali
                                                                    care practic&#259; medita&#355;ia și yoga ca stil de via&#355;&#259; &#351;i care te vor ajuta
                                                                    s&#259; intri &#238;n st&#259;ri meditative profunde pentru a-&#355;i transforma via&#355;a &#238;n bine.</p>
                        <h2 className="meditatii-paralax-content-title2">Lini&#351;te &#38; G&#226;nduri Pozitive</h2>
                        <p className="meditatii-paralax-content-subtitle2">Odat&#259; &#206;nregistrat vei avea acces la zeci de medita&#355;ii ghidate
                         &#351;i muzic&#259; de medita&#355;ie, relaxare pentru a &#238;mbun&#259;t&#259;&#355;i fiecare aspect al vie&#355;ii tale.</p>
                        <div className="mediteaza-message-text">
                            <h2 className="mediteaza-register-text" onClick={()=>{this.props.history.push("/decesameditam")}}>Vezi Detalii</h2>
                        </div>

                    </div>

                </div>

               {/* <FooterContainer/> */}








            </div>
        );
    }

}

;



