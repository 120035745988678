import React from 'react';

import { Grid } from '@material-ui/core'



export default class Player extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            barWidth: ""
        }
    }


    componentDidMount() {
        this.barWidth()
        window.addEventListener('resize', this.barWidth)
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.barWidth)
    }

   

    barWidth = () => {
        let container = document.getElementById('meditatii-myplayer')
       // let volWidth = document.getElementById('meditatii-player-volume')
        let playIcon = document.getElementById('play-icon')
        let volumeIcon = document.getElementById('player-volume-container')
        let timer = document.getElementById('player-timer')

        let bar = ((playIcon.clientWidth + volumeIcon.clientWidth + timer.clientWidth + 38 + 38 ) / container.clientWidth * 100) - 100

       
        this.setState({ barWidth: -bar + '%' })
    }

    render() {
        return (
            <Grid container className="meditatii-subsection-subsection meditatii-player-container" id="meditatii-myplayer" style={{ margin: "20px auto" }} >
                <Grid className="meditatii-subsection-subsection" style={{ padding: 0 }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div id='play-icon' style={{ width: 'fit-content', display: 'inline-flex', verticalAlign:'bottom' }}>
                        <i className='material-icons' style={{ fontSize: 38, color: "#ea7ab7", cursor: 'pointer' }} onClick={this.props.playPauseFunc}>{this.props.playPause}</i>
                    </div>
                    <div id='player-timer' style={{  display: 'inline-flex' }}>
                        <span style={{ fontSize: 12,  }}>{this.props.duration}</span>
                    </div>
                    <div style={{ height: '20px', border: '1px solid #ea7ab7', display: 'inline-flex', width: this.state.barWidth, borderRadius: 10, marginTop: 12, }} id='bar' onClick={this.props.showCoords} >

                        <div style={{ backgroundColor: '#ea7ab7', width: this.props.progressWidth + "%", height: '100%', borderRadius: 10 }}  ></div>
                    </div>
                    <div className="meditatii-volume-container" id='player-volume-container'>
                        <div id="meditatii-player-volume" onClick={this.props.setVolume}>
                            <div className="meditatii-volume-bars" style={{ height: 8, bottom: 0, display: this.props.bar1 }} ></div>
                            <div className="meditatii-volume-bars" style={{ height: 16, bottom: 0, left: 9, display: this.props.bar2 }} ></div>
                            <div className="meditatii-volume-bars" style={{ height: 24, bottom: 0, left: 18, display: this.props.bar3 }} ></div>
                            <div className="meditatii-volume-bars" style={{ height: 32, bottom: 0, left: 27, display: this.props.bar4 }}></div>
                        </div>
                    </div>
                </Grid>
                {/* <Grid className="meditatii-subsection-subsection" id='meditatii-bar-vol-container' style={{padding:0}} item xs={10} sm={10} md={10} lg={10} xl={10}   >
         
            </Grid> */}


            </Grid>

        );
    }
}

;

