import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import { List, Grid, FormControl, Input, InputLabel, InputAdornment, IconButton, Button, Dialog, DialogActions, DialogContent, Select, MenuItem } from '@material-ui/core'
import LiMeditatii from './LiMeditatii'
import {  fromServer } from '../../Providers/ws'
import { getLocalUserData } from '../../Providers/ls';


export default class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            titlu:'',
            cauta: '',
            filterContent: [],
            categories: [],
            openAdd: false,
            categorie:'',
            disabledButton:true
        }
    }

    componentDidMount() {
       
       // this.scrollTop()

       this.verifyAdmin()
    }

    verifyAdmin = async()=>{
        
        let server = await fromServer('users',{mode:'check_if_admin', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune}, {credentials:'include'})

        if(server.r === 'ok'){

            this.getCategories()
            if(this.state.techDebug === 1){
            
        
            }
        }else{
            this.props.history.push('/')
        }
    }

    
    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    insertContent= async()=>{
    

        let insertContent = await fromServer('content',{mode:'insert_content', name:this.state.titlu, type:'audio', id_category:this.state.categorie}, {credentials:'include'})

       

        if(insertContent.r === 'ok'){
            this.props.history.push(`/audiocontent/${insertContent.id_introdus}`)

            
        }
     
        

    }

    changeSearch=(ev)=>{
        this.setState({cauta:ev.target.value})
    }

    getCategories = async () => {

        let categories = await fromServer('category', {mode:'list_categories_admin'}, {credentials:'include'})

        
        if (categories.r === 'ok') {
            this.setState({ categories: categories.continut }, () => {
                this.getContent()
            })
        }
    }

    getContent = async () => {

        let content = await fromServer('content',{mode:'content_list_admin', id_user:getLocalUserData().idul, id_sesiune: getLocalUserData().id_sesiune}, {credentials:'include'})

      

    
        if (content.r === 'ok') {

            let uniqueArr = [];

            uniqueArr = content.continut.filter((el, i, self) => (
                i === self.findIndex((t) => t.nume === el.nume)

            ))

            let counter = uniqueArr.length

            for (let i = 0; i < counter; i++) {


                //uniqueArr[i].nume_categorie = ''
                let counter2 = content.continut.length
                for (let k = 0; k < counter2; k++) {
                    if ((uniqueArr[i].idul === content.continut[k].idul) && (uniqueArr[i].id_categorie !== content.continut[k].id_categorie)) {

                     
                        uniqueArr[i].nume_categorie += ', ' + content.continut[k].nume_categorie + ", "
                    }




                }
                if (uniqueArr[i].nume_categorie !== null && (uniqueArr[i].nume_categorie.substring(uniqueArr[i].nume_categorie.length - 2) === ', ') ) {

                    uniqueArr[i].nume_categorie = uniqueArr[i].nume_categorie.substring(0, uniqueArr[i].nume_categorie.length - 2)
                }
            }




           
            this.setState({
                content: uniqueArr,
                filterContent: uniqueArr
            }, () => {

            })
        }
    }

    closeAdd=()=>{
        this.setState({openAdd:false})
    }



    search = () => {
        let content = []

        //content = this.state.content.filter(c => (c.nume.toLowerCase().includes(this.state.cauta.toLowerCase())))

        content = this.state.content.filter((c) => {return c.nume.toLowerCase().indexOf(this.state.cauta.toLowerCase()) !== -1 })

        this.setState({ filterContent: content })
    }
    render() {
        return (
            <div>
                <AppBar history={this.props.history} />

                <Dialog

                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.openAdd}
                    onClose={this.closeAdd}
                >

                    <DialogActions style={{ justifyContent: 'center' }}>

                        <Button className='meditatii-close-dialog-button' onClick={this.closeAdd} variant="fab">
                            <i className='material-icons'>clear</i>
                        </Button>

                    </DialogActions>


                    <h2 style={{ padding: '20px 10px 0 10px', marginTop: 20, textAlign: 'center' }} className="meditatii-section-meditezi-title">
                        Adauga Meditatie
                        </h2>

                    <DialogContent style={{textAlign:'center'}}>
                    
                            <FormControl className="meditatii-text-field" style={{width:"100%"}}  >
                                <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-titlu">Titlu</InputLabel>
                                <Input
                                    disableUnderline={true}
                                    id="meditatii-titlu"
                                    type='text'
                                    value={this.state.titlu}
                                    onChange={(ev) => { this.setState({ titlu: ev.target.value }) }}
                                  

                                />
                            </FormControl>

                             <FormControl style={{width:"100%", marginTop:16}}> 
                                    <InputLabel htmlFor="mag">Categorie</InputLabel>
                                    <Select value={this.state.categorie || ""}
                                            onChange={(ev)=>{this.setState({categorie:ev.target.value})}}
                                           
                                            input={<Input id="mag"/>}>
                                                
                                            
                                                { this.state.categories.map((m,idx)=>{
                                                    return <MenuItem value={m.idul}  key={idx}  > {m.nume_categorie}</MenuItem>
                                                    
                                                })
                                            }
                                    </Select>
                                </FormControl>

                        
                        <Button style={{marginTop:20}} variant='contained' color="primary" className='meditatii-autentificare-button' onClick={this.insertContent} >
                            Adauga
                                </Button>

                    </DialogContent>



                </Dialog>
                <h1 className="section-title" style={{ marginBottom: 10 }} >Meditatii Content</h1>
                <div className="meditatii-back-button" onClick={() => { this.props.history.push('/administrator') }}><i className="material-icons meditatii-back-button-icon">reply</i></div>
                <Grid container className="meditatii-subsection-container" style={{ marginTop: 10 }}>
                    <Grid style={{ margin: "auto" }} item xs={12} sm={12} md={6} lg={6} xl={6}>

                        <Button style={{ color: 'darkorange' }} color="primary" className='meditatii-autentificare-button' onClick={() => { this.setState({ openAdd: true }) }} >
                            Adauga Content
                                </Button>

                        <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl className="meditatii-text-field"  >
                                <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-cauta">Cauta</InputLabel>
                                <Input
                                    disableUnderline={true}
                                    id="meditatii-cauta"
                                    type='text'
                                   
                                    onBlur={this.changeSearch}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                style={{ color: "#bfbfbf", border: 'none' }}

                                                onClick={this.search}
                                            >
                                                <i className="material-icons" >search</i>
                                            </IconButton>
                                        </InputAdornment>
                                    }

                                />
                            </FormControl>

                        </Grid>

                    </Grid>
                </Grid>
                <List style={{ marginBottom: 100, }}>
                    {
                        this.state.filterContent.map((c, idx) => {
                            return (
                                <LiMeditatii key={idx} index={idx} content={c} history={this.props.history} />

                            )
                        })
                    }

                </List>
            </div>
        );
    }
}

;