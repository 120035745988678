import React from 'react';
import backgroundab from '../Pics/backgroundab.jpg'
import AppBar from '../../Componente/Comune/AppBar'
import testimoniale from '../Pics/testimoniale.png'
import evaluaretestimonial from '../Pics/evaluaretestimonial.png'

import {Grid, Card, Button} from '@material-ui/core'
import {  getLocalUserData, setLocalUserData } from '../../Providers/ls';
import {fromServer} from '../../Providers/ws'
/*global FB*/
export default class Abonamente extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            abonamente:[]
         }
    }

    componentDidMount(){
        this.scrollTop()
        this.getAbonamente()
    }
    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    fbLogOut=()=>{
        FB.getLoginStatus((response) =>{

            if(response.status === 'connected'){
                FB.logout(function(response){
                   
                })
            }
            
          });
    }


    logOut=async()=>{

        let user = {
            existaDate:false,
            fbLogin:false,
            esteLogat:false,
            nume:'',
            idul:'',
            email:'',
            nume_utilizator:'',
            admin:'',
            blocat:'',
            id_sesiune:'',
            data_creare_cont:'',
            data_modificare_cont:'',
            label_tip_cont:'',
            cos:''
        }

     
        
       
        setLocalUserData(user)
        
        this.fbLogOut()
        this.props.history.push('/autentificare/0')
    }

    getAbonamente = async()=>{
        let abonamente = await fromServer('subscriptions',{mode:'get_subscriptions'}, {credentials:'include'})



        if(abonamente.r === 'ok'){
            this.setState({abonamente:abonamente.continut})
        }
    }

    addToCart= async(a)=>{

    if(getLocalUserData().esteLogat === true ){

        let cart = await fromServer('cart',{mode:'add_item_cart',
                                            id_user:getLocalUserData().idul,
                                             id_sesiune:getLocalUserData().id_sesiune,
                                            id_item:a.idul,
                                            item_name:a.nume,
                                            item_quantity:1,
                                        item_price:a.pret,
                                    item_type:'abonament'}, {credentials:'include'})
       

        if(cart.r === 'ok'){
            this.props.history.push('/abonament')
        }
        else{

            this.logOut()
        }
    }else{
        this.props.history.push('/autentificare/0')
    }
    }


   


    abonamenteColor=(abonament)=>{
        switch(abonament){
            case '1':
               return '#84c3f8'
              
            case '2':
              return  ' #5dd982 '  
             
          
                case '3':
               return '#5b3ddd' 
               
               
               default:
                return '#84c3f8'
        }
    }

    abonamentPret = (abonament)=>{

        console.log('abonament :', abonament);
        switch(abonament){
            case 'Lunar':
               return 'lună'
              
            case 'Pe An':
              return  'an'  
             
          
                default:
               return 'viață'      
        }
    }
    
    render() { 
        return (
            <div style={{backgroundImage:`url(${backgroundab})`, backgroundRepeat:'no-repeat', backgroundSize:'cover'}}>
             <AppBar history={this.props.history} />
                 <div className="meditatii-section-container" style={{backgroundColor:'transparent'}} >
                 <h1 className="meditatii-abonamente-title">Deblocheaz&#259; Toate Beneficiile Casei Medita&#355;iilor</h1>
                 <h2 className="meditatii-abonamente-subtitle">Acces nelimitat la zeci de medita&#355;ii ghidate, programe &#351;i muzic&#259; de relaxare</h2>
                 <Grid container className="meditatii-subsection-container">
                 {/* {this.state.abonamente.map((a, idx)=>{
                     return(
                        <Grid style={{position:'relative'}} key={idx} className="meditatii-subsection-subsection" item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div style={{display:a.idul === '2'  ? 'block' : 'none'}} className={a.idul === '2' ?'mediatii-abonament-pe-an' : ''}> CEL MAI POPULAR </div>
                        <Card className='meditatii-abonament-container'>
                            <h2 className='meditatii-abonament-titlu'>{a.nume}</h2>
                            <h3 style={{color:this.abonamenteColor(a.idul), fontSize:33}} >{a.pret} lei/{this.abonamentPret(a.nume)}</h3>
                            <Button className="meditatii-abonament-button" style={{backgroundColor:this.abonamenteColor(a.idul)}} variant='contained' onClick={()=>{this.addToCart(a)}}>
                                Alege {a.nume}
                            </Button>
                            <p className='meditatii-abonament-detalii'>{a.descriere}</p>

                        </Card>
                        </Grid>
                     )
                 })} */}
                     
                     <Grid style={{position:'relative', margin:'auto'}}  className="meditatii-subsection-subsection" item xs={12} sm={12} md={4} lg={4} xl={4}>
                        {/* <div style={{display:a.idul === '2'  ? 'block' : 'none'}} className={a.idul === '2' ?'mediatii-abonament-pe-an' : ''}> CEL MAI POPULAR </div> */}
                        <Card className='meditatii-abonament-container' onClick={()=>{this.props.history.push("/inregistreazate/0")}}>
                            <h2 className='meditatii-abonament-titlu' style={{fontWeight:600, fontSize:24}}>3 LUNI</h2>
                            <h3 style={{color:'#ea7ab7', fontSize:36, fontWeight:600, }} >GRATUIT</h3>
                            <Button className="meditatii-abonament-button" style={{backgroundColor:'#ea7ab7', fontWeight:600}} variant='contained' onClick={()=>{this.addToCart(0)}}>
                            CREEAZ&#258;-&#354;I CONT ACUM!
                            </Button>
                            <p className='meditatii-abonament-detalii'>CAMPANIA "LINI&#350;TEA DE ACAS&#258;" ESTE ACTIV&#258;. AI ACCES GRATUIT TIMP DE 3 LUNI. </p>

                        </Card>
                        </Grid>
                  
                    </Grid>

                     <h1 className="meditatii-abonamente-title" style={{marginBottom:50}}>Ce spune lumea</h1>


                     <Grid container className="meditatii-subsection-container">
                        <Grid className="meditatii-subsection-subsection meditatii-margin-bottom50" style={{textAlign:'left'}} item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${testimoniale})`, height:20}}></div> <p className="meditatii-abonament-paragraph">Frumos site, chiar mi-a adus un z&#226;mbet pe buze. Cu to&#355;ii avem nevoie de energii pozitive &#238;ntr-o astfel de perioad&#259; provocant&#259;. </p>
                        <div></div>
                           <p className="meditatii-abonament-nume">Alex B. </p><div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                           

                        </Grid>
                        <Grid className="meditatii-subsection-subsection meditatii-margin-bottom50" style={{textAlign:'left'}} item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${testimoniale})`, height:20}}></div> <p className="meditatii-abonament-paragraph">Sunt la a 4-a zi a tutorialului de pace... E chiar uimitor ce se &#238;nt&#226;mpl&#259;.. este bine. </p>
                        <div></div>
                           <p className="meditatii-abonament-nume">Gabriela P.</p><div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                           

                        </Grid>

                         <Grid className="meditatii-subsection-subsection meditatii-margin-bottom50" style={{textAlign:'left'}} item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${testimoniale})`, height:20}}></div> <p className="meditatii-abonament-paragraph">Medita&#355;iile sunt deosebite. Ajunge&#355;i la sufletul omului.. mul&#355;umesc pentru aceste lec&#355;ii concentrate de via&#355;&#259;.  </p>
                        <div></div>
                           <p className="meditatii-abonament-nume">Gabi S.</p><div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                           

                        </Grid>
                        <Grid className="meditatii-subsection-subsection meditatii-margin-bottom50" style={{textAlign:'left'}} item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${testimoniale})`, height:20}}></div> <p className="meditatii-abonament-paragraph">Bun g&#259;sit. De c&#226;nd am &#238;nceput s&#259; meditez am &#238;nv&#259;&#355;at s&#259; nu mai am a&#351;tept&#259;ri. Am avut revela&#355;ii m&#259;re&#355;e &#351;i am g&#259;sit &#238;n interiorul meu tot ceea ce alt&#259;dat&#259; primeam din exterior &#351;i cred c&#259; &#351;i mai mult. Acum sunt sub efectul entuziasmului, dar este radical mai bine dec&#226;t c&#226;nd v-am scris ultima oar&#259;. &#350;i sunt &#238;ntr-o transformare continu&#259;, ascensiune chiar, eu av&#226;nd de vindecat o minte &#351;i un creier care nu au putere de concentrare &#351;i memorare. Va mul&#355;umesc, iubesc toate medita&#355;iile &#351;i pe dumneavoastr&#259; implicit. Pace &#351;i Lumin&#259;. </p>
                        <div></div>
                           <p className="meditatii-abonament-nume">Nina B.</p><div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                           

                        </Grid>

                        <Grid className="meditatii-subsection-subsection meditatii-margin-bottom50" style={{textAlign:'left'}} item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${testimoniale})`, height:20}}></div> <p className="meditatii-abonament-paragraph">Medita&#355;iile de pe site m&#259; ajut&#259; s&#259; fiu prezent&#259;. "AICI" e pauza &#238;ntre dou&#259; c&#259;l&#259;torii. Un tren a plecat, altul urmeaz&#259; s&#259; vin&#259;. Are rost s&#259;-&#355;i at&#226;rni bagaje multe &#351;i grele? Nu-i mai simplu s&#259;-&#355;i petreci timpul &#238;n cel mai pl&#259;cut mod? E at&#226;t de frumoas&#259; ARMONIA, e at&#226;t de frumos s&#259; FII ADEV&#258;RAT!</p>
                        <div></div>
                           <p className="meditatii-abonament-nume">Gina P.</p><div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                           

                        </Grid>

                        <Grid className="meditatii-subsection-subsection meditatii-margin-bottom50" style={{textAlign:'left'}} item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${testimoniale})`, height:20}}></div> <p className="meditatii-abonament-paragraph">Mul&#355;umesc pentru aceast&#259; unealt&#259; care m&#259; ajut&#259; s&#259; &#238;mi fac via&#355;a u&#351;oar&#259;. Lucrez &#238;ntr-un mediu stresant si &#238;n schimbare &#351;i am descoperit c&#259; medita&#355;iile ghidate de pe site m&#259; lini&#351;tesc. M&#259; ajut&#259; s&#259; &#238;mi p&#259;strez concentrarea &#351;i calmul. Mi-a fost greu la &#238;nceput p&#226;n&#259; mi-am g&#259;sit ritmul, dar acum meditez &#238;n fiecare zi.</p>
                        <div></div>
                           <p className="meditatii-abonament-nume">Sorin P.</p><div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                           

                        </Grid>

                        <Grid className="meditatii-subsection-subsection meditatii-margin-bottom50" style={{textAlign:'left'}} item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${testimoniale})`, height:20}}></div> <p className="meditatii-abonament-paragraph">10 minute de medita&#355;ie pe zi &#351;i am pauza perfect&#259;, oriunde, doar pentru mine :) vmi plac mult medita&#355;iile de pe site pentru c&#259; m&#259; ajut&#259; s&#259; g&#259;sesc calmul &#238;n haos. </p>
                        <div></div>
                           <p className="meditatii-abonament-nume">Andrei P.</p><div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                           

                        </Grid>

                        <Grid className="meditatii-subsection-subsection meditatii-margin-bottom50" style={{textAlign:'left'}} item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${testimoniale})`, height:20}}></div> <p className="meditatii-abonament-paragraph">Medita&#355;iile m-au ajutat enorm s&#259; fac fa&#355;&#259; stresului. Am &#238;nv&#259;&#355;at s&#259; folosesc exerci&#355;ii de respira&#355;ie atunci c&#226;nd devin stresat la birou… acum este mai bine! </p>
                        <div></div>
                           <p className="meditatii-abonament-nume">Mihai V.</p><div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                                                                          <div className='meditatii-abonament-testimonial' style={{backgroundImage:`url(${evaluaretestimonial})`, height:20}}></div>
                           

                        </Grid>

                       
                  
                    </Grid>
                 </div>

            </div>
          );
    }
}
 
 ;