import React from 'react';
import {ListItem, ListItemText, Divider} from '@material-ui/core'
import { arataData, arataOre } from '../../Providers/utile';


export default class LiClienti  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
         
          }
    }


  

    render() { 
        return ( 
            <div style={{width:'100%'}}>
            <ListItem   button>
            <ListItemText onClick={()=>{this.props.history.push(`/infoClient/${this.props.client.idul}`)}} primary={<span className="meditatii-admin-clienti-list-text" style={{textAlign:'left', display:'inline'}}>{this.props.index + 1}. {this.props.client.nume} ({this.props.client.email})</span>}
                          secondary={<span style={{textAlign:'left', display:'block', marginLeft: 20}}>Cand creat: <span className='meditatii-list-admin-label-info' >{arataData(this.props.client.data_creare_cont)} - Ora: {arataOre(this.props.client.data_creare_cont)}</span></span>}
                                    />
            
            
        </ListItem>
        <Divider/>
        </div>
         );
    }
}
 
 ;