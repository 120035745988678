import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import {Grid, Button} from '@material-ui/core'
import {   fromServer } from '../../Providers/ws';
import Alert from '../../Componente/Comune/Alert'

export default class ResetareParola extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            parolanoua:'',
            confirmaparola:'',
            vParola:'',
            vToken:false,
            openAlert:false,
            alertTitle:''
         }
    }

    componentDidMount(){
            this.verifyToken()
    }



    dialogClose=()=>{
        this.props.history.push('/autentificare/0')
    }


    verifyToken = async ()=>{

        let token = await fromServer('reset_password',{mode:'check_token',
                                                        token:this.props.match.params.token}, {credentials:'include'})

      

        if(token.r === 'err'){
            this.setState({vToken:true,
                            openAlert:true,
                            alertTitle:token.mesaj})
        }
        
    }


    validareParola=async()=>{
        if(this.state.parolanoua !== this.state.confirmaparola){
            this.setState({vParola:'Noua parola trebuie sa fie la fel cu confirmarea'})
        }
        else if(this.state.parolanoua.length < 3){
            this.setState({vParola:'Parola trebuie sa aiba minim 3 caractere'})
        }
        else{
            this.setState({vParola:''},async()=>{

                // let nouaParola ={
                //     new_password: this.state.parolanoua
                // }


                let resetpass = await fromServer('reset_password',{mode:'reset_password',
                                                                token:this.props.match.params.token},
                                                                {credentials:'include',
                                                                method:'POST'}, false, "POST", this.state.parolanoua, 'postData')

          
                   
                if(resetpass.r === 'ok'){

                    this.props.history.push('/autentificare/0')
                }
            })
            
        }
    }
    render() { 
        return ( 
            <div>
                 <AppBar history={this.props.history} />

                 <Alert
                        open={this.state.openAlert}
                        title={this.state.alertTitle}
                        dialogClose={this.dialogClose}
                 />
                <div className="meditatii-section-container">
                <Grid container className="meditatii-subsection-container" style={{marginTop:200}}>
                    
                    <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                  
                    <label className="meditatii-login-label" htmlFor="parolanoua">Parola Noua</label>
                       <input type="password" id="parolanoua" className="meditatii-utilizator" value={this.state.parolanoua} onChange={(ev)=>{this.setState({parolanoua:ev.target.value})}}/>

                    </Grid>
                    <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="meditatii-login-label" htmlFor="confirmaparola">Confirma Parola</label>
                       <input type="password" id="confirmaparola" className="meditatii-utilizator" value={this.state.confirmaparola} onChange={(ev)=>{this.setState({confirmaparola:ev.target.value})}} />
                       <span className="meditatii-success-message">{this.state.vParola}</span>

                    </Grid>
                    <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                    
                    <Button className="meditatii-autentificare-button" variant="contained" onClick={this.validareParola}>
                        SCHIMBA PAROLA
                    </Button>
                       </Grid>
                </Grid>
                </div>
            </div>
         );
    }
}
 
