import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import {Grid, FormControl, InputLabel, Input,  Button, Card} from '@material-ui/core'

import { setLocalUserData, getLocalUserData } from '../../Providers/ls';
/*global FB*/

export default class  extends React.Component {
    constructor(props) {
      super(props);
       this.state = {

        nume:'',
        prenume:'',
        telefon:'',
        email:'',
        oras:'',
        tara:'',
        vnume:'',
        vprenume:'',
        vtelefon:'',
        vemail:'',
        validaten:false,
        validatep:false,
        validatet:false,
        valdiatee:false,
        checkData:false

 
}
    }
     


    checkData = async ()=>{


        let validaten = false
        let validatep=false
        let validatee = false
        let validatet = false
        let vnume = ''
        let vprenume =''
        let vtelefon = ''
        let vemail = ''


        if(this.state.nume.length < 2){
                vnume ='Numele este obligatoriu'
                validaten = false
          
        }else{
                vnume = ''
                validaten = true
          
        }

        if(this.state.prenume.length < 2){
                vprenume = 'Prenumele este obligatoriu'
                validatep = false
            
        }else{
                vprenume = ''
                validatep = true
            
        }

        if(this.state.email.indexOf('@') === -1){
            vemail='Emailul nu este corect'
                validatee = false
           
        }else{
            vemail=''
                validatee = true
            
        }

        if(this.state.telefon.length < 9){
                vtelefon='Telefonul este obligatoriu'
                validatet = false
            
        }else{
                vtelefon=''
                validatet = true
           
        }

        if((validaten === true) && (validatep === true) && (validatee === true) && (validatet === true)){
        
            this.setState({
                vnume:'',
                vprenume:'',
                vemail:'',
                vtelefon:'',
                
            },()=>{

                this.achitaComanda()
            })
        }
        else{
            if(validaten === false){
                this.setState({vnume :vnume})
            }else{
                this.setState({vnume :''})
            }

            if(validatep === false){
                this.setState({vprenume :vprenume})
            }else{
                this.setState({vprenume :''})
            }

            if(validatee === false){
                this.setState({vemail :vemail})
            }else{
                this.setState({vemail :''})
            }

            if(validatet === false ){
                this.setState({vtelefon :vtelefon})
            }else{
                this.setState({vtelefon :''})
            }
        }
      
    }

    fbLogOut=()=>{
        FB.getLoginStatus((response) =>{

            if(response.status === 'connected'){
                FB.logout(function(response){
                   
                })
            }
            
          });
    }


    logOut=()=>{

        let user = {
            existaDate:false,
            fbLogin:false,
            esteLogat:false,
            nume:'',
            idul:'',
            email:'',
            nume_utilizator:'',
            admin:'',
            blocat:'',
            data_creare_cont:'',
            data_modificare_cont:'',
            label_tip_cont:'',
            cos:''
        }
        setLocalUserData(user)
        window.location.href = '/'
        this.fbLogOut()
    
    }

  fromServerAchita =  (pagina, paramsToSend, httpParam) =>{

    //const adresaServer = "http://192.168.100.10:8888/CM/API/files/"

    const adresaServer = "https://www.casameditatiilor.ro/API/files/"

    let adresa = `${adresaServer}${pagina}.php`

        if(paramsToSend){
            adresa+='?'
            for (let [key, value] of Object.entries(paramsToSend)) {
               adresa+= `${key}=${value}&`
            }
           
         
            adresa =  adresa.substring(0, adresa.length -1)
        }
        adresa = encodeURI(adresa)
    this.logOut()
       document.location.href=adresa
    
    }

    achitaComanda = () =>{
        

          this.fromServerAchita('transactions',{mode:'update_transaction_client_data', 
                                                            id_tranzactie_head:this.props.match.params.idtranzactie,
                                                            id_user: getLocalUserData().idul,
                                                            id_sesiune:getLocalUserData().id_sesiune,
                                                            last_name:this.state.nume,
                                                            first_name:this.state.prenume,
                                                        email:this.state.email,
                                                        phone:this.state.telefon,
                                                     country:this.state.tara,
                                                     city:this.state.oras},{credentials:'include'}, false)

       

                                                  

        
    }


     componentDidMount(){
    }
     render() { 
       return (
          <div>
              <AppBar history={this.props.history} />
              <Grid container className="meditatii-subsection-container" style={{ marginTop: 80 }}>
                                <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>
                             
                                    <Card style={{ paddingBottom: 30, borderRadius: 20  }}>
                                    <h1 className="meditatii-paralax-content-title " style={{ marginTop: 40, color:'black',  }}>Detalii Client</h1>
                                       
                                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormControl className="meditatii-text-field"  >
                                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="utilizator">Nume</InputLabel>
                                                    <Input
                                                        disableUnderline={true}
                                                        id="utilizator"
                                                        type='text'
                                                        value={this.state.nume}
                                                        onChange={(ev) => { this.setState({ nume: ev.target.value }) }}

                                                    />
                                                </FormControl>
                                                <span className="meditatii-success-message" style={{color:'red'}}>{this.state.vnume}</span>

                                            </Grid>
                                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormControl className="meditatii-text-field"  >
                                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="utilizator">Prenume</InputLabel>
                                                    <Input
                                                        disableUnderline={true}
                                                        id="utilizator"
                                                        type='text'
                                                        value={this.state.prenume}
                                                        onChange={(ev) => { this.setState({ prenume: ev.target.value }) }}

                                                    />
                                                </FormControl>
                                                <span className="meditatii-success-message" style={{color:'red'}}>{this.state.vprenume}</span>

                                            </Grid>

                                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormControl className="meditatii-text-field"  >
                                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="utilizator">Email</InputLabel>
                                                    <Input
                                                        disableUnderline={true}
                                                        id="utilizator"
                                                        type='text'
                                                        value={this.state.email}
                                                        onChange={(ev) => { this.setState({ email: ev.target.value }) }}

                                                    />
                                                </FormControl>
                                                <span className="meditatii-success-message" style={{color:'red'}}>{this.state.vemail}</span>

                                            </Grid>

                                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormControl className="meditatii-text-field"  >
                                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="utilizator">Telefon</InputLabel>
                                                    <Input
                                                        disableUnderline={true}
                                                        id="utilizator"
                                                        type='number'
                                                        value={this.state.telefon}
                                                        onChange={(ev) => { this.setState({ telefon: ev.target.value }) }}

                                                    />
                                                </FormControl>
                                                <span className="meditatii-success-message" style={{color:'red'}}>{this.state.vtelefon}</span>


                                            </Grid>

                                            {/* <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormControl className="meditatii-text-field"  >
                                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="utilizator">Tara</InputLabel>
                                                    <Input
                                                        disableUnderline={true}
                                                        id="utilizator"
                                                       
                                                        value={this.state.tara}
                                                        onChange={(ev) => { this.setState({ tara: ev.target.value }) }}

                                                    />
                                                </FormControl>


                                            </Grid> */}

                                            {/* <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormControl className="meditatii-text-field"  >
                                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="utilizator">Oras</InputLabel>
                                                    <Input
                                                        disableUnderline={true}
                                                        id="utilizator"
                                                        
                                                        value={this.state.oras}
                                                        onChange={(ev) => { this.setState({ oras: ev.target.value }) }}

                                                    />
                                                </FormControl>


                                            </Grid> */}
                                          
                                            <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>

                                                <Button className="meditatii-autentificare-button" variant="contained" onClick={this.checkData} >
                                                    Pasul Urmator
                                              </Button>
                                              </Grid>
                                             </Card>
                                              </Grid>
                                              </Grid>
         </div>
        );
       }
}