import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import {  imgUpload, fromServer } from '../../Providers/ws';
import { Grid } from '@material-ui/core';
import Player from '../../Componente/Comune/Player'
import { getLocalUserData } from '../../Providers/ls';
import Alert from '../../Componente/Comune/Alert'
import {audio} from '../../Componente/Comune/AudioElement'
import defaultImg from '../Pics/defaultImg.png'





export default class Meditatie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            meditatie: {
                cale_poza_background: '',
                cale_audio: ''
            },
            starIcon: 'favorite_border',
            duration: "",
            dataHref: '',
            progressWidth: '0',
            playPause: 'play_arrow',
            volume: "fas fa-volume-up",
            bar1: 'block',
            bar2: 'block',
            bar3: 'block',
            bar4: 'block',
            staticDuration: '',
            alertO:false
            

        }

    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    


    componentDidMount() {
        this.scrollTop()
        this.getMeditatie()
        this.getDataHRef()
        this.fbComment(document, "div", "fb-root")
        this.getFavorites()

        //this.getSrc('hello world')
    }


    componentDidUpdate() {
     
    }

    disableRightClick=(e)=>{
        e.preventDefault();
    }


    getDataHRef = () => {
        let data = `https://www.casameditatiilor.ro/#/meditatie/${this.props.match.params.idmeditatie}/${this.props.match.params.idcategorie}`
        this.setState({ dataHref: data })
    }

    playPause = () => {
        audio.currentTime = Math.floor((audio.duration * this.state.progressWidth) / 100)
        audio.ontimeupdate = () => {

            let duration = audio.duration - audio.currentTime
           
            let hour = Math.floor(duration / 3600)
            let minutes = Math.floor(duration / 60);
            let seconds = Math.ceil(duration - minutes * 60) - 1

            if(minutes >= 60){
                minutes = minutes - 60
            }

            hour = hour < 10 ? '0' + hour : hour



            this.setState({
                progressWidth: audio.currentTime / audio.duration * 100,
                duration: `${hour < '01' ? '' : hour + ':'}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`,
            })
        }
        if (this.state.playPause === 'play_arrow') {
            this.setState({ playPause: 'pause' }, () => {
                audio.play()
            })
        } else {
            this.setState({ playPause: 'play_arrow' }, () => {
                audio.pause()
            })
        }
        audio.onended = () => {
            audio.currentTime = 0
            
            if(this.state.meditatie.content_loop !== '1'){
                this.setState({ playPause: 'play_arrow' }, () => {
           
                })
            }else{
                    audio.play();
            }


          
             
        
        
          
        }

    }

    showCoords = (ev) => {
        let div = ev.clientX;
     
        let offset = div - document.getElementById('bar').offsetLeft



        let bar_width = document.getElementById('bar').clientWidth


        //let coords = "X coords: " + offset + "si sunt la: " + Math.floor((offset / bar_width * 100))



        this.setState({ progressWidth: Math.floor((offset / bar_width * 100)) }, () => {
            this.getAudioTime()
        })

    }

    setVolume = (ev) => {
        let div = ev.clientX

        let offset = div - document.getElementById('meditatii-player-volume').offsetLeft
        let bar_width = document.getElementById('meditatii-player-volume').clientWidth

       

        let vWidth = Math.floor((offset / bar_width * 100))

        if (vWidth < 25) {
            this.setState({
                bar1: 'block',
                bar2: 'none',
                bar3: 'none',
                bar4: 'none'
            }, () => {
                audio.volume = 0
            })
        } else if (vWidth > 25 && vWidth < 50) {
            this.setState({
                bar1: 'block',
                bar2: 'block',
                bar3: 'none',
                bar4: 'none'
            }, () => {
                audio.volume = 0.25
            })
        } else if (vWidth > 50 && vWidth < 75) {
            this.setState({
                bar1: 'block',
                bar2: 'block',
                bar3: 'block',
                bar4: 'none'
            }, () => {
                audio.volume = 0.5
            })
        } else {
            this.setState({
                bar1: 'block',
                bar2: 'block',
                bar3: 'block',
                bar4: 'block'
            }, () => {
                audio.volume = 1
            })
        }

    }



    getAudioTime = () => {

        // let duration = audio.duration

        audio.currentTime = Math.floor((audio.duration * this.state.progressWidth) / 100)
        audio.ontimeupdate = () => {
            let duration = audio.duration - audio.currentTime

            let hour = Math.floor(duration / 3600)
            let minutes = Math.floor(duration / 60);
            let seconds = Math.ceil(duration - minutes * 60) - 1

            if(minutes >= 60){
                minutes = minutes - 60
            }

            hour = hour < 10 ? '0' + hour : hour

            
        
            

            this.setState({
                progressWidth: audio.currentTime / audio.duration * 100,
                duration: `${hour < '01' ? '' : hour + ':'}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`,
        },()=>{
            
        })
        }

    }

    getSrc = (src) => {

        audio.setAttribute("src", src)
  
    }

    fbComment = (d, s, id) => {

        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';
        fjs.parentNode.insertBefore(js, fjs);


    }



    getMeditatie = async () => {
      

        let getMed = await fromServer('content', {mode:'content_item', id_content:this.props.match.params.idmeditatie, id_user:getLocalUserData().idul, id_sesiune: getLocalUserData().id_sesiune}, {credentials:'include'})



        if (getMed.r === 'ok') {

            this.setState({ meditatie: getMed.continut[0] }, () => {
                this.getStarsWidth()
                this.getSrc(imgUpload(this.state.meditatie.cale_audio, "audio"))
                audio.onloadedmetadata = () => {

                    let hour = Math.floor(audio.duration / 3600)
                    let minutes = Math.floor(audio.duration / 60);
                    let seconds = Math.ceil(audio.duration - minutes * 60) - 1

                    if(minutes >= 60){
                        minutes = minutes - 60
                    }

                    hour = hour < 10 ? '0' + hour : hour

                  
                    this.setState({
                        duration: `${hour < '01' ? '' : hour + ':'}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`,
                        staticDuration: `${hour < '01' ? '' : hour + ':'}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
                        // `${hour < '01' ? '' :( hour > '01' ? hour + ' ore ' : 'O ora ' ) }${minutes < 10 ? '0' + minutes : minutes} minute si ${seconds < 10 ? '0' + seconds : seconds} secunde`
                    })
                }
            })


        }else{
            this.setState({alertO:true})
        }
       
    }


    

    starHover=(ev)=>{
        let client = ev.clientX
        let starsLeft = client - document.getElementById('meditatie-stars-outer').offsetLeft
        let starsWidth = document.getElementById('meditatie-stars-outer').clientWidth

        let starsInner = document.getElementById('meditatie-stars-inner')

       

        let starsWidthNow = starsLeft / starsWidth * 100

     

       starsInner.style.width = starsWidthNow + '%'



     
    }

    starOut = ()=>{
        let starsInner = document.getElementById('meditatie-stars-inner')

        let starPercentage = (parseFloat(this.state.meditatie.rating)/ 5) *100;
        let mathRound = `${(Math.round(starPercentage/10)*10)}%`
        starsInner.style.width =  mathRound
    }

    getStarsWidth=()=>{
        let starsInner = document.getElementById('meditatie-stars-inner')

  

        let starPercentage = (parseFloat(Number(this.state.meditatie.rating))/ 5) *100;

      
        let mathRound = `${(Math.round(starPercentage/10)*10)}%`
        starsInner.style.width =  mathRound
       
       
        
    }

    clientRating=async(ev)=>{
        let client = ev.clientX
        let starsLeft = client - document.getElementById('meditatie-stars-outer').offsetLeft
        let starsWidth = document.getElementById('meditatie-stars-outer').clientWidth

       

      
        let starsWidthNow = starsLeft / starsWidth * 100
         starsWidthNow = (starsWidthNow / 2) /10 < 1 ? 1 : (starsWidthNow / 2) /10
       
        let meditatie={...this.state.meditatie}
       
        
        
        meditatie.rating = Math.ceil(starsWidthNow)

        

        let updateRating = await fromServer('rating', {mode:'insert_rating', 
                                                        id_content:this.props.match.params.idmeditatie,
                                                    id_user:getLocalUserData().idul,
                                                    vote:meditatie.rating}, {credentials:'include'})
      

        if(updateRating.r === 'ok'){

            this.setState({meditatie:meditatie},()=>{
                this.getStarsWidth()
            })
        }

    }

    goBack = () =>{
        this.props.history.goBack()
    }

    getFavorites = async()=>{


        let fav = await fromServer('favorites', {mode:'get_user_favorites', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune}, {credentials:'include'})

       

        if(fav.r==='ok'){
            let favorite = [...fav.continut]

          

         let idx = favorite.findIndex(f=>f.id_content=== this.props.match.params.idmeditatie)

         if(idx !== -1){
             this.setState({starIcon : 'favorite'})
         }else{
            this.setState({starIcon : 'favorite_border'})
         }

         
        }

  
    }

    addToFavorites = async()=>{

        if(this.state.starIcon === 'favorite_border'){

            let addToFavorites = await fromServer('favorites', {mode:'add_favorite', 
                                                                id_user: getLocalUserData().idul,
                                                                id_sesiune:getLocalUserData().id_sesiune,
                                                            id_content: this.props.match.params.idmeditatie}, {credentials:'include'})
            
                 
            
                  
            
                    if(addToFavorites.r==='ok'){

                        this.setState({ starIcon: "favorite"  }) 
                    }
            


        }else{

            let addToFavorites = await fromServer('favorites', {mode:'remove_favorite', 
                                                                    id_user: getLocalUserData().idul,
                                                                    id_sesiune:getLocalUserData().id_sesiune,
                                                                id_content: this.props.match.params.idmeditatie}, {credentials:'include'})

           

            
            
            if(addToFavorites.r==='ok'){

                this.setState({ starIcon: "favorite_border"  }) 
            }
        }


    }
    render() {
        return (
            <div onContextMenu={this.disableRightClick}>

            <Alert
                 open={this.state.alertO}
                 dialogClose={()=>{this.props.history.push('/meditatii')}}
                 title='Atentie !'
                 mesaj='Nu aveti acces la aceasta meditatie !'/>
                
                <AppBar history={this.props.history} />
                <div className="meditatii-back-button" onClick={() => { this.goBack() }}><i className="material-icons meditatii-back-button-icon">reply</i></div>
                <div className="meditatii-section-container">

                    <Grid container className="meditatii-meditatie-container" style={{ marginBottom: 50 }}>

                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={5} lg={5} xl={5} >

                            <img alt="meditatie-asculta-background" className="logo-img" id='meditatii-background-img' style={{ width: '100%', borderRadius:20 }} src={this.state.meditatie.cale_poza_background !== null ? imgUpload(this.state.meditatie.cale_poza_background, "images") : defaultImg} />

                            <Player
                                playPauseFunc={this.playPause}
                                playPause={this.state.playPause}
                                showCoords={this.showCoords}
                                progressWidth={this.state.progressWidth}
                                setVolume={this.setVolume}

                                bar1={this.state.bar1}
                                bar2={this.state.bar2}
                                bar3={this.state.bar3}
                                bar4={this.state.bar4}
                                duration={this.state.duration} />






                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <h1 className="meditatii-meditatie-title" style={{marginBottom:2}} >{this.state.meditatie.nume} <i onClick={() =>{this.addToFavorites()} } className="material-icons meditatii-fav-icon">{this.state.starIcon}</i></h1>
                            <p className="meditatii-section-paragraph meditatii-content-name" style={{ textAlign: 'left', marginBottom: 10, color:'#7a80dc',cursor:'pointer', fontSize:18 }} onClick={()=>{this.props.history.push(`/autor/${this.state.meditatie.id_autor}`)}}>{this.state.meditatie.nume_autor}</p>
                            <p className="meditatii-section-paragraph" style={{ textAlign: 'left', marginBottom: 0 }}>Durata: {this.state.staticDuration}</p>
                            <div style={{textAlign: 'left'}}>

                            <p className="meditatii-footer-copyright-links" style={{ marginBottom: 10, cursor: "pointer" , display:'inline-block', }}>Evalueaza:</p>
                            <div className="stars-outer" id='meditatie-stars-outer'onMouseOver={this.starHover} onMouseMove={this.starHover} onMouseOut={this.getStarsWidth} onClick={this.clientRating}>
                                <div className="stars-inner" id='meditatie-stars-inner' ></div>
                            </div>
                            </div>
                            <p className="meditatii-section-paragraph" style={{ textAlign: 'left' }}>{this.state.meditatie.descriere}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>

                        </Grid>

                    </Grid>
                    <div id="fb-root"></div>
                    <div className="fb-comments" data-href={this.state.dataHref} style={{ maxWidth: 1200, width: "100%" }} data-width="1200" data-numposts="5"></div>
                </div>

            </div>
        );
    }
}

;