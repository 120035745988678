import React from 'react';
import AppBar from '../../Componente/Comune/AppBar';
import { Grid, Card, Divider } from '@material-ui/core'
import { arataData } from '../../Providers/utile';
import { fromServer } from '../../Providers/ws';

export default class DetaliiTranzactie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tranzactie: [
                {
                    id_tranzactie: '',
                    data_tranzactie: '',
                    total: ''
                }
            ]
        }
    }

    componentDidMount() {
        this.getTranzactie()
    }
    getTranzactie = async () => {
        let tranzactie = await fromServer('transactions', {
            mode: 'get_transaction',
            id_tranzactie_head: this.props.match.params.idtranzactie
        }, { credentials: 'include' })

       

        if (tranzactie.r === 'ok') {
            this.setState({ tranzactie: tranzactie.continut })
        }
    }
    render() {
        return (
            <div>
                <AppBar history={this.props.history} />
                <div className="meditatii-back-button" onClick={() => { this.props.history.goBack() }}><i className="material-icons meditatii-back-button-icon">reply</i></div>
               
                <div className="meditatii-section-container">
                    <h3 className='meditatii-comenzile-mele-title'>Comanda nr. {this.state.tranzactie[0].id_tranzactie}</h3>
                    <Card style={{ maxWidth: '90%', margin: '10px auto' }}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <p className='meditatii-tranzactii-subtitle' style={{ marginBottom: 0, marginTop: 15 }}>Plasata pe: {arataData(this.state.tranzactie[0].data_tranzactie)}</p>
                                <p className='meditatii-tranzactii-subtitle'>Total : {this.state.tranzactie[0].total} lei</p>
                            </Grid>



                        </Grid>
                    </Card>
                    <h3 className='meditatii-comenzile-mele-title'>Produse vandute:</h3>
                    <Card style={{ maxWidth: '90%', margin: '10px auto' }}>

                        {this.state.tranzactie.map((t, idx) => {
                            return (
                                <div key={idx}>
                                    <Grid container>
                                        <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>

                                            <p className='meditatii-tranzactii-subtitle' style={{ marginBottom: 0, marginTop: 23, color:'#252f4e' }}> Abonament {t.nume_abonament}</p>

                                        </Grid>
                                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>

                                            <p className='meditatii-tranzactii-subtitle' style={{ marginBottom: 0, marginTop: 15, textAlign:'right', paddingRight:10 }}>{t.pret} lei</p>
                                            <p className='meditatii-tranzactii-subtitle' style={{textAlign:'right', paddingRight:10}}>{t.cantitate} buc</p>
                                        </Grid>



                                    </Grid>
                                    <Divider />

                                </div>

                            )
                        })}
                            <p className='meditatii-tranzactii-subtitle' style={{textAlign:'right', paddingRight:10, color:'#252f4e', marginTop: 15,}}>Total: {this.state.tranzactie[0].total} lei</p>
                    </Card>
                </div>
            </div>
        );
    }
}

;