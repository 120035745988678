import React from 'react';


import AppBar from '../../Componente/Comune/AppBar'

import {Grid, FormControl, Input, InputLabel, Button} from '@material-ui/core'
import { fromServer } from '../../Providers/ws';
import { getLocalUserData } from '../../Providers/ls';

export default class InfoAutor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            counterTime:4,
            autor:{}
        }
    }

    componentDidMount(){
        this.verifyAdmin()
        this.scrollTop()
    }

    verifyAdmin = async()=>{
        
        let server = await fromServer('users',{mode:'check_if_admin', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune}, {credentials:'include'})

        if(server.r === 'ok'){

            this.getAutor()
            if(this.state.techDebug === 1){
       
            }
        }else{
            this.props.history.push('/')
        }
    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    textHandle = camp => ev => {
        let oldState = this.state.autor
        oldState[camp] = ev.target.value

        this.setState({ autor: oldState })
    }

    getAutor=async()=>{
        let autor = await fromServer('author', {mode:'get_author',
                                                id_author:this.props.match.params.idautor}, {credentials:'include'})

        if(autor.r === 'ok'){
            this.setState({autor:autor.continut[0]})
        }
    }

    updateAutor=async()=>{
        let updateAutor = await fromServer('author',{mode:'update_author',
                                                     id_author:this.props.match.params.idautor,
                                                    author_name:this.state.autor.nume,
                                                    description:this.state.autor.descriere,
                                                    website:this.state.autor.website},{credentials:'include'})

              if(updateAutor.r==='ok'){
                  this.timeOutEdit()
              }                                      
    }

    intervalTime = () => {
        let newCount = this.state.counterTime - 1

        if (this.state.counterTime > 0) {
            this.setState({ counterTime: newCount }, () => {

            })
        } else {
            clearInterval(this.state.intervalId)
        }
    }
    timeOutEdit = () => {
        this.setState({
            intervalId: setInterval(this.intervalTime, 1000),
            timeOut: setTimeout(() => {


                this.setState({
                    counterTime: 4,
                }, () => {
                    clearTimeout(this.state.intervalId)
                })
            }, 4000)

        })


    }

    render() {
        return (
            <div>
                <AppBar history={this.props.history} />
                <div className="meditatii-back-button" onClick={() => { this.props.history.goBack() }}><i className="material-icons meditatii-back-button-icon">reply</i></div>
                <h1 className="section-title" style={{ marginBottom: 10 }}>{this.state.autor.nume}</h1>
                <div className="meditatii-section-container">
                <Grid container className="meditatii-subsection-container" style={{ marginTop: 80 }}>
                    <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>



                        <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl className="meditatii-text-field"  >
                                <InputLabel style={{ color: "#bfbfbf" }} htmlFor="autor-nume">Nume</InputLabel>
                                <Input
                                    disableUnderline={true}
                                    id="autor-nume"
                                    type='text'
                                    value={this.state.autor.nume || ""}
                                    onChange={this.textHandle('nume')}

                                />
                            </FormControl>

                        </Grid>
                        </Grid>

                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>



                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="autor-website">Website</InputLabel>
                                    <Input
                                        disableUnderline={true}
                                        id="autor-website"
                                        type='text'
                                        value={this.state.autor.website || ""}
                                        onChange={this.textHandle('website')}

                                    />
                                </FormControl>

                            </Grid>
                        </Grid>
                        <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>



                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl className="meditatii-text-field"  >
                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="autor-descriere">Descriere</InputLabel>
                                    <Input
                                        disableUnderline={true}
                                        multiline
                                        id="autor-descriere"
                                        type='text'
                                        value={this.state.autor.descriere || ""}
                                        onChange={this.textHandle('descriere')}

                                    />
                                </FormControl>
                                <Button style={{ color: 'darkorange' }} color="primary" className='meditatii-autentificare-button' onClick={this.updateAutor} >
                                Salveaza Content
                                </Button>
                            <span style={{ display: this.state.counterTime < 4 ? 'block' : 'none', fontSize: 14, textAlign: 'center', color: '#0dd00d' }} >Content actualizat cu succes</span>
                       
                            </Grid>
                        </Grid>
                   
                </Grid>
                </div>
            </div>
                );
            }
        }
        
;