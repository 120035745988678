import React, { Component } from 'react';
import './App.css';
import './casa-meditatiilor-main-css.css'
import { HashRouter, Route, Switch} from 'react-router-dom';


import Home from './Module/Acasa/Home';
import DeCeSaMeditezi from './Module/DeCeSaMeditezi/DeCeSaMeditezi';
import FooterContainer from './Componente/Comune/FooterContainer'
import Login from './Module/Login/Login';
import Inregistreaza from './Module/Inregistreaza/Inregistreaza';
import Meditatii from './Module/Meditatii/Meditatii';
import Blog from './Module/Blog/Blog';
import DespreNoi from './Module/DespreNoi/DespreNoi';

import history from './History'


import Contact from './Module/Contact/Contact';
import Confidentialitate from './Module/Confidentialitate/Confidentialitate';
import TermeniSiConditii from './Module/TermeniSiConditii/TermeniSiConditii';
import ContulMeu from './Module/ContulMeu/ContulMeu';
import PoliticaCookie from './Module/PoliticaCookie/PoliticaCookie';
import Abonamente from './Module/Abonamente/Abonamente';
import Abonament from './Module/Abonamente/Abonament';
import ResetareParola from './Module/ResetareParola/ResetareParola';
import Administrator from './Module/Admin/Admin';
import Clienti from './Module/Admin/Clienti';
import InfoClient from './Module/Admin/InfoClient';
import Meditatie from './Module/Meditatii/Meditatie';
import Content from './Module/Admin/Content';
import AudioContent from './Module/Admin/ContentInfoComponents/AudioContent';
import Categorii from './Module/Admin/Categorii';
import Programe from './Module/Admin/Programe';
import Program from './Module/Admin/Program';
import ProgramListare from './Module/Meditatii/ProgramListare';
import Ratings from './Module/Admin/Ratings';
import Autori from './Module/Admin/Autori';
import InfoAutor from './Module/Admin/InfoAutor';
import Autor from './Module/Meditatii/Autor';
import DetaliiTranzactie from './Module/ContulMeu/DetaliiTranzactie';
import CitateZilnice from './Module/CitateZilnice/CitateZilnice';
import Categorie from './Module/Admin/Categorie';
import AchitaComanda from './Module/Abonamente/AchitaComanda';
import Subscribe from './Module/Subscribe/Subscribe';
import ReactGA from 'react-ga';





class App extends Component {
constructor(props){
  super(props);
  this.state={
    blogAddr:''
  }
}
  

  componentDidMount(){
 
    this.initReactGa()

  }

  componentWillMount(){
   // this.getBlogAddr()
  }

  initReactGa = () =>{
    ReactGA.initialize('UA-142987961-1');
    ReactGA.pageview('/');
  }

//   getBlogAddr = async () =>{

//     let addr = await fromServer('blog',{mode:'get_blog_address'}, {credentials:'include'})

   

//         setBlogAddr(addr)
    
// }

 

 

  render() {
    return (
      <div className="App">

        <HashRouter>
          <Switch>
            <Route exact path="/" component={Home} history={history} />
            <Route exact path="/decesameditam" component={DeCeSaMeditezi} history={history} />
          
            
            <Route exact path="/blog" component={()=> <Blog history={history} />} history={history} defaultAddress={this.getBlogAddr} />
            
            <Route exact path="/meditatii" component={Meditatii} history={history} />
            <Route exact path="/inregistreazate/:fbcode" component={Inregistreaza}  history={history} />
            <Route exact path="/autentificare/:fbcode" component={Login} history={history} />
            <Route exact path="/desprenoi" component={DespreNoi} history={history} />
            <Route exact path="/contact" component={Contact} history={history} />
            <Route exact path="/confidentialitate" component={Confidentialitate} history={history} />
            <Route exact path="/termenisiconditii" component={TermeniSiConditii} history={history} />
            <Route exact path="/politicacookie" component={PoliticaCookie} history={history} />
            <Route exact path="/abonamente" component={Abonamente} history={history} />
            <Route exact path="/contulmeu" component={ContulMeu} history={history} />
            <Route exact path="/abonament" component={Abonament} history={history} />
            <Route exact path="/resetare/:token" component={ResetareParola} history={history} />
            <Route exact path="/administrator" component={Administrator} history={history} />
            <Route exact path="/adminclienti" component={Clienti} history={history} />
            <Route exact path="/infoClient/:idclient" component={InfoClient} history={history} />
            <Route exact path="/meditatie/:idmeditatie/" component={Meditatie} history={history} />
            <Route exact path="/admincontent" component={Content} history={history} />
            <Route exact path="/audiocontent/:idmeditatie" component={AudioContent} history={history} />
            <Route exact path="/admincategorii" component={Categorii} history={history} />
            <Route exact path="/adminprograme" component={Programe} history={history} />
            <Route exact path="/adminprogram/:idprogram" component={Program} history={history} />
            <Route exact path="/program/:idprogram" component={ProgramListare} history={history} />
            <Route exact path="/ratings" component={Ratings} history={history} />
            <Route exact path="/autori" component={Autori} history={history} />
            <Route exact path="/autorinfo/:idautor" component={InfoAutor} history={history} />
            <Route exact path="/autor/:idautor" component={Autor} history={history} />
            <Route exact path="/tranzactie/:idtranzactie" component={DetaliiTranzactie} history={history} />
            <Route exact path="/citatezilnice" component={CitateZilnice} history={history} />
            <Route exact path="/categorie/:idcategorie" component={Categorie} history={history} />
            <Route exact path="/achitacomanda/:idtranzactie" component={AchitaComanda} history={history} />
            <Route exact path="/subscribe" component={Subscribe} history={history} />

          </Switch>
        </HashRouter>
          <FooterContainer history={history} articol={this.getIframeAddress}/>
        
      </div>
    );
  }
}


export default App;
