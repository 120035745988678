import React from 'react';
import {ListItem, ListItemText, Divider} from '@material-ui/core'



export default class LiMeditatii  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
         
          }
    }


    infoContent =()=>{
        if(this.props.content.tip_content === 'audio'){
            this.props.history.push(`/audiocontent/${this.props.content.idul}`)
        }
    }

  

    render() { 
        return ( 
            <div style={{maxWidth:800,margin:"auto"}}>
            <ListItem style={{backgroundColor:this.props.content.ascuns !== 1 ? '' : '#ecc9d1'}}  button>
            <ListItemText onClick={this.infoContent} primary={<h3 className="meditatii-admin-clienti-list-text">{this.props.index + 1} - {this.props.content.nume}</h3>}
                          secondary={<span className="meditatii-list-admin-label">Categorie: <span className='meditatii-list-admin-label-info'>{this.props.content.nume_categorie}</span></span>}
                                    />
            
            
        </ListItem>
        <Divider/>
        </div>
         );
    }
}
 
 ;