import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import { Grid, Button, FormControl, InputLabel, Input, InputAdornment, IconButton, Card } from '@material-ui/core'
import meditatiiAcasa from '../Pics/meditatii-acasa-min.jpg'
import { fromServer } from '../../Providers/ws';
import Alert from '../../Componente/Comune/Alert'
import FacebookLogin from 'react-facebook-login'
import radioLive from '../Pics/radioLive.png'


export default class Inregistreaza extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            nume: '',
            mail: '',
            parola: '',
            vUserSiMail: '',
            vTrimiteMail:'',
            openAlert:false
        }
    }


    componentDidMount() {
        // window.fbAsyncInit = async() =>{
        //     FB.init({
        //      // appId:'268302460534860',
        //       appId:'279709906062725',
        //       autoLogAppEvents : true,
        //       xfbml            : true,
        //       version          : 'v3.2'
        //     });
            
        //   };
        //   (function(d, s, id){
        //   var js, fjs = d.getElementsByTagName(s)[0];
        //   if (d.getElementById(id)) return;
        //   js = d.createElement(s); js.id = id;
        //   js.src = 'https://connect.facebook.net/en_US/sdk.js';
        //   fjs.parentNode.insertBefore(js, fjs);
        // }(document, 'script', 'facebook-jssdk'))
        this.scrollTop()

        this.facebookMobileLogin()
    }



    facebookMobileLogin = async () => {
        if(this.props.match.params.fbcode !== '0'){
            let addr = `https://graph.facebook.com/v3.2/oauth/access_token?client_id=268302460534860&redirect_uri=https://casameditatiilor.ro/facebookLogin.html&code=${this.props.match.params.fbcode}&client_secret=be76056633cafc5353b3e080676db0a2&scope=email,public_profile`
        addr = encodeURI(addr)
    
    
    
        let r = await fetch(addr)

    
         if (r.ok) {

                 let js = await r.json();


                     let addr3 = `https://graph.facebook.com/v3.2/me?access_token=${js.access_token}&fields=email,name`
         addr3 = encodeURI(addr3)

         let r3 = await fetch(addr3)

                  

            if(r3.ok){
               let js3 = await r3.json()

               this.fblogin(js3)

        
            }
          
            
    

            return new Error(`Eroare primire date: ${js.m}`)
        }
        else {
            throw new Error(r.statusText);
        }
        }

     
        
    }



   responseFacebook = (r)=>{
   
      this.fblogin(r)
   }


    fblogin= async(r)=>{
        // let url = '/me?fields=name,email';

        // let email = ''
        // FB.login((r)=>{
        //     FB.api(url,async (re) =>{
               
        //      email= re.email

      

        if(r.email){

            let fbLogin = await fromServer('insert_account',{mode:'insert_fb_user', 
                                                           email:r.email,
                                                           name:r.name }, {credentials:'include'})
    
     
    
                if(fbLogin.r === 'ok'){
                //    let user = {
                //        esteLogat: true,
                //        fbLogin:true,
                //        idul: fbLogin.id_introdus,
                //        email: r.email,
                //        nume:r.name,
                //        nume_utilizator: r.email,
                //        label_tip_cont:'free',
                //        data_creare_cont:defaultData(),
                //        admin:0,
                //        blocat:0    
                //    }
      
              
             
      
                  this.props.history.push("/autentificare/0")
                }else{
                   this.setState({openAlert:true,
                                   alertMesaj:fbLogin.mesaj})
               }
        }
          
            
            
      
     
    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    inregistreazaTe = async () => {
      this.setState({vTrimiteMail:'Va rugam asteptati....'})

            let inregistreazaTe = await fromServer('insert_account',{username:this.state.user,
                                                                    email:this.state.mail,
                                                                    password:this.state.parola, 
                                                                    name:this.state.nume,
                                                                    mode:'insert_user'}, {credentials:'include'})

         

            if (inregistreazaTe.r === 'ok') {

                
            
                // let user = {
                //     esteLogat: true,
                //     fbLogin:false,
                //     idul: inregistreazaTe.id_introdus,
                //     email: this.state.mail,
                //     nume_utilizator: this.state.user,
                //     label_tip_cont:'free',
                //     data_creare_cont:defaultData(),
                
                //     admin:0,
                //     blocat:0,

                // }

            
             

                this.props.history.push("/autentificare/0")
            }
            else {
                this.setState({ vUserSiMail: inregistreazaTe.mesaj,
                    vTrimiteMail:'',
                                user: '',
                                nume: '',
                                mail: '',
                                parola: '',
                                })
            }

          
        
    }


    render() {
        return (
            <div>
                <AppBar history={this.props.history} />
                <Alert 
                    open={this.state.openAlert}
                    dialogClose={()=>{this.setState({openAlert:false})}}
                    title='Atentie'
                    mesaj={this.state.alertMesaj}/>
                <div style={{ backgroundImage: `url(${meditatiiAcasa})`, paddingTop: 10, backgroundRepeat:'no-repeat', backgroundSize:'cover', position:'relative' }}>
                <a id='meditatii-redirect-link' href="https://www.youtube.com/channel/UCPMpZtCjoZ2HvxUmMe9d5PA" target='_blank'  rel='noopener noreferrer'>
                        <div  className='meditatii-radio-live' style={{backgroundImage:`url(${radioLive})`}}>
                            
                        </div>
                        </a>
                    <h1 className="meditatii-paralax-content-title " style={{ marginTop: 40 }}>CREEAZ&#258; CONT GRATUIT</h1>
                    <h2 className="mediteaza-register-text" style={{ color: "white" }}>&#350;i ai access la cele 20 de medita&#355;ii gratuite!</h2>

                    <div className="meditatii-section-container" style={{backgroundColor:'transparent'}}>
                        <Grid container className="meditatii-subsection-container" style={{ marginTop: 80 }}>
                            <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>

                                <Card style={{paddingBottom:30, borderRadius: 20 }}>

                                    <Grid style={{marginTop:20}} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormControl className="meditatii-text-field"  >
                                            <InputLabel style={{color:"#bfbfbf"}} htmlFor="meditatii-user">Nume Utilizator</InputLabel>
                                            <Input
                                               disableUnderline={true}
                                                id="meditatii-user"
                                                type='text'
                                                value={this.state.user}
                                                onChange={(ev) => { this.setState({ user: ev.target.value, vUserSiMail:'' }) }}
                                               
                                            />
                                        </FormControl>

                                    </Grid>
                                    <Grid style={{marginTop:20}} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormControl  className="meditatii-text-field" >
                                            <InputLabel style={{color:"#bfbfbf"}} htmlFor="meditatii-email">Email</InputLabel>
                                            <Input
                                               disableUnderline={true}
                                                id="meditatii-email"
                                                type='email'
                                                value={this.state.mail}
                                                onChange={(ev) => { this.setState({ mail: ev.target.value,vUserSiMail:'' }) }}
                                                
                                            />
                                        </FormControl>

                                    </Grid>
                                    <Grid  style={{marginTop:20}} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl className="meditatii-text-field"  >
                                            <InputLabel style={{color:"#bfbfbf"}} htmlFor="meditatii-parola">Parola</InputLabel>
                                            <Input
                                               disableUnderline={true}
                                                id="meditatii-parola"
                                                type={this.state.showPassword ? 'text' : 'password'}
                                                value={this.state.password}
                                                onChange={(ev) => { this.setState({ parola: ev.target.value,  }) }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                         style={{color:"#bfbfbf", border:'none'}}
                                                            
                                                            onClick={() => { this.setState({ showPassword: this.state.showPassword === true ? false : true }) }}
                                                        >
                                                           <i className="material-icons">{this.state.showPassword ? "visibility" : "visibility_off"}</i>
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <span className="meditatii-verify-span">{this.state.vUserSiMail}</span>
                                    </Grid>
                                    <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <span className="meditatii-success-message">{this.state.vTrimiteMail}</span>
                                        <Button className="meditatii-autentificare-button" variant="contained" onClick={this.inregistreazaTe}>
                                            Inregistrare
                                 </Button>
                                 <h2 className="meditatii-subsection-meditezi-title">sau</h2>
                                 <FacebookLogin
                                 cssClass='meditatii-autentificare-button-facebook'
                                 style={{}}
                                    appId="268302460534860"
                                    textButton="INREGISTREAZ&#258;-TE CU FACEBOOK"
                                    redirectUri={'https://casameditatiilor.ro/facebookLogin.html'}
                                    autoLoad={false}
                                    fields='name,email'
                                    onClick={this.componentClicked}
                                    state='inregistrare'
                                    scope="email"
                                    callback={this.responseFacebook}
                                    icon={<i className='fab fa-facebook' style={{fontSize:25, marginRight:15, verticalAlign:'sub'}}></i>}/>
                                 {/* <Button className="meditatii-autentificare-button-facebook"  variant="contained" onClick={this.fblogin}>
                                 <i className='fab fa-facebook' style={{fontSize:31, marginRight:15}} ></i>   Inregistreaza-te cu Facebook
                                 </Button> */}
                             
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>

                    </div>
                </div>

            </div>
        );
    }
}

;