import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import {Grid, Dialog, DialogActions, DialogContent, FormControl, InputLabel, Input, List, Button} from '@material-ui/core'
import {  fromServer } from '../../Providers/ws';

import LiPrograme from './LiPrograme'
import { getLocalUserData } from '../../Providers/ls';

export default class Programe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disableInsert:true,
            openAdd:false,
            programe:[],
            cauta:'',
            program:''
        }
    }

    componentDidMount() {
       this.verifyAdmin()
       this.scrollTop()
    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    verifyAdmin = async()=>{
        
        let server = await fromServer('users',{mode:'check_if_admin', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune}, {credentials:'include'})

        if(server.r === 'ok'){

            this.getPrograme()
            if(this.state.techDebug === 1){
            
         
            }
        }else{
            this.props.history.push('/')
        }
    }

    getPrograme = async () =>{

        let programe = await fromServer('programs', {mode:'programs_list_admin'}, {credentials:'include'} )
  


       
        if(programe.r === 'ok'){
            this.setState({programe:programe.continut})
        }
    } 

    closeAdd=()=>{
        this.setState({openAdd:false})
    }

    search = () => {
        let content = []

        console.log('this.state.content :', this.state.content);
       // content = this.state.programe.filter(c => (c.nume_program.toLowerCase().includes(this.state.cauta.toLowerCase())))
        content = this.state.programe.filter((c) => {return c.nume_program.toLowerCase().indexOf(this.state.cauta.toLowerCase()) !== -1 })
       
        return (

            content.map((c, idx) => {
                return (
                    <LiPrograme key={idx} index={idx} content={c} history={this.props.history} changeStatus={this.changeStatus} getInfo={this.getInfo}/>

                )
            })

        )

    }


    changeStatus=(cat, nr)=>{
        let cloneCat = [...this.state.programe]
        let idx = cloneCat.findIndex(c=>c.idul === cat.idul)
    

        cloneCat[idx].ascuns = nr

        this.setState({programe:cloneCat})
    }

    getInfo=(c)=>{
        this.props.history.push(`/adminprogram/${c.idul}`)
    }

    insertProgram = async()=>{


        let programNou = await fromServer('programs',{mode:'insert_program', program_name:this.state.program}, {credentials:'include'})
       

        if(programNou.r ==='ok'){
      
           this.props.history.push(`/adminprogram/${programNou.id_introdus}`)
        }
    }


    render() {
        return (
            <div>
                <AppBar history={this.props.history} />
                <Dialog

                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.openAdd}
                    onClose={this.closeAdd}
                >

                    <DialogActions style={{ justifyContent: 'center' }}>

                        <Button className='meditatii-close-dialog-button' onClick={this.closeAdd} variant="fab">
                            <i className='material-icons'>clear</i>
                        </Button>

                    </DialogActions>


                    <h2 style={{ padding: '20px 10px 0 10px', marginTop: 20, textAlign: 'center' }} className="meditatii-section-meditezi-title">
                      Adauga Program
                    </h2>

                    <DialogContent style={{ textAlign: 'center' }}>

                        <FormControl className="meditatii-text-field" style={{ width: "100%" }}  >
                            <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-nume-program">Nume Program</InputLabel>
                            <Input
                                disableUnderline={true}
                                id="meditatii-nume-program"
                                type='text'
                                value={this.state.program}
                                onChange={(ev) => {
                                    this.setState({ program: ev.target.value }, () => {
                                        if (this.state.program.length > 2) {
                                            this.setState({ disableInsert: false })
                                        } else {
                                            this.setState({ disableInsert: true })
                                        }
                                    })
                                }}


                            />
                        </FormControl>



                        <Button disabled={this.state.disableInsert} style={{ marginTop: 20 }} variant='contained' color="primary" className={this.state.disableInsert === true ? "" : 'meditatii-autentificare-button'} onClick={this.insertProgram} >
                           Adauga
                        </Button>

                    </DialogContent>
                </Dialog>
                <div className="meditatii-back-button" onClick={() => { this.props.history.push('/administrator') }}><i className="material-icons meditatii-back-button-icon">reply</i></div>
                <h1 className="section-title" style={{ marginBottom: 10 }} >Programe</h1>
                <Grid container className="meditatii-subsection-container" style={{ marginTop: 10 }}>
                    <Grid style={{ margin: "auto" }} item xs={12} sm={12} md={6} lg={6} xl={6}>

                        <Button style={{ color: 'darkorange' }} color="primary" className='meditatii-autentificare-button' onClick={() => { this.setState({ openAdd: true }) }} >
                            Adauga Program
            </Button>

                        <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl className="meditatii-text-field"  >
                                <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-cauta">Cauta</InputLabel>
                                <Input
                                    disableUnderline={true}
                                    id="meditatii-cauta"
                                    type='text'
                                    value={this.state.cauta}
                                    onChange={(ev) => { this.setState({ cauta: ev.target.value }) }}


                                />
                            </FormControl>

                        </Grid>

                    </Grid>
                </Grid>
                <List style={{ marginBottom: 100, }}>
                    {
                       this.search()
                    }

                </List>

            </div>
         
         );
    }
}

;