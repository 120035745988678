import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'


export default class Confidentialitate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount(){ 
        this.scrollTop()
    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    render() {
        return (
            <div>
                <AppBar history={this.props.history} />
                <div className="meditatii-section-container" >
                    <h1 className="meditatii-section-meditezi-title" style={{ marginTop: 50 }}>POLITIC&#258; DE CONFIDEN&#354;IALITATE </h1>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Pentru noi este important ca tu s&#259; &#351;tii ce date colect&#259;m de la tine, cum le folosim &#351;i &#238;n ce scop. Av&#226;nd &#238;n vedere aceste am&#259;nunte po&#355;i afla &#351;i drepturile tale referitor la informa&#355;iile ce ni le furnizezi sau ce le colect&#259;m &#238;n urma vizitei pe site-ul nostru.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Ca si terminologie, c&#226;nd ne referim la date personale, ne referim de fapt la orice informa&#355;ie despre o persoan&#259; fizic&#259;, date ce pot duce, direct sau indirect, la identificarea persoanei respective. Sunt incluse aici numele si prenumele, informa&#355;ii de localizare, informa&#355;ii despre adres&#259;, date privind locul de conectare la calculator, ip-ul conexiunii &#351;i dispozitivul de pe care se face conexiunea sau informa&#355;iile ce &#355;in de cookie-uri.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Legat de acordul t&#259;u, &#238;n contextul colect&#259;rii &#351;i folosirii datelor tale, am luat toate m&#259;surile tehnice pentru ca tu s&#259; ne po&#355;i oferi acest acord &#238;n deplin&#259; cuno&#351;tin&#355;&#259; de cauz&#259;, de la prima &#351;i p&#226;n&#259; la ultima ta interac&#355;iune cu acest site.</p>
                    <ul className="meditatii-confidentialitate-list">
                        <li>Cine suntem: Casa Namaste S.R.L.</li>
                        <li>Ce date colectezam: colectam datele urmatoare: email, nume</li>
                        <li>De ce colectezam datele: pentru facturare, newsletter, utilizarea contului pe website, crearea unei experiente mai bune la vizitarea website-ului</li>
                        <li>Pentru cat timp pastrezam datele colectate: datele sunt stocate pentru o perioada limitata de durata contului pe website</li>
                        <li>Cum isi pot clientii accesa datele personale: pentru accesarea datelor personale se va trimite o cerere catre noi pe email contact@casameditatiilor.ro</li>
                        <li>Care este procedura pentru eliminarea datelor personale din evidenta (cum putem fi contactati de persoanele care doresc sa faca aceasta solicitare si cum primesc ei dovada actiunii de stergere): solicitarea de stergere se trimite pe email contact@casameditatiilor.ro</li>
                        <li>Ce alte terte parti au acces la datele lor (furnizori servicii email marketing, Analytics, Facebook, firma de contabilitate)</li>
                        <li>Care sunt datele tale de contact: pentru intrebari sau nelamuriri privind datele personale trimiteti un email la contact@casameditatiilor.ro</li>
                    </ul>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Aceasta politica (“Politica de confidentialitate”) descrie practicile cu privire la datele personale pe care le colectam prin Site, care este gazduit si operat din Romania. Va incurajam sa cititi aceasta Politica inainte sa folositi site-ul nostru. Folosind acest Site, sunteti de acord cu termenii si conditiile acestei Politici.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Daca nu sunteti de acord cu acesti termeni si conditii, va rugam nu folositi acest Site.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 40 }}><b>CasaMeditatiilor.ro aduna prin acest Site doua tipuri de informatie aduna doua tipuri de informatie:</b> “Date Personale”, informatie prin care un individ poate fi identificat si “Informatie Agregata”, prin care un individ nu poate fi identificat.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>a) Informatie agregata.</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 40 }}>Cand vizitezi sau interactionezi cu Site-ul si tertele pe care site-ul le-a contractat pentru a-i oferi servicii, pot colecta informatii anonime prin trei surse: fisiere server log, cookies si pixel tags.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>I. Fisiere de pe server.</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Adresa dumneavoastra IP identifica un numar care va este adresat automat computerului dumneavoastra prin ISP (Internet Server Provider). Acest numar este identificat si logat automat in fisierele de server ori de cate ori viziatati site-ul, impreuna cu timpul vizitei si pagina pe care o viziati. Folosim adresa IP pentru a calcula nivelurile de utilizare ale Site-ului, pentru a diagnostica problemele cu serverele si pentru a administra Site-ul. Putem de asemenea sa folosim adresele IP pentru a comunica sau a bloca accesul vizitatorilor ca nu sunt de acord cu Termenii de utilizare. Colectarea adreselor IP este o procedura standard pe Internet si este utilizata automat de multe website-uri. CasaMeditatiilor.ro nu va colecta datele private individualizate, de exemplu, nume, adresa, numar de telefon sau adresa de email (acestea vor fi numite de aici inainte ‘date private’), decat in cazul in care le veti furniza prin dorinta proprie. Daca nu doriti ca datele dumneavoastra sa fie colectate, va vom ruga sa nu ne trimiteti astfel de date. Datele care au fost trimise – in cazul in care nu este specificat altfel – pot fi folosite in urmatorul mod: stocare si procesare pentru o mai buna intelegere a nevoilor dumneavoastra si imbunatatirea produselor si serviciilor noastre; contactarea (in cadrul promotiilor ; aceasta poate fi facuta de catre terte parti care vor actiona conform instructiunilor noastre); inaintarea informatiilor compresate despre utilizatorii sau vizitatorii site-ului nostru – decat exclusiv intr-o forma non – individualizata – catre terte parti. Nu vom vinde, inchiria sau folosi in alte scopuri de marketing datele dumneavoastra private sau cele ale unor terte persoane catre terte parti. CasaMeditatiilor.ro va folosi datele private in conformitate cu aceasta politica.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>Informatii Aditionale Stocate Automat</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>In anumite conditii, informatii tehnice non-individualizate pot fi stocate automat, de exemplu, nu prin inregistrare, ci atunci cand ne vizitezi website-ul. Acest lucru poate fi conectat, de exemplu, cu tipul de Internet Browser pe care il folositi, cu sistemul de operare al calculatorului si cu domeniul website-ului de unde ati intrat pe website-ul nostru.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>II. Cookies</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Cookies sunt fisiere trimise de un server web pe calculatorul unei persoane cu scopul de a arhiva. Cookies sunt standard in schimb, folosite de majoritatea website-urilor si ajuta sa simplifice accesul continuu la un website specific si la utilizarea lui. Cookies nu provoaca nici o dauna sistemului de operare al computerului sau fisierelor si numai website-ul care a trimis un cookie specific poate sa citeasca, sa modifice sau sa stearga cookie-ul. Daca nu doriti ca aceste informatii sa fie stocate prin cookies, majoritatea browserelor au metode simple de a permite stergerea cookies-urilor stocate, respingerea automata a cookies sau selectia dintre acceptari si respingere</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Cookies nu produc daune sistemului instalat pe calculatorul dumneavoastra sau fisierelor si doar website-ul care a trimis acel cookie specific poate citi, modifica sau sterge acel cookie. Daca nu doriti ca aceste informatii sa fie stocate prin intermediul cookies, majoritatea browserelor au metode simple care permit stergerea cookies stocate, respingerea automata a cookies sau selectia intre acceptarea si respingerea unor anumite cookies trimise catre calculatorul dumneavoastra. In orice caz, ar trebui sa stiti ca respingerea cookies ingreuneaza si poate face chiar imposibil pentru dumneavoastra sa « folositi » partia ale unui site.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>III. Informatii Plasate Automat pe Calculatorul dumneavoastra – Taguri Pixel</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 40 }}>Site-ul poate folosi asa-numitele “taguri pixel”, “GIF-uri transparente” sau asemenea ajutoare (numite in mod obisnuit “taguri pixel”) pentru a aduna date statistice de baza asupra folosirii site-ului si a citatelor de reactii. Tagurile Pixel permit unui numar de vizitatori care au vizitat anumite pagini pe site sa fie numarati, sa fie oferite servicii de brand si analizarea eficacitatii promotiei si a campaniilor de publicitate. Cand sunt folosite sub forma unor mesaje pe email formatatate HTML, tagurile pixel pot spune expeditorului daca si cand emailul a fost deschis.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>Newsletter-e sau alte mijloace de comunicare electronice</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 40 }}>Dac&#259; v&#259; &#238;nscrie&#355;i pentru a primi newsletter-ele noastre prin email sau alte materiale promotionale vom utiliza informa&#355;iile pe care ni le-ati furnizat pentru a va oferi ceea ce ati solicitat. Daca ne informati ca vreti sa anulati serviciile de newsletter oferit de noi prin selectarea sectiunii Dezabonare din partea de jos a emailului sau ne itrimiteti un email catre <a className="meditatii-despre-noi-abonament-link" href="mailto:contact@casameditatiilor.ro">contact@casameditatiilor.ro</a>, vom scoate din baza noastra de date adresa dumneavoastra de email.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>Unde stocam informa&#355;iile personale</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Toate informatiile pe care le furniza&#355;i noi prin folosirea serviciilor site-ului sunt stocate pe serverele noastre sigure, localizate &#238;n Romania &#351;i Marea Britanie. Orice opera&#355;iuni de plat&#259; vor fi criptate folosind tehnologia SSL; toate informa&#355;iile de plat&#259; sunt stocate de catre procesatorul nostru de plati &#351;i nu sunt stocate in serverele noastre.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 40 }}>&#238;n cazul &#238;n care v-am dat (sau &#238;n cazul &#238;n care a&#355;i ales) o parol&#259; care v&#259; permite s&#259; accesa&#355;i contul dumneavoastra, sunte&#355;i responsabil pentru p&#259;strarea acestei parole confiden&#355;iale. Noi v&#259; recomandam s&#259; nu spuneti aceasta parola &#351;i va sugeram sa v&#259; schimba&#355;i parola &#238;n mod frecvent.  Din nefericire, transmiterea informa&#355;iilor prin intermediul internetului nu este complet sigura. De&#351;i vom face tot posibilul pentru a proteja informa&#355;iile dumneavoastr&#259; personale, nu putem garanta securitatea informa&#355;iilor transmise; orice transmitere se face pe propriul risc. Odat&#259; ce am primit informa&#355;iile dumneavoastr&#259;, vom utiliza proceduri stricte ed securitate pentru a &#238;ncerca s&#259; prevenim accesul neautorizat.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>Conectare via Facebook (retele sociale)</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 40 }}>V&#259; pute&#355;i conecta la serviciile noastre, folosind diverse metode, cum ar fi Facebook Connect. Aceste servicii vor autentifica identitatea dumneavoastra, si vor oferi posibilitatea de a partaja anumite informa&#355;ii cu caracter personal (cum ar fi numele &#351;i adresa de e-mail) cu noi, eventual sa completeze automat formularul de Creare Cont. Serviciul Facebook Connect v&#259; da posibilitatea de a posta informa&#355;ii despre activit&#259;&#355;ile dumneavoastra pe produsele noastre sau la pagina dumneavoastra de profil pentru a partaja cu al&#355;ii din cadrul re&#355;elei.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>Acces si stergere informatii personale</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Ave&#355;i dreptul s&#259; accesa&#355;i informa&#355;iile personale &#238;n orice moment, prin contul online, sau prin trimiterea unui e-mail la contact@casameditatiilor.ro. Pentru a solicita &#351;tergerea datelor dumneavoastr&#259; personale pe care le avem v&#259; rug&#259;m s&#259; ne trimite&#355;i un email la contact@casameditatiilor.ro. Vom r&#259;spunde solicit&#259;rii &#238;ntr -un interval de timp rezonabil.</p>
                    <p className="meditatii-section-paragraph" style={{ textAlign: "left", maxWidth: "100%", fontSize: 25, marginBottom: 20 }}><b>Confidentialitate</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>www.casameditatiilor.ro utilizeaza metode si tehnologii de securitate avansate, impreuna cu politici stricte aplicate salariatilor si procedurilor de lucru, pentru a proteja datele cu caracter personal, colectate si procesate conform prevederilor legale in vigoare.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Toate materialele si informatiile personale trimise catre website, prin e-mail sau prin intermediul paginilor de Internet, vor fi considerate confidentiale.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Datele personale ale dumneavoastra vor fi folosite numai in scopul declarat al acestui site. Informatiile din formularul de comanda vor fi folosite pentru a va trimite confirmarea comenzilor, eventualele promotii, buletinele informative periodice etc. Ne obligam sa nu facem publice sau sa nu vindem bazele de date continand informatii referitoare la datele personale ale clientilor nostri.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Conform cerintelor Legii nr. 677/2001 pentru protectia persoanelor cu privire la prelucrarea datelor cu caracter personal si libera circulatie a acestor date, modificata si completata si ale Legii nr. 506/2004 privind prelucrarea datelor cu caracter personal si protectia vietii private in sectorul comunicatiilor electronice, Officesupply Distribution SRL-D are obligatia de a administra in conditii de siguranta si numai pentru scopurile specificate datele personale pe care ni le furnizati despre dumneavoastra.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Sunteti obligat sa furnizati datele din formularul de comanda, acestea fiind necesare facturarii si livrarii. Refuzul dvs. determina neonorarea comenzii. Informatiile inregistrate sunt destinate utilizarii de catre operator. Conform Legii nr. 677/2001, beneficiati de dreptul de acces, de interventie asupra datelor, dreptul de a nu fi supus unei decizii individuale si dreptul de a va adresa justitiei. Totodata, aveti dreptul sa va opuneti prelucrarii datelor personale care va privesc si sa solicitati stergerea datelor. Pentru exercitarea acestor drepturi, va puteti adresa cu o cerere scrisa, datata si semnata, pe adresa contact@casameditatiilor.ro.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 10 }}><b>Politica de utilizare Cookie-uri</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Aceasta politica se refera la cookie-urile de pe paginile web operate de Daruri Autentice SRL cu sediul social in Str. Brailei 73, Mineri, Tulcea (denumita in continuare Daruri Autentice).</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>Informatii privind  prezenta cookie-urilor pe un site</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Site-ul www.casameditatiilor.ro utilizeaza cookie-uri.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Informatiile prezentate in continuare au scopul de a va aduce la cunostinta mai multe detalii despre plasarea, utilizarea si administrarea “cookie”-urilor utilizate de site-ul nostru www.casameditatiilor.ro. De asemenea, veti mai gasi si cateva linkuri utile legate de acest subiect. In cazul in care aveti nevoie de mai multe informatii, pe care nu le gasiti mai jos, ne puteti contacta la: <a className="meditatii-despre-noi-abonament-link" href="mailto:contact@casameditatiilor.ro">contact@casameditatiilor.ro</a></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Acest website foloseste cookie-uri proprii si de la terti pentru a furniza vizitatorilor o experienta mult mai buna de navigare si servicii adaptate nevoilor si interesului fiecaruia.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>In ziua de azi cookie-urile joaca un rol important in facilitarea accesului si livrarii multiplelor servicii de care utilizatorul se bucura pe Internet, cum ar fi:</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Personalizarea anumitor setari precum: limba in care este vizualizat un site, moneda in care se exprima anumite preturi sau tarife, pastrarea optiunilor pentru diverse produse (masuri, alte detalii, etc.) in cosul de cumparaturi (si memorarea acestor optiuni) – generandu-se astfel flexibilitatea “cosului de cumparaturi” (accesarea preferintelor vechi prin accesarea butonului “inainte” si “inapoi”).</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Cookie-urile ofera proprietarilor de site-uri un feedback valoros asupra modului in care sunt utilizate site-urile lor de catre utilizatori, astfel incat sa le poata face mai eficiente si mai accesibile pentru utilizatori.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Permit aplicatiilor multimedia sau de alt tip de pe alte site-uri sa fie incluse intr-un anumit site pentru a crea o experienta de navigare mai valoroasa, mai utila si mai placuta;</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Imbunatatesc eficienta publicitatii online.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b> 1. Ce este un cookie?</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Un cookie este un fisier de mici dimensiuni, in general alcatuit din litere si cifre, care este descarcat in memoria unui calculator (sau altui echipament folosit pentru navigare online – telefon mobil, tableta, etc), atunci cand utilizatorul acceseaza un anumit site web.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Cookie-urile sunt create atunci cand browserul folosit de catre un utilizator afiseaza un anumit site web. Site-ul web transmite informatii catre browser, iar acesta creeaza un fisier text. De fiecare data cand utilizatorul acceseaza din nou respectivul site web, browserul acceseaza si transmite acest fisier catre serverul site-ului web. Altfel spus, cookie-ul poate fi vazut ca un card de identificare a utilizatorului de Internet, care anunta site-ul web de fiecare data cand utilizatorul se intoarce pe respectivul site.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b> 2. Scopul cookie-urilor</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Cookie-urile pot asigura o interactiune mai rapida si mai usoara intre utilizatori si site-urile web. Spre exemplu, in momentul autentificarii unui utilizator pe un anumit site web, datele de autentificare sunt stocate intr-un cookie; ulterior, utilizatorul poate accesa respectivul site fara sa fie nevoie sa se autentifice din nou.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>In alte cazuri, cookie-urile pot fi utilizate pentru stocarea de informatii referitoare la activitatile desfasurate de utilizator pe o anumita pagina web, astfel incat acesta sa isi poata relua usor respectivele activitati la o accesare ulterioara a site-ului. Cookie-urile spun serverului ce pagini trebuie sa arate utilizatorului, astfel incat acesta sa nu fie nevoit sa isi aminteasca acest lucru sau sa navigheze intregul site de la inceput. Astfel, cookie-urile pot fi asimilate unor „semne de carte” care ii spun utilizatorului exact unde a ramas in cadrul unui site web.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>In mod similar, cookie-urile pot stoca informatii referitoare la produsele comandate de catre utilizator pe un site de comert electronic, facand astfel posibil conceptul de „cos de cumparaturi”.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>De asemenea, cookie-urile pot oferi site-urilor web posibilitatea de a monitoriza activitatile online ale utilizatorilor si de a stabili profiluri de utilizatori, care pot fi apoi folosite in scopuri de marketing. Spre exemplu, pe baza cookie-urilor pot fi identificate produsele si serviciile agreate de catre un utilizator, aceste informatii servind ulterior la transmiterea de mesaje publicitare adecvate catre respectivul utilizator.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 40 }}>Este important de mentionat faptul ca site-urile web din Romania au obligatia de a specifica in mod public daca folosesc cookie-uri si in ce scop (<a className="meditatii-despre-noi-abonament-link" href="https://www.apti.ro/cookies/#c5" rel='noopener noreferrer' target="_blank">vezi pct.5 – Reglementarea utilizarii cookie-urilor</a>).</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b> 2. Tipuri de cookie-uri</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>3.1. Cookie-uri specifice unei sesiuni online</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Paginile web nu au memorie. Un utilizator care navigheaza de la o pagina web la alta va fi considerat de catre site-ul web drept un nou utilizator. Cookie- urile specifice unei sesiuni stocheaza, de regula, un identificator care permite utilizatorului sa treaca de la o pagina web la alta fara sa fie nevoie sa introduca de fiecare data informatiile de identificare (nume de utilizator, parola, etc). Astfel de cookie-uri sunt utilizate la scara larga de catre site-uri comerciale, spre exemplu, pentru a tine evidenta produselor adaugate de catre un utilizator in cosul de cumparaturi. Atunci cand utilizatorul viziteaza o anumita pagina a unui catalog de produse si selecteaza anumite produse, cookie-ul retine produsele selectate si le adauga in cosul de cumparaturi, care va contine toate produsele selectate in momentul in care utilizatorul doreste sa paraseasca pagina.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Cookie-urile specifice unei sesiuni sunt stocate in memoria calculatorului utilizatorului doar pe durata unei sesiuni de navigare pe Internet si sunt sterse in mod automat in momentul in care browser-ul este inchis. Pot deveni inaccesibile si daca sesiunea a fost inactiva pentru o anumita perioada de timp (de regula, 20 de minute).</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>3.2. Cookie-uri permanente, persistente sau stocate</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Cookie-urile persistente sunt stocate la nivelul calculatorului utilizatorului si nu sunt sterse atunci cand sesiunea de navigare este inchisa. Aceste cookie-uri pot retine preferintele utilizatorului pentru un anumit site web, astfel incat acestea sa poata fi utilizate in cadrul altor sesiuni de navigare pe Internet.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Pe langa informatiile de autentificare, cookie-urile persistente pot retine si detalii referitoare la limba si tema selectate pe un anumit site web, preferinte privind meniul unui site, pagini favorite in interiorul unui site, etc. Atunci cand utilizatorul acceseaza un site pentru prima data, acesta este prezentat in modul implicit. Ulterior, utilizatorul selecteaza o serie de preferinte, care sunt apoi retinute de catre cookie-uri si folosite atunci cand utilizatorul acceseaza din nou site-ul. Spre exemplu, un site web isi ofera continutul in mai multe limbi. La prima vizita, utilizatorul selecteaza limba engleza, iar site-ul retine aceasta preferinta intr-un cookie. Atunci cand utilizatorul viziteaza din nou site-ul respectiv, continutul va fi afisat automat in limba engleza.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Cookie-urile persistente pot fi folosite pentru identificarea utilizatorilor individuali si, astfel, pentru analizarea comportamentului online al utilizatorilor. Ele pot furniza informatii referitoare la numarul de vizitatori ai unui site web, timpul (in medie) petrecut pe o anumita pagina, si, in general, performantele unui site web. Aceste cookie-uri sunt configurate pentru a putea urmari activitatile utilizatorilor pentru o perioada indelungata de timp, in unele cazuri fiind vorba chiar despre ani.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>3.3. Cookie-uri flash</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Daca utilizatorul are Adobe Flash instalat pe calculator, mici fisiere pot fi stocate in memoria respectivului calculator de catre site-uri web care contin elemente Flash (cum ar fi clipuri video). Aceste fisiere sunt cunoscute sub numele de „local shared objects” sau „cookie-uri flash” si pot fi utilizate pentru aceleasi scopuri ca si cookie-urile obisnuite.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Atunci cand cookie-urile obisnuite sunt sterse prin intermediul functiilor unui browser, cookie-urile flash nu sunt afectate. Astfel, un site web care utilizeaza cookie-uri flash poate sa recunoasca un utilizator cu ocazia unei noi vizite, daca datele specifice cookie-urilor sterse au fost retinute si intr-un cookie flash.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Intrucat cookie-urile flash nu sunt stocate in calculatorul utilizatorului in aceeasi maniera in care sunt stocate cele obisnuite, ele sunt mai dificil de identificat si de sters. Bancile si site-urile de natura financiara folosesc astfel de cookie-uri tocmai pentru acest motiv. Pentru ca sunt dificil de identificat, aceste cookie-uri sunt stocate in calculatoarele utilizatorilor pentru a permite autentificarea utilizatorilor si a preveni fraudele, deoarece eventualii infractori pot detine numele de utilizator si parola pentru autentificare, dar nu au acces la calculatorul utilizatorului. Astfel, cookie- urile actioneaza ca un al doilea nivel de autentificare, pe langa numele de utilizator si parola.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>3.4. Cookie-uri first party vs cookie-uri third party</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Fiecare cookie are un „proprietar” – site-ul web/domeniul Internet care plaseaza respectivul cookie.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Cookie-urile first party (prima parte) sunt plasate de catre domeniul Internet /site-ul web accesat de catre utilizator (a carui adresa apare in bara de adrese a browserului). Spre exemplu, daca utilizatorul viziteaza <b>www.apti.ro</b>, iar domeniul cookie-ului plasat pe calculatorul sau este <b>www.apti.ro</b>, atunci este vorba despre un cookie first party.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Un cookie third party (terta parte) este plasat de catre un alt domeniu Internet/site web decat cel accesat de catre utilizator; acest lucru inseamna ca site-ul web accesat contine si informatii provenind de la un site web tert – spre exemplu, un banner publicitar care apare pe site-ul accesat. Astfel, daca utilizatorul viziteaza <b>www.apti.ro </b>dar cookie-ul plasat pe calculatorul sau are ca domeniu <b>www.trafic.ro</b>, atunci este vorba despre un cookie third party.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Grupul de Lucru Articolul 29 (alcatuit din autoritatile nationale pentru protectia datelor din statele membre ale Uniunii Europene) considera ca, din punct de vedere juridic, si avand in vedere legislatia europeana, notiunea de „cookie third party” se refera la un cookie plasat de catre un operator distinct fata de cel care opereaza site-ul web vizitat de catre utilizator. Cookie-urile third party nu sunt strict necesare utilizatorului care acceseaza un site web, intrucat ele sunt, de regula, asociate unui serviciu distinct fata de cel care a fost „solicitat” in mod explicit de catre utilizator (prin accesarea site-ului web).</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>4. Cookie-urile din perspectiva securitatii informatice si protectiei vietii private</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Desi cookie-urile sunt stocate in memoria calculatorului utilizatorului de Internet, ele nu pot accesa/citi alte informatii aflate in respectivul calculator. Cookie-urile nu sunt virusi. Ele sunt doar mici fisiere text; nu sunt compilate sub forma de cod si nu pot fi executate. Astfel, nu se pot auto-copia, nu se pot raspandi in alte retele pentru a genera actiuni si nu pot fi folosite pentru raspandirea de virusi.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Cookie-urile nu pot cauta informatii in calculatorul utilizatorului, insa ele stocheaza informatii de natura personala. Aceste informatii nu sunt generate de catre cookie-uri, ci de catre utilizator, in momentul in care acesta completeaza formulare online, se inregistreaza pe anumite site-uri web, utilizeaza sisteme de plati electronice, etc. Desi, de regula, informatiile sensibile sunt protejate pentru a nu putea fi accesate de persoane neautorizate, este insa posibil ca astfel de persoane sa intercepteze informatiile transmise intre browser si site-ul web. Chiar daca sunt destul de rare, astfel de situatii pot aparea atunci cand browserul se conecteaza la server utilizand o retea necriptata, cum ar fi un canal WiFi nesecurizat.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Pentru a reduce ricurile de interceptare a cookie-urilor, pot fi utilizate asa numitele „secure cookie” sau „HttpOnly cookie”. Cookie-urile de tip „secure” sunt menite sa limiteze comunicarea informatiilor stocate in cookie-uri la transmiterea criptata, indicand browser- ului sa foloseasca cookie-uri doar prin conexiuni sigure/criptate. Astfel, daca site-ul web foloseste HTTPS, cookie-urile aferente site-ului sunt marcate cu atributul „secure”, acest lucru impiedicand transmiterea lor catre o pagina non-HTTPS, chiar daca aceasta este localizata la acelasi URL. Spre exemplu, daca google.ro foloseste un „secure cookie”, acel cookie poate fi obtinut doar de catre google.ro si doar de pe o conexiune https (care certifica faptul ca cel care cere cookie-ul este Google Inc si nu altcineva). Atributul „HttpOnly” indica browserului sa foloseasca cookie-uri doar prin intermediul protocolului HTTP (care include si HTTPS). Un cookie HttpOnly nu este accesibil prin metode non-HTTP, cum ar fi JavaScript, neputand fi tinta unor atacuri de tip cross-site scripting.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>O alta sursa de ingrijorare este reprezentata de utilizarea cookie-urilor in scopuri de publicitate directionata comportamental. Astfel, cookie-urile pot fi utilizate de catre companiile de publicitate online pentru monitorizarea comportamentului si preferintelor online ale utilizatorului, in scopul identificarii si livrarii catre utilizator a celor mai relevante mesaje publicitare. Insa aceste preferinte nu sunt exprimate explicit sau constient de catre utilizator, ci sunt modelate in functie de istoricul navigarii online a utilizatorului, paginile vizualizate de catre acesta, mesajele publicitare accesate. Spre exemplu, atunci cand utilizatorul citeste o pagina web despre masini si se muta ulterior pe o alta pagina, mesaje publicitare referitoare la masini vor fi afisate in noua pagina, chiar daca aceasta nu are legatura cu masini. Intrucat utilizatorul nu este informat despre faptul ca actiunile sale online sunt monitorizate, acest lucru genereaza ingrijorari referitoare la protectia vietii private.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Astfel, utilizarea cookie-urilor ridica ingrijorari referitoare la folosirea informatiilor retinute prin aceste cookie-uri in scopul monitorizarii utilizatorilor si folosirii de tehnologii de tip spyware, mai ales in cazurile in care informatiile sunt stocate in calculatoarele utilizatorilor si folosite pentru recunoasterea lor, fara ca utilizatorii sa aiba cunostinta despre acest lucru sau sa-si fi dat acordul in acest sens.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>5. Reglementarea utilizarii cookie-urilor </b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Utilizarea cookie-urilor si obligatiile furnizorilor sunt reglementate atat in legislatia Uniunii Europene, cat si in legislatia nationala.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Astfel, <b>Directiva 2002/58/CE</b> privind prelucrarea datelor personale si protejarea confidentialitatii in sectorul comunicatiilor electronice, modificata prin <b>Directiva 2009/136/CE </b>, prevede ca :</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>“Art.5 – (3) Statele membre se asigura ca stocarea de informatii sau dobandirea accesului la informatiile deja stocate in echipamentul terminal al unui abonat sau utilizator este permisa doar cu conditia ca abonatul sau utilizatorul in cauza sa isi fi dat acordul, dupa ce a primit informatii clare si complete, in conformitate cu Directiva 95/46/CE, inter alia, cu privire la scopurile prelucrarii. Aceasta nu impiedica stocarea sau accesul tehnic cu unicul scop de a efectua transmisia comunicarii printr-o retea de comunicatii electronice sau in cazul in care acest lucru este strict necesar in vederea furnizarii de catre furnizor a unui serviciu al societatii informationale cerut in mod expres de catre abonat sau utilizator.”</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Aceste prevederi au fost transpuse in legislatia nationala in Legea nr.506/2004 privind prelucrarea datelor cu caracter personal si protectia vietii private in sectorul comunicatiilor electronice, cu modificarile si completarile ulterioare:</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>“Art.4 –</p>
                    <ul className="meditatii-confidentialitate-list">
                        <li>(5) Stocarea de informatii sau obtinerea accesului la informatia stocata in echipamentul terminal al unui abonat ori utilizator este permisa numai cu indeplinirea in mod cumulativ a urmatoarelor conditii:</li>
                        <li style={{ listStyle: 'none' }}>
                            <ol className="meditatii-confidentialitate-list">
                                <li>abonatul sau utilizatorul in cauza si-a exprimat acordul;</li>
                                <li>abonatului sau utilizatorului in cauza i s-au furnizat, anterior exprimarii acordului, in conformitate cu prevederile art. 12 din Legea nr. 677/2001, cu modificarile si completarile ulterioare, informatii clare si complete care:</li>
                                <li style={{ listStyle: 'none' }}>
                                    <ol className="meditatii-confidentialitate-list">
                                        <li>sa fie expuse intr-un limbaj usor de inteles si sa fie usor accesibile abonatului sau utilizatorului;</li>
                                        <li>sa includa mentiuni cu privire la scopul procesarii informatiilor stocate de abonat sau utilizator ori informatiilor la care acesta are acces.</li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                    </ul>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>In cazul in care furnizorul permite unor terti stocarea sau accesul la informatii stocate in echipamentul terminal al abonatului ori utilizatorului, informarea in concordanta cu pct. (i) si (ii) va include scopul general al procesarii acestor informatii de catre terti si modul in care abonatul sau utilizatorul poate folosi setarile aplicatiei de navigare pe internet ori alte tehnologii similare pentru a sterge informatiile stocate sau pentru a refuza accesul tertilor la aceste informatii.</p>
                    <ul className="meditatii-confidentialitate-list">
                        <li>(51) Acordul prevazut la alin. (5) lit. a) poate fi dat si prin utilizarea setarilor aplicatiei de navigare pe internet sau a altor tehnologii similare prin intermediul carora se poate considera ca abonatul ori utilizatorul si-a exprimat acordul.</li>
                        <li>(6) Prevederile alin. (5) nu aduc atingere posibilitatii de a efectua stocarea sau accesul tehnic la informatia stocata in urmatoarele cazuri:</li>
                        <li style={{ listStyle: 'none' }}>
                            <ol className="meditatii-confidentialitate-list">
                                <li>atunci cand aceste operatiuni sunt realizate exclusiv in scopul efectuarii transmisiei unei comunicari printr-o retea de comunicatii electronice;</li>
                                <li>atunci cand aceste operatiuni sunt strict necesare in vederea furnizarii unui serviciu al societatii informationale, solicitat in mod expres de catre abonat sau utilizator.”</li>

                            </ol>
                        </li>
                    </ul>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Conform acestor prevederi, utilizarea cookie-urilor third party este permisa cu urmatoarele conditii:</p>
                    <ul className="meditatii-confidentialitate-list">
                        <li>informarea utilizatorilor, intr-o maniera clara, completa si usor accesibila, in legatura cu:</li>
                        <li style={{ listStyle: 'none' }}>
                            <ul className="meditatii-confidentialitate-list">
                                <li>plasarea, de catre un anumit site web, de cookie-uri in memoria calculatorului utilizatorului;</li>
                                <li>scopul utilizarii cookie-urilor (informatiile stocate in cookie-uri si scopul in care aceste informatii sunt utilizate);</li>
                                <li>modalitatile prin care utilizatorul poate sterge cookie-urile sau poate refuza accesul unor terti la informatiile stocate de respectivele cookie-uri;</li>
                            </ul>
                        </li>
                        <li>obtinerea acordului utilizatorului pentru plasarea de cookie-uri si pentru utilizarea informatiilor continute de catre acestea.</li>
                        <li>desi acordul utilizatorilor poate fi exprimat si prin utilizarea setarilor browser-ului folosit pentru navigarea pe Internet, este necesar ca si in acest caz sa existe o informare prealabila a utilizatorului in legatura cu plasarea de cookie-uri si cu scopul acestora.</li>
                    </ul>

                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Exceptiile prevazute in legislatia europeana si nationala permit utilizarea de cookie-uri first party fara respectarea obligatiei obtinerii acordului utilizatorului. In plus, in iunie 2012, Grupul de Lucru Articolul 29 a emis un aviz prin care clarifica aceste exceptii:</p>
                    <ul className="meditatii-confidentialitate-list">
                        <li>unele cookie-uri pot fi exceptate de la obligatia obtinerii acordului informat al utilizatorului in anumite conditii si daca nu sunt utilizate in scopuri suplimentare. Astfel de cookie-uri includ: cookie-urile utilizate pentru memorarea informatiilor introduse de catre un utilizator atunci cand completeaza un formular online, cookie-urile utilizate pentru stocarea datelor tehnice necesare rularii de continut video si audio si cookie-urile utilizate pentru personalizarea paginilor web (spre exemplu, cele care retin preferintele referitoare la limba in care este afisat continutul unui site web).</li>
                        <li>cookie-urile de tip first party nu genereaza riscuri pentru viata privata a utilizatorilor daca site-ul web ofera utilizatorilor informatii clare in ceea ce priveste utilizarea de cookie- uri, precum si garantii privind protectia vietii private (spre exemplu, punerea la dispozitie a unui mecanism facil prin care utilizatorul sa poata solicita ca datele sale sa nu fie colectate) si daca este asigurata anonimizarea informatiilor de autentificare.</li>
                    </ul>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>6. Mecanismul „Do Not Track”</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Asa cum am aratat la <a className="meditatii-despre-noi-abonament-link" href="https://www.apti.ro/cookies/#c5" rel='noopener noreferrer' target="_blank">pct.5</a>, la nivel european exista reglementari referitoare la monitorizarea activitatilor online ale utilizatorilor in scopuri de marketing, fiind necesara, in general, obtinerea acordului utilizatorilor pentru astfel de practici. Insa in alte parti ale lumii astfel de situatii sunt mai putin reglementate. In aceste conditii, in cadrul World Wide Web Consortium (W3C) se lucreaza, in momentul de fata, la un standard tehnic (si neutru din punct de vedere tehnologic) – „Do Not Track”. Acest standard va putea fi folosit de catre utilizatori pentru a le spune browserelor sa semnalizeze companiilor de publicitate faptul ca nu doresc ca activitatile lor online sa fie monitorizate.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>W3C arata ca „utilizatorii au dreptul sa stie care date vor fi colectate si in ce scop vor fi utilizate. Avand aceste informatii, ei pot decide daca sa permita sau nu monitorizarea activitatilor desfasurate online si colectarea datelor cu caracter personal. Multe companii Internet folosesc datele colectate in legatura cu activitatile online ale utilizatorilor pentru a personaliza continutul furnizat utilizatorilor si a directiona catre acestia mesaje publicitare relevante, in functie de interesele identificate pe baza informatiilor colectate. Desi unii utilizatori apreciaza aceasta personalizare a continutului si a mesajelor publicitare in anumite contexte, altii sunt ingrijorati in legatura cu ceea ce percep a fi o intruziune in viata lor privata.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>In aceste conditii, utilizatorii au nevoie de un mecanism care sa le permita sa-si exprime preferintele cu privire la monitorizarea activitatilor desfasurate online; acest mecanism trebuie sa fie usor de configurat si eficient. In plus, site-urile web care nu pot sau nu vor sa furnizeze continut fara sa ofere in acelasi timp si publicitate comportamentala sau fara sa colecteze date despre utilizatori au nevoie de un mecanism prin care sa indice aceste lucruri utilizatorilor si sa le permita acestora sa ia o decizie in cunostinta de cauza.”</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Scopul standardului „Do Not Track” este „de a oferi utilizatorului posibilitatea de a-si exprima optiunile personale cu privire la monitorizarea activitatilor desfasurate online si de a comunica aceste optiuni fiecarui server sau aplicatie web cu care interactioneaza, permitand astfel fiecarui serviciu accesat fie sa-si ajusteze practicile in functie de optiunile utilizatorului, fie sa ajunga la un acord separat cu utilizatorul, care sa fie convenabil ambelor parti. Principiul de baza este ca exprimarea preferintelor privind monitorizarea este transmisa doar atunci cand reflecta o optiune deliberata a utilizatorului. In lipsa unei optiuni a utilizatorului, se considera ca preferinta privind monitorizarea activitatilor online nu este exprimata.”</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b>Functionalitati Do Not Track pentru motoarele de cautare</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Optiuni pentru impiedicarea monitorizarii activitatii online a utilizatorului sunt implementate astazi in diverse forme. De la Internet Explorer 8 care iti ofera posibilitatea sa blochezi site-uri terte care lasa continut atunci cand vizitezi un website, pana la noile extensii, add-ons si optiuni introduse chiar in preferintele motorului de cautare. In lipsa standardului amintit mai sus, la unele motoare de cautare e mai evident cum activezi aceasta functionalitate, la altele e mai ascuns. Instructiuni pentru a seta mecanismul Do Not Track pentru Safari, Internet Explorer 9, Firefox si Chrome gasesti <a className="meditatii-despre-noi-abonament-link" href="https://www.eff.org/deeplinks/2012/06/how-turn-do-not-track-your-browser" rel='noopener noreferrer' target="_blank">aici</a></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Numarandu-se printre ultimii care introduc aceasta functionalitate, versiunea numarul 23 Google Chrome ofera posibilitatea instalarii extensiillor <a className="meditatii-despre-noi-abonament-link" href="https://chrome.google.com/webstore/detail/blur/epanfjkfahimkgomnigadpkobaefekcd?hl=en#detail/donottrackme/epanfjkfahimkgomnigadpkobaefekcd?hl=en" rel='noopener noreferrer' target="_blank">Do Not Track Me</a>, AVG Do Not Track sau Keep My Opt-Outs care blocheaza cookie-uri si impiedica (momentan) doar companiile de publicitate americane sa personalizeze anunturile in functie de comportamentul online al utilizatorului de internet.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Firefox, pe langa add-on-ul <a className="meditatii-despre-noi-abonament-link" href="https://addons.mozilla.org/en-US/firefox/addon/donottrackplus/" rel='noopener noreferrer' target="_blank">Do Not Track Me</a>, ofera si optiunea „<a className="meditatii-despre-noi-abonament-link" href="https://support.mozilla.org/en-US/kb/how-do-i-turn-do-not-track-feature" rel='noopener noreferrer' target="_blank">Tell web sites I do not want to be tracked</a>” ce poate fi configurata in meniul privacy. Mai mult, Internet Explorer 10 vine cu Do Not Track ca optiune default. Decizia Microsoft a starnit o serie de reactii puternice, raspunsul unor companii precum Yahoo si Apache fiind ca <a className="meditatii-despre-noi-abonament-link" href="https://www.cnet.com/news/yahoo-will-ignore-do-not-track-for-ie10-users/" rel='noopener noreferrer' target="_blank">vor ignora</a> semnalele Do Not Track ale Internet Explorer 10.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>O alta unealta pe care o poti instala pe majoritatea motoarelor de cautare (si chiar ca aplicatie pe iOS) este <a className="meditatii-despre-noi-abonament-link" href="https://www.ghostery.com/" rel='noopener noreferrer' target="_blank">Ghostery</a>. Ghostery scaneaza pagina pe care o vizitezi si te anunta de existenta elementelor instalate de site-uri terte pentru a iti urmari activitatea. Iti poti seta apoi preferintele in functie de categoriile din meniu: advertising, analytics, beacons, privacy, widgets. Mai multe informatii <a className="meditatii-despre-noi-abonament-link" href="https://www.ghostery.com/faqs/" rel='noopener noreferrer' target="_blank">aici</a>.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>De mentionat este faptul ca nu toate functionalitatile Do Not Track blocheaza cookie-urile. Asadar e bine sa verifici ce intra in componenta fiecarei extensii Do Not Track si sa o alegi pe cea care reprezinta cel mai bine limitarile pe care vrei sa le transmiti site-urilor care iti monitorizeaza activitatea pe internet.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b> 7. Recomandarile IAB Romania pentru administratorii de site-uri web privind cookie-urile</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>In iunie 2012, IAB Romania a publicat o serie de <a className="meditatii-despre-noi-abonament-link" href="https://iab-romania.ro/noutati/activitate-iab/recomandarile-iab-privind-prezenta-cookie-urilor-pe-siteurile-web/" rel='noopener noreferrer' target="_blank">recomandari privind utilizarea cookie-urilor</a> de catre site-urile web. Aceste recomandari au fost grupate in doua categorii:</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b> 7.1 Informarea utilizatorului</b></p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>In momentul in care un site web foloseste cookie-uri, fie in interes propriu, fie din partea tertilor, acesta trebuie sa puna la dispozitia utilizatorului, in mod vizibil si clar, cel putin urmatoarele informatii:</p>
                    <ol className="meditatii-confidentialitate-list">
                        <li>faptul ca site-ul utilizeaza cookie-uri – in special mentionat daca sunt plasate de terti (in scopuri de publicitate targetata, de exemplu) si mentionarea clara a scopurilor pentru care site-ul respectiv foloseste cookie-uri;</li>
                        <li>ce sunt cookie-urile;</li>
                        <li>care este rolul lor;</li>
                        <li>de ce sunt folosite de terti;</li>
                        <li>ce tip de informatii sunt accesate cu ajutorul cookie-urilor;</li>
                        <li>cookie-urile, securitatea si confidentialitatea datelor personale;</li>
                        <li>managementul cookie-urilor prin intermediul setarilor browserului;</li>
                        <li>de ce sunt cookie-urile importante pentru Internet;</li>
                        <li>dezinstalarea cookie-urilor de la terti;</li>
                        <li>ce se intampla daca utilizatorul refuza cookie-urile.</li>
                    </ol>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b> 7.2  Modul de prezentare a acestor informatii</b></p>
                    <ol className="meditatii-confidentialitate-list">
                        <li>informatiile referitoare la cookie-uri trebuie prezentate pe site intr-un mod vizibil si accesibil utilizatorilor;</li>
                        <li>trebuie sa existe un link separat de „termeni si conditii” sau „politica de confidentialitate”;</li>
                        <li>acest link trebuie sa fie usor de reperat sau utilizatorului trebuie sa i se indice existenta acestui link si a informatiilor referitoare la cookie-uri;</li>
                        <li>utilizatorului trebuie sa ii fie recomandat in mod vizibil sa citeasca aceste informatii si instructiuni;</li>
                        <li>recomandari: fie printr-un banner plasat in primul ecran, fie printr-un sticky ribbon, fie printr-un widget plasat vizibil – utilizatorului trebuie sa i se aduca la cunostinta ca site-ul foloseste cookie-uri si sa i se recomande informarea suplimentara referitoare la acestea.</li>
                        
                    </ol>


                </div>
            </div>
        );
    }
}

;