import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'


export default class PoliticaCookie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    componentDidMount(){

    this.scrollTop()
}

scrollTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}
    render() { 
        return ( 
            <div>
                <AppBar history={this.props.history} />
                 <div className="meditatii-section-container" >
                    <h1 className="meditatii-section-meditezi-title" style={{ marginTop: 50 }}>Politica Cookie</h1>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Casa Medita&#355;iilor foloseste “cookie-uri” pentru a &#238;mbun&#259;t&#259;&#355;i experien&#355;a online a utilizatorilor website-ului nostru.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Cookie-urile reprezint&#259; fi&#351;iere de dimensiuni extreme de reduse, compuse dintr-un &#351;ir de litere &#351;i cifre, salvate &#238;n calculatorul dumneavoastr&#259; de serverele paginilor web pe care le vizita&#355;i. Aceste fi&#351;iere permit proprietarilor de site-uri s&#259; v&#259; deosebeasc&#259; de al&#355;i useri ai aceluia&#351;i site. Cookie-urile nu pot fi rulate ca &#351;i cod sau utilizate pentru a livra viru&#351;i &#351;i nu ne permit accesul in hard disk-ul dumneavoastr&#259;. Nu putem citi nici o informa&#355;ie de pe hard disk-ul dumneavoastr&#259; chiar dac&#259; acolo se stocheaz&#259; cookie-urile.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Datele generate de cookie-urile de pe site-ul Casa Medita&#355;iilor pot fi folosite &#238;n scopuri variate, inclusiv urm&#259;toarele:</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Absolut necesare: Cookie-uri esen&#355;iale pentru a v&#259; permite s&#259; naviga&#355;i prin website &#351;i s&#259;-i utliza&#355;i functionalit&#259;&#355;ile precum accesarea zonelor securizate ale website-ului.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Func&#355;ionalitate: Aceste cookie-uri permit site-ului s&#259; re&#355;in&#259; alegerile pe care le faceti precum numele de utilizator, limba preferat&#259; sau regiunea pentru a facilita o experien&#355;&#259; &#238;mbun&#259;t&#259;&#355;it&#259; de navigare.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Performan&#355;&#259; &#351;i Statistic&#259;: Aceste cookie-uri colecteaza informa&#355;ii despre modul &#238;n care vizitatorii folosesc un website, de exemplu care sunt paginile cele mai vizitate sau dac&#259; primesc mesaje de eroare. Aceste cookie-uri ne ajut&#259; s&#259; &#238;mbun&#259;t&#259;&#355;im func&#355;ionalitatea versiunilor viitoare ale site-ului.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Nu permitem p&#259;r&#355;ilor ter&#355;e publicitatea pe web site-ul nostru, dar nu putem controla advertiserii care au permisiunea companiei dumneavoastr&#259; de internet s&#259; promoveze c&#226;t timp dumneavoastr&#259; sunte&#355;i conectat la internet.</p>
                    <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>&#206;n cazul &#238;n care continua&#355;i s&#259; utiliza&#355;i acest web site, aceasta va indica consim&#355;&#259;m&#226;ntul dumneavoastr&#259; de a utiliza cookie-uri.</p>

                 </div>

            </div>
         );
    }
}
 
;