import React from 'react';
import {ListItem, ListItemText, Divider} from '@material-ui/core'



export default class LiRatings  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
         
          }
    }


    getRatings = (rating) =>{
        let percent = (parseFloat(rating) / 5) * 100

      

        let percentRound = `${(Math.round(percent/10)*10)}%`

     
 
    
        return percentRound

     }

     aproba=()=>{
         this.props.aproba(this.props.rating)
     }

     sterge=()=>{
         this.props.sterge(this.props.rating)
     }

  

    render() { 
        return ( 
            <div style={{maxWidth:800,margin:"auto"}}>
            <ListItem  style={{display:'block', position:'relative'}} button>
            <i className='material-icons meditatii-add-rating-button' onClick={this.aproba} style={{display:this.props.rating.publicat !== 1 ?'inline-block' : 'none'}}>done</i>
           
            <ListItemText style={{display:'block', width:'100%', margin:'auto'}} onClick={this.infoContent} primary={<h3 className="meditatii-admin-clienti-list-text">{this.props.index + 1} - {this.props.rating.email}</h3>}
                          secondary={<span className="meditatii-list-admin-label">Meditatie: <span className='meditatii-list-admin-label-info'>{this.props.rating.nume_content}</span></span>}
                                 
                                    />
                                    <div style={{width:'100%', textAlign:'center', margin:'auto'}}>

             <div className="stars-outer" id='meditatie-stars-outer'  >
                                <div className="stars-inner" id='meditatie-stars-inner' style={{width:this.getRatings(this.props.rating.nota)}} ></div>
                            </div>
                                    </div>
            <i className='material-icons meditatii-remove-rating-button' onClick={this.sterge}>delete</i>
        </ListItem>
        <Divider/>
        </div>
         );
    }
}
 
 ;