import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import {Grid} from '@material-ui/core'

export default class DespreNoi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    componentDidMount(){
        this.scrollTop()
    }

    scrollTop=()=>{
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    render() { 
        return (
            <div>
                 <AppBar history={this.props.history} />
                <div className="meditatii-paralax">
                    <div className="meditatii-paralax-img-desprenoi" ></div>
                    <div className="meditatii-paralax-content">
                        <h1 className="meditatii-paralax-content-title " style={{ marginTop: 40 }}>Bine ai venit acas&#259;</h1>

                    </div>
                </div>
                <div className="meditatii-section-container" >
                <Grid container className="meditatii-subsection-container" style={{maxWidth:1200}}>
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={6} lg={6} xl={6} style={{margin:'auto'}}>
                            <img alt="meditatie-despre-noi" className="logo-img" src={require('../../Module/Pics/Despre-noi1-min.png')} />
                          


                        </Grid>
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <p className="meditatii-despre-noi-paragraph" style={{ marginBottom: 30 }}>Suntem o echipa mic&#259; dar inimoas&#259;, pasionat&#259; de medita&#355;ie, relaxare &#351;i muzic&#259;. Noi tr&#259;im via&#355;a la fel ca tine. Ne facem griji zilnic. Ne stres&#259;m încontinuu. Ne pierdem aten&#355;ia u&#351;or &#351;i uneori adormim cu greu. Dar am descoperit medita&#355;ia &#351;i lucrurile bune pe care ea le poate aduce atunci când o practic&#259;m regulat. Din acest motiv am creat Casa Medita&#355;iilor, pentru a oferi celor din jur lini&#351;te &#351;i bucurie.  Medita&#355;ia ajut&#259;, iar &#351;tiin&#355;a modern&#259; este de acord cu noi. În Cas&#259; po&#355;i s&#259; înve&#355;i s&#259; meditezi imediat cu cele 20 medita&#355;ii primite cadou. Dac&#259; î&#355;i plac, te po&#355;i <a className="meditatii-footer-copyright-links" href="https://www.casameditatiilor.ro/#/abonamente" rel='noopener noreferrer'>abona</a> . Astfel, vei avea acces la tehnici, programe de medita&#355;ie &#351;i muzic&#259; de relaxare care te vor ajuta s&#259; vezi lumea cu ochi calmi &#351;i blânzi. Ai nevoie doar de 10 minute pe zi pentru ca mintea ta s&#259; intre într-o rutin&#259; care s&#259; te elibereze de stres, anxietate &#351;i s&#259; te ajute s&#259; dormi bine.  </p>
                        <p className="meditatii-despre-noi-paragraph" style={{ marginBottom: 30 }}>Fie c&#259; e&#351;ti stresat(&#259;), anxios(&#259;), adormi greu, vrei s&#259; te concentrezi mai bine la serviciu sau pur &#351;i simplu s&#259;-&#355;i lini&#351;te&#351;ti mintea, suntem aici pentru &#355;ine. Chiar dac&#259; e&#351;ti incep&#259;tor sau meditezi de mult timp, e&#351;ti binevenit.   </p>
                        </Grid>
                </Grid>
                <h2 className="meditatii-section-meditezi-title" style={{marginBottom:10}}>F&#195; O PAUZ&#195;</h2>
                <p className="meditatii-section-paragraph">Descoper&#259; puterea medita&#355;iei</p>
               
                </div>
                <div className="meditatii-paralax-desprenoi2">
                    <div className="meditatii-paralax-img-desprenoi2" ></div>
                    <div className="meditatii-paralax-content">
                        <p className="meditatii-paralax-content-subtitle2" style={{ marginTop: 40 }}>Creeaz&#259; Cont Gratuit Acum &#351;i Mediteaza Online</p>
                        <div className="mediteaza-message-text">
                            <h2 className="mediteaza-register-text" onClick={()=>{this.props.history.push("/inregistreazate/0")}}>CREEAZ&#195; CONT</h2>
                        </div>
                    </div>
                </div>
                <div className="meditatii-section-container" style={{ marginTop: 100 }} >
                <iframe title='meditatii-despre-noi' style={{width:"100%", maxWidth:700}} height="400" src='https://www.youtube.com/embed/1IAdnS9I1o8?wmode=opaque' allowFullScreen></iframe>
                </div>
            </div>
          );
    }
}
 
