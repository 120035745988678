//const adresaServer = "http://192.168.100.10:8888/CM/API/files/"

const adresaServer = "https://www.casameditatiilor.ro/API/files/"

// poza - tip = images, audio - tip = audio
export const imgUpload=(media, tip)=>{

   return `https://www.casameditatiilor.ro/API/media/${tip}/${media}`
    //return `http://192.168.100.10:8888/CM/API/media/${tip}/${media}`

 
}



//(pagina, paramsToSend =null,httpParam = null, asText= false, metoda = 'GET', postData = null, postDataName )



//(lista, {mode:''}, {credentials:'include'})





export const fromServer = async (pagina, paramsToSend =null,httpParam = null, asText= false, metoda = 'GET', postData = null, postDataName = '' ) =>{
        let r = null;

        let adresa = `${adresaServer}${pagina}.php`

    

        if(paramsToSend){
            adresa+='?'
            for (let [key, value] of Object.entries(paramsToSend)) {
               adresa+= `${key}=${value}&`
            }
        
         
            adresa =  adresa.substring(0, adresa.length -1)
        
        }
        adresa = encodeURI(adresa)

        

        if(metoda ==='GET'){
            let myReq = new Request(adresa, httpParam)

             r = await fetch(myReq)
             
        }else if(metoda === 'POST'){
            let formData = new FormData();
            formData.append(postDataName, postData)

           

            let postParams = {...httpParam}
            postParams.body = formData

            

            let myReq = new Request(adresa, postParams)
            r = await fetch(myReq)

          
            
        }
 
    

        if(asText === true){
            if(r.ok) {

           
                let js = await r.text();
            
               
                    return js;
                
                //return new Error(`Eroare primire date: ${js.m}`)
            }
            else{
                throw new Error(r.statusText);
            }
        }else{
            if(r.ok) {

                let js = await r.json();

           
            
                 
                
                return js
            }
            else{
                throw new Error(r.statusText);
            }
        }
}






