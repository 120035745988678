import React from 'react';
import {ListItem, ListItemText, Switch, Divider} from '@material-ui/core'
import { fromServer } from '../../Providers/ws';



export default class LiPrograme  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ascunde:this.props.content.ascuns === 1 ? true : false
          }
    }


   
    blocheaza=async(ev)=>{
        this.setState({ascunde:ev.target.checked},async()=>{
            if(this.state.ascunde === true){

                await fromServer('programs',{mode:'hide', id_program:this.props.content.idul}, {credentials:'include'})
              
                this.props.changeStatus(this.props.content,1)
            }else{

                await fromServer('programs',{mode:'publish', id_program:this.props.content.idul}, {credentials:'include'})
           
                this.props.changeStatus(this.props.content,0)
            }
        })
        
    }

    infoContent=()=>{
        this.props.getInfo(this.props.content)
    }
  

    render() { 
        return ( 
            <div style={{maxWidth:800,margin:"auto"}}>
            <ListItem style={{backgroundColor:this.props.content.ascuns !== 1 ? '' : '#ecc9d1'}}  button>
            <ListItemText onClick={this.infoContent} primary={<h3 className="meditatii-admin-clienti-list-text">{this.props.index + 1} - {this.props.content.nume_program}</h3>}
                         
                                    />
            
            <Switch
                checked={this.state.ascunde}
                onChange={this.blocheaza}
                value="checkClient"
                />
        </ListItem>
        <Divider/>
        </div>
         );
    }
}
 
 ;