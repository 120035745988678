import React from 'react';
import {ListItem, ListItemText, Switch, Divider} from '@material-ui/core'
import { fromServer } from '../../Providers/ws';



export default class LiAutori  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
         ascunde:this.props.autor.dezactivat === '1' ? true : false
          }
    }

    goToAutor=()=>{
        this.props.history.push(`/autorinfo/${this.props.autor.idul}`)

        
    }

    

    blocheaza=async(ev)=>{
        this.setState({ascunde:ev.target.checked},async()=>{
            if(this.state.ascunde === true){

                await fromServer('author',{mode:'deactivate_author', id_author:this.props.autor.idul}, {credentials:'include'})

          
                this.props.changeStatus(this.props.autor,'1')
            }else{

                await fromServer('author',{mode:'activate_author', id_author:this.props.autor.idul}, {credentials:'include'})

             
                this.props.changeStatus(this.props.autor,'0')
            }
        })
        
    }


  

    render() { 
        return ( 
            <div style={{maxWidth:800,margin:"auto"}}>
            <ListItem style={{backgroundColor:this.props.autor.dezactivat !== '1' ? '' : '#ecc9d1'}}   button>
            <ListItemText onClick={this.goToAutor} primary={<h3 className="meditatii-admin-clienti-list-text">{this.props.index + 1} - {this.props.autor.nume}</h3>}

                                    />
            <Switch
                checked={this.state.ascunde}
                onChange={this.blocheaza}
                value="checkClient"
                />
            
        </ListItem>
        <Divider/>
        </div>
         );
    }
}
 
 ;