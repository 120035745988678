import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import { Grid } from '@material-ui/core';

export default class Subscribe  extends React.Component {
    constructor(props) {
      super(props);
       this.state = {
 
}
    }

     componentDidMount(){
         this.props.history.push('/abonamente')
    }
     render() { 
       return (
          <div>
              <AppBar history={this.props.history} />
              <div className="meditatii-paralax2">
                    <div className="meditatii-paralax-img2" style={{height:500}}></div>
                    <div className="meditatii-paralax-content" style={{marginTop:212}}>
                        <h2 className="meditatii-paralax-content-title2">Subscrip&#355;ie</h2>
                        
                    

                    </div>

                </div>
                <p className="meditatii-section-paragraph">The Meditation Lab/ Casa Meditatiilor is the Space we invite you to create and explore together for the next months. Here we will experiment together techniques of meditation and breathing for a long, peaceful and relaxed life.
                </p>
                <Grid container className="meditatii-subsection-container">
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <img alt="acasa-meditatie" className="logo-img" src={require('../../Module/Pics/Meditation1.jpg')} />
                          
                        </Grid>
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <img alt="meditatie-concentrare" className="logo-img" src={require('../../Module/Pics/1.JPG')} />
                           </Grid>

                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <img alt="meditatie-relaxare" className="logo-img" src={require('../../Module/Pics/2.JPG')} />
                        </Grid>

                    
                    </Grid>
                <p className="meditatii-section-paragraph">Facilitators of these experiences will guide you in meditations thus reconnecting you to your own resources so you can easily regain balance. This will be an unlimited space that will eliminate barriers and old mental patterns that are of no use to you anymore. A space in which you feel free to just be.
                </p>
                <Grid container className="meditatii-subsection-container">
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <img style={{height:368.19}} alt="acasa-meditatie" className="logo-img" src={require('../../Module/Pics/Meditation2.jpg')} />
                          
                        </Grid>
                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <img alt="meditatie-concentrare" className="logo-img" src={require('../../Module/Pics/Meditation3.jpg')} />
                           </Grid>

                       

                    
                    </Grid>
                <p className="meditatii-section-paragraph">Starting every week of the year, from 19:00 to 20:00, each session will include breathing techniques that will prepare your body and mind for introspection.
                </p>
                <p className="meditatii-section-paragraph">Every Tuesday of the week we will experience a technique of meditation, which can be continued by each of you at home until the next meeting, when we will encourage you to share the harvested fruits with the rest of the participants.
                </p>
              
         </div>
        );
       }
}