import React from 'react';

import AppBar from '../../Componente/Comune/AppBar'

import {Tabs, Tab, Paper, List} from '@material-ui/core'
import {   fromServer } from '../../Providers/ws';

import LiRatings from './LiRatings'
import { getLocalUserData } from '../../Providers/ls';
 

export default class Ratings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            tabsValue:0,
            ratingsAp:[],
            ratingsNeAp:[]
        }
    }

    componentDidMount(){
       this.verifyAdmin()
        this.scrollTop()
    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    verifyAdmin = async()=>{
        
        let server = await fromServer('users',{mode:'check_if_admin', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune}, {credentials:'include'})

        if(server.r === 'ok'){

            this.getRatingsAp()
            if(this.state.techDebug === 1){
            
    
            }
        }else{
            this.props.history.push('/')
        }
    }

    getRatingsAp = async()=>{

        let ratings = await fromServer('rating',{mode:'get_ratings'}, {credentials:'include'})

    

        if(ratings.r === 'ok'){

            let ratingsA = [...ratings.continut]
            let ratingsN =[...ratings.continut]

            let aprobate = ratingsA.filter(r => r.publicat === '1')
            let neaprobate = ratingsN.filter(r=> r.publicat !== '1')

           
            
            this.setState({ratingsAp:aprobate,
                            ratingsNeAp:neaprobate})

        }

      
    }

    sterge=async(r)=>{
       

        let cloneAprobate = [...this.state.ratingsAp]
        let cloneNeaprobate = [...this.state.ratingsNeAp]

        if(r.publicat !== '0'){

           let newR = r

           newR.publicat = '0'
            
            let idx = cloneAprobate.findIndex(ra => ra.id_rating === r.id_rating)
            
            cloneAprobate.splice(idx,1)

            cloneNeaprobate.unshift(newR)


            let anuleaza = await fromServer('rating',{mode:'unapprove_rating', 
                                                        id_rating: r.id_rating,
                                                    id_content:r.id_content }, {credentials:'include'})



            if(anuleaza.r === 'ok'){


                this.setState({ratingsAp:cloneAprobate,
                            ratingsNeAp:cloneNeaprobate})
    
            }
            

        }else{
           
            let idx = cloneNeaprobate.findIndex(ra => ra.id_rating === r.id_rating)
            
            cloneNeaprobate.splice(idx,1)

         

            let anuleaza = await fromServer('rating', {mode:'delete_rating', id_rating:r.id_rating}, {credentials:'include'})

            if(anuleaza.r === 'ok'){

                this.setState({
                            ratingsNeAp:cloneNeaprobate})
            }


        }
    }

    aproba=async(r)=>{
      

        let cloneAprobate = [...this.state.ratingsAp]
        let cloneNeaprobate = [...this.state.ratingsNeAp]

        let newR = r

        newR.publicat = '1'
         
         let idx = cloneNeaprobate.findIndex(ra => ra.id_rating === r.id_rating )
         
         cloneNeaprobate.splice(idx,1)

         cloneAprobate.unshift(newR)

         
         
         let aproba = await fromServer('rating', {mode:"approve_rating", 
                                                    id_rating:r.id_rating,
                                                id_content:r.id_content }, {credentials:'include'})



        if(aproba.r === 'ok'){


            this.setState({ratingsAp:cloneAprobate,
                        ratingsNeAp:cloneNeaprobate})

        }
    }


    render() { 
        return (  
        <div>
            <AppBar history={this.props.history} />
            <div className="meditatii-back-button" onClick={() => { this.props.history.push('/administrator') }}><i className="material-icons meditatii-back-button-icon">reply</i></div>
           
            <Paper >
                    <Tabs
                        value={this.state.tabsValue}
                        onChange={(ev, tabsValue)=>{this.setState({tabsValue})}}
                       className="meditatii-tabs"
                        textColor="primary"
                        centered
                    >
                        <Tab label={<span style={{color:this.state.tabsValue !== 0 ? "rgba(0, 0, 0, 0.54)" : "#2991d6"}}>Neaprobate</span>}  />
                        <Tab label={<span style={{color:this.state.tabsValue !== 1 ? "rgba(0, 0, 0, 0.54)" : "#2991d6"}}>Aprobate</span>} className="meditatii-tabs" />
                       
                    </Tabs>
                </Paper>
                <h1 className="section-title" style={{ marginBottom: 10 }} >Note</h1>
            
                <List style={{ marginBottom: 100, }}>
                    {this.state.tabsValue === 0 &&
                       this.state.ratingsNeAp.map((r, idx)=>{
                           return(
                               <LiRatings key={idx} index={idx} rating={r} aproba={this.aproba} sterge={this.sterge}/>
                           )
                       })
                    }
                    {this.state.tabsValue === 1 &&
                        this.state.ratingsAp.map((r, idx)=>{
                            return(
                                <LiRatings key={idx} index={idx} rating={r} sterge={this.sterge}/>
                            )
                        })
                    }

                </List>
          
        </div>
        );
    }
}
 
 ;