import React from 'react';


import Button from '@material-ui/core/Button';
import {Dialog, DialogContent, DialogActions} from '@material-ui/core/';
 

export default class Alert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div>
                <Dialog 
                 
                disableBackdropClick
                disableEscapeKeyDown
                 open={this.props.open}
                 onClose={this.props.dialogClose}
                 >
                        <h2 style={{ padding: '20px 10px 0 10px', marginTop: 20, textAlign: 'center' }} className="meditatii-section-meditezi-title">
                        {this.props.title}
                        </h2>  <DialogContent>
                           <h4 style={{    color: '#d81a1a'}}>
                              {this.props.mesaj}
                            </h4>            
                        </DialogContent>
                      
                        <DialogActions style={{justifyContent:'center'}}>
                            <Button variant="contained" onClick={this.props.dialogClose} className="dialogAnuleaza">
                                        OK
                            </Button>
                         
                        </DialogActions>
                        
                 </Dialog>
            </div>
             )
    }
}