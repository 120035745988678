import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'



export default class TermeniSiConditii extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    componentDidMount() {
        this.scrollTop()
    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() { 
        return ( 
            <div>
                 <AppBar history={this.props.history} />
                 <div className="meditatii-section-container" >
                 <h1 className="meditatii-section-meditezi-title" style={{ marginTop: 50 }}>Termeni &#351;i condi&#355;ii</h1>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Site-ul “Casa Meditatiilor” este construit exclusiv in scopuri educationale si nu are ca obiectiv oferirea de sfaturi medicale, recomandari legale sau servicii profesionale. Daca simtiti ca aveti o problema medicala, ar trebui sa va adresati de urgenta unui medic.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Folosirea acestui site implica acceptarea termenilor si conditiilor de mai jos. Recomandam citirea cu atentie a acestora. Casa Medita&#355;iilor isi asuma dreptul de a modifica aceste prevederi fara o alta notificare. Cea mai recenta versiune poate fi accesata in aceasta pagina.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Accesul/vizitarea acestui website de catre dumneavoastra se supune Termenilor si conditiilor de ultilizare, si implica acceptul explicit al dumneavoastra cu privire la acestea si reprezinta intelegerea dintre parti.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Relatia dintre parti este guvernata de urmatoarele acte normative:</p>
                 <ul className="meditatii-confidentialitate-list">
                        <li>OG nr. 21/1992 privind protectia consumatorilor</li>
                        <li>OUG nr. 34/2014 privind drepturile consumatorilor in cadrul contractelor incheiate cu profesionistii</li>
                        <li>Legea nr. 363/2007 privind combaterea practicilor incorecte ale comerciantilor in relatia cu consumatorii si armonizarea reglementarilor cu legislatia europeana privind protectia consumatorilor</li>
                        <li>Legea 365/2002 privind comertul electronic</li>
                 </ul>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b> 1. Prin utilizarea site-ului <span className="meditatii-despre-noi-abonament-link" onClick={()=>{this.props.history.push("/")}}>www.CasaMeditatiilor.ro</span> , sunteti de acord cu aceste conditii de utilizare.</b></p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Accesul si utilizarea acestui Site sunt supuse urmatoarelor conditii de utilizare si tuturor legilor si regulamentelor aplicabile. Accesand si utilizand Site-ul, acceptati, fara limitari sau calificari, aceste conditii si luati la cunostinta ca orice alte acorduri intre Dumneavoastra si CasaMeditatiilor.ro sunt inlocuite prin prevederile prezentelor Conditii de Utilizare. Daca nu sunteti de acord sau nu acceptati, fara limitari sau calificari, Conditiile de Utilizare ale acestui Site, va rugam sa parasiti acest Site.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b> 2. Proprietatea Continutului</b></p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Site-ul si tot ceea ce cuprinde acesta, incluzind fara limitare toate textele si imaginile (“Continut”) care sunt in proprietatea si sub dreptul de autor (copyright) al CasaMeditatiilor.ro. Este strict interzisa utilizarea oricarui Continut, cu exceptia celor prevazute in Conditiile de Utilizare, fara permisiunea in scris a proprietarului “Continutului”. De asemenea, va informam ca Site-ul CasaMeditatiilor.ro isi vor asigura si impune in mod hotarit recunoasterea drepturilor de proprietate intelectuala in conformitate cu legile in vigoare, ajungind daca este cazul la actionarea celor vinovati de incalcarea dreptului de proprietate intelectuala in judecata prin instantele penale.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b> 3. Utilizarea Site-ului</b></p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Poti folosi site-ul daca ai peste 13 ani, doar daca ai permisiunea unui adult sau tutore legal. Dar pentru a-ti face cont,pentru a te inregistra si utiliza serviciile oferite de Site trebuie sa ai peste 18 ani.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>&#206;n&#355;elege&#355;i &#351;i sunte&#355;i de acord c&#259; serviciile &#351;i orice alte informa&#355;ii pe care le invatati din Site nu sunt destinate, proiectate, sau recomandate implicit pentru a diagnostica, a preveni sau trata orice afec&#355;iune sau boal&#259;, sau pentru a evalua starea dumneavoastr&#259; de s&#259;n&#259;tate, sau sa substituie &#238;ngrijirea medical&#259; profesional&#259;. Nu utiliza&#355;i Serviciile site-ului &#238;n timpul conducerii autovehiculului, operarii utilajelor grele sau efectuarii altor sarcini care necesit&#259; o aten&#355;ie &#351;i concentrare sporite . &#206;n&#355;elege&#355;i &#351;i sunte&#355;i de acord c&#259; sunte&#355;i singurul responsabil pentru utilizarea Serviciilor.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>CasaMeditatiilor.ro acorda permisiunea de a utiliza Site-ul in urmatoarele conditii:</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Puteti folosi Continutul, insa doar pentru folosul personal si ne-comercial si cu conditia de a va conforma cu informatiile referitoare la dreptul de autor (copyright) si alte drepturi de proprietate intelectuala cuprinse in Continut;</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Nu aveti permisiunea sa distribuiti, sa modificati, sa copiati (cu exceptia celor mai sus mentionate), sa transmiteti, sa expuneti, sa refolositi, sa reproduceti, sa publicati, sa autorizati, sa acordati o licenta de folosire, sa creati lucrari derivate din, sau sa transferati, sa vindeti sau sa folositi Continutul in alt mod, fara acordul in scris al CasaMeditatiilor.ro;</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Este interzis sa folositi Site-ul pentru a afisa sau transmite orice fel de material ce are caracter amenintator, fals, inselator, abuziv, de hartuire, licentios, calomniator, vulgar, obscen, scandalos, instigator, pornografic sau profanator, sau orice alt fel de material care poate constitui sau incuraja un comportament ce va putea da nastere unei infractiuni, sau ar putea conduce la raspundere civila, sau ar incalca in alt mod legea. CasaMeditatiilor.ro va coopera cu oricare dintre autoritatile desemnate sa aplice legea si se va conforma cu orice sentinta judecatoreasca prin care se cere sau se ordona CasaMeditatiilor.ro sa dezvaluie identitatea oricarei persoane care ar afisa sau transmite orice fel de informatie sau material de acest fel pe sau prin intermediul Site-ului; si</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Este interzis sa utilizati Site-ul in scop de publicitate sau pentru orice fel de cerere/oferta cu caracter comercial.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b> 4. Confidentialitate</b></p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Orice date cu caracter personal precum: numele, adresa, numarul de telefon sau adresa de e-mail a dumneavoastra pe care o transmiteti pe Site prin posta electronica sau prin alta modalitate vor fi folosite in conformitate cu Politica de Confidentialitate. (Accesul se face prin Site). Orice alt fel de comunicari sau materiale pe care le transmiteti pe acest Site, precum intrebari, comentarii, sugestii sau alte mesaje de acest fel vor fi considerate ca neconfidentiale si neprotejate de drepturi de proprietate intelectuala determinate.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b> 5. Lipsa garantiilor</b></p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>INTREG CONTINUTUL ACESTUI SITE POATE FI MODIFICAT SI VA ESTE OFERIT “CA ATARE” FARA A SE OFERI NICI O GARANTIE DE NICI UN FEL, FIE ACEASTA EXPRESA SAU IMPLICITA.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b> 6. Exonerarea de Raspundere</b></p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>UTILIZAREA ACESTUI SITE ESTE IN TOTALITATE PE CONTUL DUMNEAVOASTRA. CASAMEDITATIILOR.RO, FUNCTIONARII, DIRECTORII, AGENTII SAU ORICE ALTA PARTE IMPLICATA IN CONCEPEREA, PRODUCEREA SAU OFERIREA SITE-ULUI NU SUNT RASPUNZATOARE PENTRU DAUNE DIRECTE SAU INDIRECTE, DE ORICE NATURA, CE AR REZULTA DIN SAU IN LEGATURA CU UTILIZAREA ACESTUI SITE SAU A CONTINUTULUI SAU. CASAMEDITATIILOR.RO NU ISI ASUMA NICI O RESPONSABILITATE SI NU VA FI RASPUNZATOARE PENTRU NICI O DAUNA SAU VIRUSI CARE AR PUTEA SA VA INFECTEZE COMPUTERUL SAU ALTE BUNURI IN URMA ACCESARII SAU UTILIZARII ACESTUI SITE, SAU DESCARCARII ORICARUI MATERIAL, INFORMATII, TEXT, IMAGINI VIDEO SAU AUDIO DE PE ACEST SITE.NU OFERIM NICIO GARANTIE CU PRIVIRE LA ACURATETEA, CALITATEA, COMPLEXITATEA, SERIOZITATEA, VERIDICITATEA, ACTUALITATEA CONTINUTULUI SITEULUI.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b> 7. Link-uri pe site-urile unei terte parti</b></p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Site-ul poate contine link-uri catre alte site-uri aflate in proprietatea sau operate de alte parti decit CasaMeditatiilor.ro. Astfel de link-uri va sunt furnizate pentru a le folosi numai daca veti dori aceasta. CasaMeditatiilor.ro nu controleaza, si nu este raspunzatoare pentru continutul si conditiile de confidentialitate sau securitate si de functionalitatea acestor site-uri. Fara a se limita la cele mai sus mentionate, CasaMeditatiilor.ro isi declina in mod special orice raspundere daca aceste site-uri:</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Incalca drepturile de proprietate intelectuala ale unei terte parti;</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Sunt inexacte, incomplete sau contin informatii inselatoare;</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Nu au caracter comercial sau nu raspund indeplinirii unui anumit scop;</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Nu ofera o securitate adecvata;</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Contin virusi sau alte elemente cu caracter distructiv; sau</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Sunt licentioase sau calomnioase.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 40 }}>De asemenea, CasaMeditatiilor.ro nu autorizeaza continutul sau orice alte produse si servicii prevazute pe astfel de site-uri. Daca intrati printr-un link pe astfel de site-uri sau pe acest site, va asumati personal riscul, fara a exista in acest sens permisiunea CasaMeditatiilor.ro.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b> 8. Revizuiri ale acestor Conditii de Utilizare</b></p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>CasaMeditatiilor.ro poate, in orice moment si fara notificare prealabila, sa revizuiasca aceste Conditii de Utilizare prin actualizarea acestora. Sunteti obligat sa respectati oricare si toate astfel de revizuiri si de aceea va trebui sa vizitati aceasta pagina a site-ului nostru in mod periodic pentru a lua la cunostinta Conditiile de Utilizare actualizate.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Este interzisa utilizarea acestui site pentru a posta sau transmite orice fel de material ilegal, de amenintare, fals, inselator, abuziv, de hartuire, defaimator, vulgar, obscen, scandalos, incendiar, pornografic, profan sau orice alt material care ar putea constitui sau incuraja un comportament care ar putea fi considerat criminal, poate da nastere responsabilitatii civile sau viola orice lege. Compania va coopera deplin cu orice autoritati care aplica legea sau orice ordin judiciar care solicita sau ordona Companiei sa reveleze identitatea persoanelor care posteaza sau transmit astfel de informatii sau materiale; sunteti de acord in asa fel incat Compania, la alegerea sa, sa inceteze sau sa intrerupa utilizarea de catre dvs. a site-ului la nerespectarea Termenilor de Utilizare. In momentul suspendarii sau incetarii respective, dumneavoastra trebuie (a) sa incetati utilizarea site-ului si (b) sa distrugeti orice copii facute dupa orice parte a continutului sau. Nu veti considera Compania raspunzatoare pentru daunele, costurile, raspunderile, cheltuielile (inclusiv costurile avocatilor) si sumele pentru intelegerile legate de orice proces, pretentie sau actiune depusa de catre un tert impotriva Companiei drept rezultat al (a) neglijentei, proastei reprezentari, erori sau omitere din partea dvs. sau (b) incalcarii din partea dvs. a Termenilor de Utilizare si a legilor sau regulamentelor de aplicare.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>UTILIZATI ACEST SITE PE PROPRIA RESPONSABILITATE. NICI CASAMEDITATIILOR.RO, NICI DIRECTORII SAU MANAGERII, NICI AGENTII SAI SAU TERTII IMPLICATI IN CREAREA, PRODUCEREA SAU LIVRAREA SITE-ULUI NU SUNT RESPONSABILI PENTRU DAUNELE DIRECTE, INDIRECTE, PUNITIVE, INCIDENTALE, SPECIALE, LOGICE SAU ALTE DAUNE LEGATE DE UTILIZAREA ACESTUI SITE SAU A CONTINUTULUI INDIFERENT DACA SE REALIZEAZA PE BAZA UNUI CONTRACT, RESPONSABILITATE STRICTA SAU ALTA MODALITATE, CHIAR CU AVERTIZAREA PRIVIND POSIBILITATEA UNOR ASTFEL DE DAUNE.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b> 9. Legislatie aplicabila si Jurisdictie</b></p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}>Aceste Conditii de Utilizare si utilizarea acestui site sunt guvernate de legile din Romania. Instantele competente din Romania vor avea jurisdictie exclusiva asupra oricaror si tuturor disputelor ce vor aparea din sau se vor referi la sau vor fi in legatura cu prevederile Conditiilor de Utilizare si/sau Continutul site-ului sau in cazul disputelor in care aceste Conditii de Utilizare si/sau acest site vor fi considerate fapte relevante pentru astfel de dispute.</p>
                 <p className="meditatii-confidentialitate-paragraph" style={{ marginBottom: 20 }}><b> 10. Daca ai intrebari</b> despre Termeni si Conditii sau vrei sa ne spui parerea ta, te rugam sa contactezi CasaMeditatiilor.ro la <a className="meditatii-despre-noi-abonament-link" href="mailto:contact@casameditatiilor.ro">contact@casameditatiilor.ro</a>.</p>
          

                 </div>
            </div>
         );
    }
}
 
 ;