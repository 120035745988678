import React from 'react'; 
import './ProgramatorLuni.css'
import {  updateData } from '../../Providers/utile';

export default class MonthCal extends React.Component{

    constructor(props){
        super(props);

        this.state={
            title : ''
        }

    }





    monthDisplay=(luna, anul)=>{

        let monthBegin = new Date(anul, (luna-1) , 1 ) // js index luna este 0
        let monthEnd = new Date(anul, luna , 0 )

 

        let beginFor = this.getWeekNumber(monthBegin, luna);
        let endFor = this.getWeekNumber(monthEnd, luna) ;

      

      

        let tbodyContent=[]
        let _td = new Date()
        let _day = 0;

        for(let i = beginFor; i<=endFor; i++){
            let cols = []
            
            for(let k=1; k<=7; k++){

                let celContent=[] 

                for(let j=0; j< monthEnd.getDate() ; j++){

                    _td = this.addDays(monthBegin,j)
                    _day = _td.getDay()===0 ? 7 : _td.getDay()

                    if(this.getWeekNumber(_td, luna)===i && _day===k){
                        // doar display brut de calendar
                        //celContent.push(<label key={`i=${i}k=${k}j=${j}`}>{_td.getDate()}</label>)

                        celContent.push(<div onClick={()=>{this.props.goToZi(j)}} className={_day >=6 ? 'monthcalendar-weekend-class' : 'monthcalendar-weekday-class'} key={`i=${i}k=${k}j=${j}`}>
                            <label className={_day >=6 ? 'monthcalendar-weekend-label' : 'monthcalendar-weekday-label'} > {_td.getDate()} </label> 
                                            { this.filterLabelEvents(_td,_day) }  
                                        </div>)
                    }
                }

                cols.push(<td  key={`w${i}d${k}`}> {celContent}  </td>)
            }

            tbodyContent.push(<tr key={`w${i}`}>{cols}</tr>)
        }

       
   
         return tbodyContent
    }



     getWeekNumber=(d, luna)=> {

        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        let dayNum= d.getUTCDay() || 7
        d.setUTCDate(d.getUTCDate() + 4 - dayNum);
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
      
        if(weekNo === 1 && luna === 12){
            let ld = new Date(d.getFullYear(), 11, 24);
            return this.getWeekNumber(ld) + 1
        }else{
            return weekNo
        }
    }

    ajustareSfarsitAn=(anul)=>{
            return this.weeksInYear(anul) + 1
    }

   weeksInYear=(year)=> {
        var d = new Date(year, 11, 31);
        var week = this.getWeekNumber(d);
        if(week === 1){
            d.setDate(24)
            return this.getWeekNumber(d)
        }else{
            return week
        }
       
      }





    daysInMonth=(month, year)=> {
        
        return new Date(year, month, 0).getDate();
    }

    addDays=(date, days)=> {

        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    filterLabelEvents = (dataCalendar,day ) => {

        //let dataCalendar = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
        

        let events =  this.props.eventsProps.filter(e=>{
            let s = updateData(e.data_listare).split('-')
            let dataEvent = new Date(s[0],Number(s[1]-1),s[2])

            if(dataCalendar.getTime()===dataEvent.getTime()){
              
                return true
            }
            else{
                return false
            }
        })

        let ds = dataCalendar.getFullYear()+'-'+dataCalendar.getMonth()+ '-' +dataCalendar.getDate();

        let evKeyIndexPrefix = `ev${ds[0]}${Number(ds[1]-1)}${ds[2]}`
        return <ul className='monthcalendar-container-evenimente'>
            {
                events.map(( ev,idx)=> {
                    return <li style={{cursor:'pointer'}} key={`${evKeyIndexPrefix}ix${idx}`} className={day >= 6 ? 'monthcalendar-eveniment meditatii-calendar-weekend':'monthcalendar-eveniment meditatii-calendar-weekday'}>
                        <span>{ev.text}. -{ev.autor}-</span>
                    </li>
                })
            }
        </ul>

    }

    numeLuna = (l)=>
    {
        let rez =''

        switch(l){
            case 1: rez = "Ianuarie"; break;
            case 2: rez = "Februarie"; break;
            case 3: rez = "Martie"; break;
            case 4: rez = "Aprilie"; break;
            case 5: rez = "Mai"; break;
            case 6: rez = "Iunie"; break;
            case 7: rez = "Iulie"; break;
            case 8: rez = "August"; break;
            case 9: rez = "Septembrie"; break;
            case 10: rez= "Octombrie"; break;
            case 11: rez = "Noiembrie"; break;
            case 12: rez = "Decembrie"; break;
            default: rez=null

        }

        return rez
    }


    render(){
        return (
            <div style={{display:this.props.display}} className="monthcalendar-main-container">

                 <div className='monthcalendar-header'>
                                    <i onClick={this.props.minusLuna} className='material-icons' style={{color:'#ea7ab7', verticalAlign:'sub', cursor:'pointer'}}>arrow_back</i>
                                    <span className='monthcalendar-title'>{this.numeLuna(this.props.luna)} - {this.props.anul}</span>
                                    <i onClick={this.props.plusLuna} className='material-icons' style={{color:'#ea7ab7', verticalAlign:'sub', cursor:'pointer'}}>arrow_forward</i> 
                                    </div> 
              <div className="monthcalendar-table-container">
                                       

                <table className='monthcalendar-table-class'>
                    <thead className='monthcalendar-tablehead-class'>
                    <tr className='monthcalendar-tableheadtr-class' >
                        <th className="meditatii-calendar-weekday">Lu</th>
                        <th className="meditatii-calendar-weekday">Ma</th>
                        <th className="meditatii-calendar-weekday">Mi</th>
                        <th className="meditatii-calendar-weekday">Jo</th>
                        <th className="meditatii-calendar-weekday">Vi</th>
                        <th className="meditatii-calendar-weekend">Sa</th>
                        <th className="meditatii-calendar-weekend">Du</th>
                    </tr>
                    </thead>

                    <tbody>
                        {this.monthDisplay(this.props.luna, this.props.anul)}
                    </tbody>
                   
                </table>
                </div>
            </div>
        );
    }


}  // end class