




const randomData = (nr) => {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  
    for (var i = 0; i < nr; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    return text;
}



export const generateId = () => {
  return  randomData(32);
}

export const primesteObiect = (arr) =>{

 

let rez = '"'
 rez += JSON.stringify(arr)
 rez += '"'
  return rez  ;
} 

export const arrToString = (arr)=>{
  return arr.join()
}


export const upperCaseFistLetter= (word)=>{
  if(word !==null){

  
  let newValue = ''
 
 
      if( word.length > 0){
          newValue+= word.substring(0,1).toUpperCase() + word.slice(1) + ''
      }else{
         
      }
  
  return newValue
    }
}



export const statusColor = (status)=>{
  switch(status){
      case 'Deschisa':
      return '#5bcc57';
      case 'Finalizata':
      return '#2f8bf1';
      case 'Anulata' : 
      return '#ff7f09';
      default :
      return '#eb3e10';
       
    
  }
}

export const updateDataText = (data) => {
   
   
  let myDate = new Date(data)

  let year = myDate.getFullYear()
  let lunaNormala = myDate.getMonth()+1
  let month = ((lunaNormala <10? '0' : '') +lunaNormala);
  let day = (myDate.getDate()<10? '0' : '') +myDate.getDate();
  
  
  
  return `${year}-${month}-${day}`

}



export const updateData = (data) => {
   
 if(data !==null && data.length > 19){
  let myDate = new Date(+data.substring(6,19))

  let year = myDate.getFullYear()
  let lunaNormala = myDate.getMonth()+1
  let month = ((lunaNormala <10? '0' : '') + lunaNormala );
  let day = (myDate.getDate()<10? '0' : '') +myDate.getDate();

  return `${year}-${month}-${day}`

 }  else if(data !== null){
  
  const an = data.slice(0,4)
  let luna = data.slice(5,7)
  const zi = data.slice(8,10)
  return `${an}-${luna}-${zi}`
}
}

 export const transformDataForSql = (data) => {
   
 
   let myDate = new Date(data)
 
   let year = myDate.getFullYear()
   let lunaNormala = myDate.getMonth()+1
   let month = ((lunaNormala <10? '0' : '') + lunaNormala );
   let day = (myDate.getDate()<10? '0' : '') +myDate.getDate();
   return `${year}-${month}-${day}`
 

  
 
  }  
  



export const updateOra = (data) =>{

  if(data !==null && data.length > 19){
  let myDate = new Date(+data.substring(6,19))

  const ora = (myDate.getHours()<10? '0' : '') +  myDate.getHours()
  const minute = (myDate.getMinutes()<10? '0' : '') +  myDate.getMinutes()

  return `${ora}:${minute}`
}else if(data !==null){
 
  let ora = data.slice(11,13)
  let minute = data.slice(14,16)
  return `${ora}:${minute}`
}
}

export const arataData = (data ) => {

  if(data !=null && data.length>19){
      
      let dataCampanie = new Date(+data.substring(6,19))

      const luni=['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie']
      

      
      const an = dataCampanie.getFullYear()
      const zi = (dataCampanie.getDate() < 10 ? '0' : '') + dataCampanie.getDate()  ;
      const luna = luni[dataCampanie.getMonth()];
      


      return `${zi} ${luna} ${an}`
  }else if(data != null){
    const luni = ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie']
    const an = data.slice(0,4)
    let dataLunaIndex = (data.slice(5,7)< 10 ? data.slice(6,7) : data.slice(5,7)) -1
    const luna = luni[dataLunaIndex]
    const zi = data.slice(8,10)

    return `${zi} ${luna} ${an}`
  }
  
}

export const updateToSql = (data) =>{
  if(data !== null){
      let myDate = new Date(+data.substring(6,19))
      
      let year = myDate.getFullYear()
      let lunaNormala = myDate.getMonth()+1
      let month = ((lunaNormala<10? '0' : '')  + lunaNormala)
      let day = (myDate.getDate()<10? '0' : '') +myDate.getDate()

      let hour = (myDate.getHours()<10? '0' : '') +  myDate.getHours()
      let minutes = (myDate.getMinutes()<10? '0' : '') +  myDate.getMinutes()

      return `${year}-${month}-${day} ${hour}:${minutes}:00 `
  }
}

export const updateSqlNow = () =>{
    
  let myDate = new Date()
  
  let year = myDate.getFullYear()
  let lunaNormala = myDate.getMonth()+1
  let month = ((lunaNormala<10? '0' : '')  + lunaNormala)
  let day = (myDate.getDate()<10? '0' : '') +myDate.getDate()

  let hour = (myDate.getHours()<10? '0' : '') +  myDate.getHours()
  let minutes = (myDate.getMinutes()<10? '0' : '') +  myDate.getMinutes()

  return `${year}-${month}-${day} ${hour}:${minutes}:00`

}

export const arataOre = (hour) => {

  if(hour !=null && hour.length>19 ){
   
      let dataCampanie = new Date(+hour.substring(6,19))
    
      const ora = (dataCampanie.getHours()<10? '0' : '') +  dataCampanie.getHours()
      const minute = (dataCampanie.getMinutes()<10? '0' : '') +  dataCampanie.getMinutes()


      return `${ora}:${minute}`
  }else {
      const ora = hour.slice(11,13)
      const minute = hour.slice(14,16)
      return `${ora}:${minute}`
  }
  
}

export const dataAzi = (data) =>{

   
  let myDate = new Date(data)


  const luni=['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie']

  let an = myDate.getFullYear()
  let luna = luni[myDate.getMonth()]
  let zi = (myDate.getDate() < 10 ? '0' : '') + myDate.getDate() 
  return `${zi}-${luna}-${an}`




}

export const defaultData = () =>{
  let myDate = new Date()
 
   let year = myDate.getFullYear()
   let lunaNormala = myDate.getMonth()+1
   let month = ((lunaNormala <10? '0' : '') + lunaNormala );
   let day = (myDate.getDate()<10? '0' : '') +myDate.getDate();
   return `${year}-${month}-${day}`
}

export const month1 = () =>{
  let myDate = new Date()

  myDate.setMonth(myDate.getMonth() - 1)
 
   let year = myDate.getFullYear()
   let lunaNormala = myDate.getMonth()+1
   let month = ((lunaNormala <10? '0' : '') + lunaNormala );
   let day = (myDate.getDate()<10? '0' : '') +myDate.getDate();
   return `${year}-${month}-${day}`
}

export const todayPlus3 =()=>{
  let myDate = new Date()
  let addDays = 3

  myDate.setDate(myDate.getDate()+ addDays)

  let year = myDate.getFullYear()
  let lunaNormala = myDate.getMonth()+1
  let month = ((lunaNormala <10? '0' : '') + lunaNormala );
  let day = (myDate.getDate()<10? '0' : '') +myDate.getDate();
  return `${year}-${month}-${day}`
}

export const dataCurentaForSql = ()=> {
  let myDate = new Date()
  
  let year = myDate.getFullYear()

  let lunaNormala = myDate.getMonth() + 1
  let month = (lunaNormala < 10? '0' : '') + lunaNormala
  
  let day = (myDate.getDate()<10? '0' : '') +myDate.getDate()

  let hour = (myDate.getHours()<10? '0' : '') +  myDate.getHours()
  let minutes = (myDate.getHours()<10? '0' : '') +  myDate.getHours()

  return `${year}-${month}-${day} ${hour}:${minutes}:00`
}


export const dataFriendlyFromSql = (data) => {

if(data){
  if ( data.length > 18) {
    
    let dataCampanie = new Date(+data.substring(6, 19))

    const luni = ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie']



    const an = dataCampanie.getFullYear()
    const zi = (dataCampanie.getDate() < 10 ? '0' : '') + dataCampanie.getDate();
    const luna = luni[dataCampanie.getMonth()];

    return `${zi}-${luna}-${an}`
  } 
  else {
    const luni = ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie']
    const an = data.slice(0, 4)
    let dataLunaIndex = (data.slice(5, 7) < 10 ? data.slice(6, 7) : data.slice(5, 7)) - 1
    const luna = luni[dataLunaIndex]
    const zi = data.slice(8, 10)

    return `${zi}-${luna}-${an}`
  }
}
else{
return ''
}

}



export const convertDataFromSql = (d)=>{
if(d){
if (d.length > 19) {
  let dataCal = new Date(+d.substring(6, 19))

  const an = dataCal.getFullYear()
  const zi = (dataCal.getDate() < 10 ? '0' : '') + dataCal.getDate()

  let lunaNormala = dataCal.getMonth() + 1
  const luna = (lunaNormala < 10 ? '0': '') + lunaNormala

  return `${an}-${luna}-${zi}`
}
}
else{
return null
}
}

export const dataFriendly = (d) => {
// 1878-02-09
if(d){

let splitData = d.split('-')

let an = Number(splitData[0])

let luna = splitData[1].substring(0,1) === '0' ? splitData[1].substring(1,2) : splitData[1]
luna = Number(luna)


let ziua = splitData[2].substring(0,1) === '0' ? splitData[2].substring(1,2) : splitData[2]
ziua = Number(ziua)

const luni = ['','Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'] 

return `${ziua} ${luni[luna]} ${an}`
}
else{
return null
}
}

export const dataTaskuri = (data, ora, minute) =>{
  if(data !== null){
    let myDate = new Date()
      let dataScadenta = new Date(+data.substring(6,19))
    let dateDiff = dataScadenta.getTime() - myDate.getTime()

    let difDays = Math.ceil(dateDiff / (1000 * 3600 * 24))
    let hours = myDate.getHours() < 10 ? '0' : '' + myDate.getHours()
    let minutes = myDate.getMinutes() < 10 ? '0' : '' + myDate.getMinutes()
  
    if(difDays === 0 ){
      if(`${ora}:${minute}` < `${hours}:${minutes}`){
        return '#fbc0ad'
      }
    }else if(difDays < 0){
      return '#fbc0ad'
    }
   
    return ''
    
  }
}

export const dataScadenta = (data)=> {
  if(data !== null){
      let myDate = new Date();
      let dataScadenta = new Date(+data.substring(6,19))
      
      let dateDiff = dataScadenta.getTime() - myDate.getTime()
      let difDays = Math.ceil(dateDiff / (1000 * 3600 * 24))
     

      if(difDays <= 3 && difDays > 0){
        return '#ffffb4'
      }else if(difDays <= 0){
        return '#fbc0ad'
      }
      return ''
             
      }
    }

    export const arataDataPrescurtata = (data ) => {

      if(data !=null && data.length>19){
          
          let dataCampanie = new Date(+data.substring(6,19))
    
          const luni=['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Noi', 'Dec']
          
    
          
          const an = dataCampanie.getFullYear()
          const zi = (dataCampanie.getDate() < 10 ? '0' : '') + dataCampanie.getDate()  ;
          const luna = luni[dataCampanie.getMonth()];
          
    
    
          return `${zi} ${luna} ${an}`
      }else if(data != null){
        const luni = ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Noi', 'Dec']
        const an = data.slice(0,4)
        let dataLunaIndex = (data.slice(5,7)< 10 ? data.slice(6,7) : data.slice(5,7)) -1
        const luna = luni[dataLunaIndex]
        const zi = data.slice(8,10)
    
        return `${zi} ${luna} ${an}`
      }
      
    }


    export const homeNrColor = (nr, prag1, prag2)=>{
      if((nr > prag1) && (nr <= prag2)){
        return 'darkorange'
      }else if(nr > prag2){
        return "#e60b0b"
      }else{
        return "#09bf6d"
      }
    }


export const valabilitate = [
  {idul:'0',
    nume:'Ambele platforme'},
    
      {idul:'1',
        nume:'Doar in browser'},
        {idul:'2',
      nume:'Doar pe Aplicatia Mobila'},

]