import React from 'react';
import AppBar from '../../Componente/Comune/AppBar'
import { Grid, Button, FormControl, Input, InputLabel, Card, IconButton, InputAdornment } from '@material-ui/core'
import { fromServer } from '../../Providers/ws';
import { setLocalUserData } from '../../Providers/ls';
import meditatiiAcasa from '../Pics/meditatii-acasa-min.jpg'
import Alert from '../../Componente/Comune/Alert'
import FacebookLogin from 'react-facebook-login'
import radioLive from '../Pics/radioLive.png'




export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            parola: '',
            vParola: '',
            schimbaParola: false,
            mail: '',
            emailTrimis: '',
            openAlert:false,
            alertMesaj:'',
            facebookData:[]
        }
    }

   

  

 



    componentDidMount(){

       //  this.loadFbLoginApi()

    //     window.fbAsyncInit = async() =>{
    //         FB.init({
    //             appId:'279709906062725',
    //         //   appId:'268302460534860',
    //           autoLogAppEvents : true,
    //           xfbml            : true,
    //           version          : 'v3.2'
    //         });
    //         this.checkLoginState()
    //       };
    //       (function(d, s, id){
    //       var js, fjs = d.getElementsByTagName(s)[0];
    //       if (d.getElementById(id)) return;
    //       js = d.createElement(s); js.id = id;
    //       js.src = 'https://connect.facebook.net/en_US/sdk.js';
    //       fjs.parentNode.insertBefore(js, fjs);
    //     }(document, 'script', 'facebook-jssdk'))
  
     //this.responseFacebook()
    // this.checkLoginState()

    this.facebookMobileLogin()

    }

     facebookMobileLogin = async () => {
        if(this.props.match.params.fbcode !== '0'){
            let addr = `https://graph.facebook.com/v3.2/oauth/access_token?client_id=268302460534860&redirect_uri=https://casameditatiilor.ro/facebookLogin.html&code=${this.props.match.params.fbcode}&client_secret=be76056633cafc5353b3e080676db0a2&scope=email,public_profile`
        addr = encodeURI(addr)
    
    
    
        let r = await fetch(addr)

        
    
         if (r.ok) {

                 let js = await r.json();


                     let addr3 = `https://graph.facebook.com/v3.2/me?access_token=${js.access_token}&fields=email,name`
         addr3 = encodeURI(addr3)

         let r3 = await fetch(addr3)

             

            if(r3.ok){
               let js3 = await r3.json()

               this.fblogin(js3)

            }
          
            
    

            return new Error(`Eroare primire date: ${js.m}`)
        }
        else {
            throw new Error(r.statusText);
        }
        }

     

    
      
        
    }




    fblogin= async(r)=>{
      if(r.email){

          let fbLogin = await fromServer('login',{mode:'facebook_login', fb_email:r.email}, {credentials:'include'})
  
       
  
              if(fbLogin.r === 'ok'){
                let user = {
                    esteLogat: true,
                    fbLogin:true,
                    nume: fbLogin.continut.nume,
                    idul: fbLogin.continut.idul,
                    email: fbLogin.continut.email,
                    nume_utilizator: fbLogin.continut.nume_utilizator,
                    admin: fbLogin.continut.admin,
                    blocat: fbLogin.continut.blocat,
                    data_creare_cont: fbLogin.continut.data_creare_cont,
                    data_modificare_cont: fbLogin.continut.data_modificare_cont,
                    label_tip_cont: fbLogin.continut.label_tip_cont,
                    id_sesiune:fbLogin.continut.id_sesiune
                }
    
             
                await setLocalUserData(user);
    
                this.props.history.push("/meditatii")
              }else{
                 this.setState({openAlert:true,
                                 alertMesaj:fbLogin.mesaj})
             }
      }
          
            
            
        }

        responseFacebook = (r)=>{
  
            console.log('r :', r);
        
        //    this.fblogin(r)
        }
     
   
        componentClicked=(r)=>{
        
        }
 
 


    logIn = async () => {
        let logInInfo = {
            username:  this.state.user,
            password:  this.state.parola
        }

        console.log('loginstart :');
        let login = await fromServer('login', {mode:'login'},{credentials:'include', method:'POST'}, false, 'POST',JSON.stringify(logInInfo), 'postData' )
        console.log('login :', login);
       
        if (login.r === 'ok') {

            

            let user = {
                esteLogat: true,
                fbLogin:false,
                nume: login.continut.nume,
                idul: login.continut.idul,
                email: login.continut.email,
                nume_utilizator: login.continut.nume_utilizator,
                admin: login.continut.admin,
                blocat: login.continut.blocat,
                data_creare_cont: login.continut.data_creare_cont,
                data_modificare_cont: login.continut.data_modificare_cont,
                label_tip_cont: login.continut.label_tip_cont,
                id_sesiune:login.continut.id_sesiune
            }

         
                setLocalUserData(user);

            this.props.history.push("/meditatii")
        } else {
            this.setState({ vParola: login.mesaj })
         }
        
     
    }

   

    parolaUitata = () => {
        if (this.state.schimbaParola === false) {
            this.setState({ schimbaParola: true })
        } else {
            this.setState({ schimbaParola: false })
        }
    }


    trimiteMail = async () => {
        this.setState({emailTrimis:'Va rugam asteptati...'})

        let trimiteMail = await fromServer('reset_password',{mode:'check_email',
                                                        email:this.state.mail}, {credentials:'include'} )

      

        if (trimiteMail.r === 'ok') {
            this.setState({ emailTrimis: trimiteMail.mesaj,
                                    mail:'' })
           
        } else {
            this.setState({ emailTrimis: trimiteMail.mesaj })
        }
    }


    dialogClose = ()=>{
        this.props.history.push('/inregistreazate/0')
    }




    render() {

        
        return (
            <div>
                <AppBar history={this.props.history} />
                <Alert 
                    open={this.state.openAlert}
                    dialogClose={this.dialogClose}
                    title='Atentie'
                    mesaj={this.state.alertMesaj}/>
                <div style={{ backgroundImage: `url(${meditatiiAcasa})`, paddingTop: 10, backgroundRepeat:'no-repeat', backgroundSize:'cover', position:'relative' }}>
                <a id='meditatii-redirect-link' href="https://www.youtube.com/channel/UCPMpZtCjoZ2HvxUmMe9d5PA" target='_blank'  rel='noopener noreferrer'>
                        <div  className='meditatii-radio-live' style={{backgroundImage:`url(${radioLive})`}}>
                            
                        </div>
                        </a>
                {this.state.schimbaParola === false &&
                    <h1 className="meditatii-paralax-content-title " style={{ marginTop: 40 }}>AUTENTIFIC&#258;-TE</h1>
                }

                    <div className="meditatii-section-container" style={{backgroundColor:'transparent'}}>
                        {this.state.schimbaParola === false &&
                            <Grid container className="meditatii-subsection-container" style={{ marginTop: 80 }}>
                                <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ paddingBottom: 30, borderRadius: 20  }}>
                                        <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormControl className="meditatii-text-field"  >
                                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="utilizator">Nume Utilizator</InputLabel>
                                                    <Input
                                                        disableUnderline={true}
                                                        onKeyPress={(ev)=>{ ev.key === 'Enter' ? this.logIn() : console.warn('no');}}
                                                        id="utilizator"
                                                        type='text'
                                                        value={this.state.user}
                                                        onChange={(ev) => { this.setState({ user: ev.target.value }) }}

                                                    />
                                                </FormControl>


                                            </Grid>
                                            <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                

                                                <FormControl className="meditatii-text-field"  >
                                                    <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-parola">Parola</InputLabel>
                                                    <Input
                                                        disableUnderline={true}
                                                        onKeyPress={(ev)=>{ ev.key === 'Enter' ? this.logIn() : console.warn('no');}}
                                                        id="meditatii-parola"
                                                        type={this.state.showPassword ? 'text' : 'password'}
                                                        value={this.state.password}
                                                        onChange={(ev) => { this.setState({ parola: ev.target.value }) }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    style={{ color: "#bfbfbf", border: 'none' }}

                                                                    onClick={() => { this.setState({ showPassword: this.state.showPassword === true ? false : true }) }}
                                                                >
                                                                    <i className="material-icons">{this.state.showPassword ? "visibility" : "visibility_off"}</i>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                                <span className="meditatii-success-message" style={{color:'red'}}>{this.state.vParola}</span>
                                                <span className="meditatii-schimba-parola" onClick={this.parolaUitata}>Ai uitat parola?</span>
                                            </Grid>
                                            <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>

                                                <Button className="meditatii-autentificare-button" variant="contained" onClick={this.logIn}>
                                                    Autentificare
                                              </Button>
                                              <h2 className="meditatii-subsection-meditezi-title">sau</h2>
                                 {/* <Button className="meditatii-autentificare-button-facebook"  variant="contained" onClick={this.fblogin}>
                                          <i className='fab fa-facebook' style={{fontSize:31, marginRight:15}}></i> Autentifica-te cu Facebook
                                 </Button> */}

                                 <FacebookLogin
                                 cssClass='meditatii-autentificare-button-facebook'
                                 style={{}}
                                    appId="268302460534860"
                                    autoLoad={false}
                                    redirectUri={'https://casameditatiilor.ro/facebookLogin.html'}
                                    fields='name,email'
                                    textButton="AUTENTIFIC&#258;-TE CU FACEBOOK"
                                    onClick={this.componentClicked}
                                    state='autentificare'
                                    scope="email"
                                    callback={this.responseFacebook}
                                    icon={<i className='fab fa-facebook' style={{fontSize:25, marginRight:15, verticalAlign:'sub'}}></i>}/>
 
                            {/* <div className="fb-login-button" data-max-rows="1" data-size="large" data-button-type="continue_with" data-show-faces="false" data-auto-logout-link="false" data-use-continue-as="false"></div> */}
                                
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        }
                    </div>

            
                    <div className="meditatii-section-container" style={{backgroundColor:'transparent'}}>
                        {this.state.schimbaParola === true &&
                            <Grid container className="meditatii-subsection-container" style={{ marginTop: 50 }}>
                            <Grid style={{ margin: "auto", marginBottom: 100 }} item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ paddingBottom: 30, borderRadius: 20  }}>
                                <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <p className="meditatii-confidentialitate-paragraph" style={{ textAlign: 'center', marginBottom: 20, marginTop:20 }}>Pentru a-&#355;i reseta parola, introdu adresa de e-mail mai jos</p>
                                    <FormControl  className="meditatii-text-field" >
                                            <InputLabel style={{color:"#bfbfbf"}} htmlFor="meditatii-email">Email</InputLabel>
                                            <Input
                                               disableUnderline={true}
                                                id="meditatii-email"
                                                type='email'
                                                value={this.state.mail}
                                                onChange={(ev) => { this.setState({ mail: ev.target.value }) }}
                                                
                                            />
                                        </FormControl>

                                    <span className="meditatii-schimba-parola" onClick={this.parolaUitata}><i className='material-icons' style={{ verticalAlign: 'bottom' }}>keyboard_arrow_left</i>&#206;napoi la logare</span>
                                    <span className="meditatii-success-message" >{this.state.emailTrimis}</span>
                                </Grid>
                                <Grid className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>

                                    <Button className="meditatii-autentificare-button" variant="contained" onClick={this.trimiteMail}>
                                        Resetare Parola
                                    </Button>
                                </Grid>
                                </Card>
                                </Grid>
                            </Grid>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

