import React from 'react';

import AppBar from '../../Componente/Comune/AppBar'

import {Dialog, DialogActions, DialogContent, Button, Grid, Input, InputAdornment, InputLabel,FormControl, List, IconButton} from '@material-ui/core'
import { fromServer } from '../../Providers/ws';
import LiAutori from './LiAutori'
import { getLocalUserData } from '../../Providers/ls';


export default class Autori extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAdd:false,
            cauta:'',
            autori:[],
            filterAutori:[],
            titlu:''
        }
    }

    componentDidMount(){
        this.verifyAdmin()
        this.scrollTop()
    }
    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    verifyAdmin = async()=>{
        
        let server = await fromServer('users',{mode:'check_if_admin', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune}, {credentials:'include'})

        console.log('server :', server);

        if(server.r === 'ok'){

            this.getAutori()
            if(this.state.techDebug === 1){
            
        
            }
        }else{
            this.props.history.push('/')
        }
    }


    getAutori=async()=>{
        let autori = await fromServer('author',{mode:'get_authors_list', id_user:getLocalUserData().idul, id_sesiune:getLocalUserData().id_sesiune},{credentials:'include'})

        console.log('autori :', autori);

        if(autori.r === 'ok'){
            this.setState({autori:autori.continut,
                            filterAutori:autori.continut})
        }
        
    }


    search = () => {
        let autori = []

       // autori = this.state.autori.filter(c => (c.nume.toLowerCase().includes(this.state.cauta.toLowerCase())))

        autori = this.state.content.filter((c) => {return c.nume.toLowerCase().indexOf(this.state.cauta.toLowerCase()) !== -1 })
        this.setState({ filterAutori: autori })
    }

    insertAutor = async()=>{
        
        let insert = await fromServer('author', {mode:'insert_author',
                                                author_name:this.state.titlu},{credentials:'include'})

        if(insert.r==='ok'){
            this.props.history.push(`/autorinfo/${insert.id_introdus}`)
        }                                        


        
    }

    changeStatus=(cat, nr)=>{
        let cloneCat = [...this.state.filterAutori]
        let idx = cloneCat.findIndex(c=>c.idul === cat.idul)


        cloneCat[idx].dezactivat = nr

        this.setState({filterAutori:cloneCat})
    }

    

    closeAdd=()=>{
        this.setState({openAdd:false})
    }
    render() {
        return (
            <div>
                <AppBar history={this.props.history} />

                <Dialog

                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.openAdd}
                    onClose={this.closeAdd}
                >

                    <DialogActions style={{ justifyContent: 'center' }}>

                        <Button className='meditatii-close-dialog-button' onClick={this.closeAdd} variant="fab">
                            <i className='material-icons'>clear</i>
                        </Button>

                    </DialogActions>


                    <h2 style={{ padding: '20px 10px 0 10px', marginTop: 20, textAlign: 'center' }} className="meditatii-section-meditezi-title">
                        Adauga Autor
                     </h2>

                    <DialogContent style={{ textAlign: 'center' }}>

                        <FormControl className="meditatii-text-field" style={{ width: "100%" }}  >
                            <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-titlu">Nume</InputLabel>
                            <Input
                                disableUnderline={true}
                                id="meditatii-titlu"
                                type='text'
                                value={this.state.titlu}
                                onChange={(ev) => { this.setState({ titlu: ev.target.value }) }}


                            />
                        </FormControl>

                      

                        <Button style={{ marginTop: 20 }} variant='contained' color="primary" className='meditatii-autentificare-button' onClick={this.insertAutor} >
                            Adauga
                        </Button>

                    </DialogContent>



                </Dialog>
                <h1 className="section-title" style={{ marginBottom: 10 }}>Autori</h1>
                <div className="meditatii-back-button" onClick={() => { this.props.history.push('/administrator') }}><i className="material-icons meditatii-back-button-icon">reply</i></div>
                <Grid container className="meditatii-subsection-container" style={{ marginTop: 10 }}>
                    <Grid style={{ margin: "auto" }} item xs={12} sm={12} md={6} lg={6} xl={6}>

                        <Button style={{ color: 'darkorange' }} color="primary" className='meditatii-autentificare-button' onClick={() => { this.setState({ openAdd: true }) }} >
                            Adauga Autor
                                </Button>

                        <Grid style={{ marginTop: 20 }} className="meditatii-subsection-subsection" item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl className="meditatii-text-field"  >
                                <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-cauta">Cauta</InputLabel>
                                <Input
                                    disableUnderline={true}
                                    id="meditatii-cauta"
                                    type='text'
                                    value={this.state.cauta}
                                    onChange={(ev) => { this.setState({ cauta: ev.target.value }) }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                style={{ color: "#bfbfbf", border: 'none' }}

                                                onClick={this.search}
                                            >
                                                <i className="material-icons" >search</i>
                                            </IconButton>
                                        </InputAdornment>
                                    }

                                />
                            </FormControl>

                        </Grid>

                    </Grid>
                </Grid>
                <List style={{ marginBottom: 100, }}>
                    {
                        this.state.filterAutori.map((a, idx) => {
                            return (
                                <LiAutori key={idx} index={idx} autor={a} history={this.props.history} changeStatus={this.changeStatus}/>

                            )
                        })
                    }

                </List>
          
            </div >

          );
    }
}

;